import React from "react";
import { YearSelector } from "./YearSelector.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const YearSelectorContainer = () => {
  return (
    <Container className="m-0 align-self-end">
      <Row>
        <Col
          md={10}
          xs={8}
          className="d-flex align-items-center justify-content-end"
        >
          <p className="m-0">Ano-Calendário</p>
        </Col>
        <Col md={2} xs={4} className="p-0">
          <YearSelector />
        </Col>
      </Row>
    </Container>
  );
};
