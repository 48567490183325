import React from "react";
import { useSelector } from "react-redux";
import useLogout from "../Hooks/useLogout";
import { Icon } from "./Icon";
import { LogoutPopover } from "./LogoutPopover";

export const Logout = () => {
  const username = useSelector((state) => state.currentUser.username);

  return (
    <div className="m-2 w-100">
      <div
        style={{
          height: "1px",
          width: "100%",
          backgroundColor: "#eee",
        }}
      ></div>
      <div
        style={{ color: "#fff", cursor: "pointer" }}
        className="d-flex align-items-center gap-2 p-2"
      >
        <LogoutPopover>
          <div className="d-flex gap-2">
            <Icon icon={"User"} />
            {username.length > 20
              ? username.slice(0, 20).concat("...")
              : username}
            <Icon icon={"ChevronDown"} />
          </div>
        </LogoutPopover>
      </div>
    </div>
  );
};
