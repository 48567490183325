import React, { useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AddYear } from "../Components/AddYear";
import { HeadedCard } from "../Components/HeadedCard";
import { ProjectInfo } from "../Components/ProjectInfo";
import { SaveAndDelete } from "../Components/SaveAndDelete";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import Loading from "../Components/Loading/Loading";
import CustomAlert from "../Components/CustomAlert/CustomAlert";

export const ClientesAdminProjetosEdit = ({ add = false }) => {
  const axiosBearer = useAxiosBearer();
  const companyID = useSelector((state) => state.clientToEdit.clientToEdit.id);
  const navigate = useNavigate();
  const projectInfo = useSelector((state) => {
    return add ? {} : state.projectOrTermToEdit.projectOrTermToEdit;
  });
  const [validatedForm, setValidatedForm] = useState(false);
  const projectFormRef = useRef();
  const numRef = useRef();
  const multiannualRef = useRef();
  const macroRef = useRef();
  const microRef = useRef();
  const leaderRef = useRef();
  const benefitRef = useRef();
  const segmentRef = useRef();
  const refs = {
    num: numRef,
    multiannual: multiannualRef,
    macro: macroRef,
    micro: microRef,
    leader: leaderRef,
    benefit: benefitRef,
    segment: segmentRef
  };
  const [yearsInput, setYearsInput] = useState(projectInfo.years);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("ok");
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [isMultiAnnual, setIsMultiAnnual] = useState("SIM")

  const getMultiAnnual = (value) => {
    setIsMultiAnnual(value)
  }

  const confirmGoBack = () => {
    if (alertType !== "error") {
    setAlert(false)
    navigate(-1);
    }
    else {
      setAlert(false);
    }    
  };  

  const checkBenefitID = () => {
    if(benefitRef.current.value === "Lei da Informática")
      return 1
    if(benefitRef.current.value === "Lei do Bem")
      return 2
    if(benefitRef.current.value === "Rota 2030")
      return 3
    if (benefitRef.current.value === "Mover")
      return 4
  }

  const validateProject = () => {
    setValidatedForm(true)
    if(numRef.current.value.length === 0)
      return false
    if(microRef.current.value.length === 0 || microRef.current.value.length > 40)
      return false
    if(leaderRef.current.value.length === 0)
      return false
    
    return true
  }
  
  const handleAlert = (message, type) => {
    setAlert(true);
    setAlertMessage(message);
    setAlertType(type);      
    setLoading(false);
  }

  const validateYears = () => {
    setValidatedForm(true);

    if(!yearsInput || yearsInput?.length === 0){
      handleAlert("Insira o ano do projeto", "error")
      return false
    }
    if(multiannualRef.current.value === "SIM" && yearsInput.length < 2){
      handleAlert("Insira pelo menos 2 anos", "error")
      return false
    }
    if(multiannualRef.current.value === "NÃO" && yearsInput.length > 1){
      handleAlert("Insira apenas 1 ano", "error")
      return false      
    }
    
    return true
  }

  //create callbacks to pass to SaveAndDelete component
  const onSave = () => {
    //console.log("added/edited project");
    setLoading(true);
    
    if(!validateProject()){
      handleAlert("Preencha todos os campos obrigatórios", "error")
      return
    } else {
      if(!validateYears()) return

      const projectData = {
        name: `${macroRef.current.value}?${microRef.current.value}`,
        number: numRef.current.value === "" ? null : numRef.current.value,
        leader: leaderRef.current.value === "" ? null : leaderRef.current.value,
        project_years: yearsInput,
        multiannual: multiannualRef.current.value === "SIM" ? true : false,
        segment: segmentRef.current.value,
        company: companyID,
        benefit: checkBenefitID()
      };
      if (add) {
        axiosBearer.post("projects/", projectData).then((response) => {
          setAlert(true);
          setAlertMessage("Projeto adicionado com sucesso!");
          setAlertType("ok");
          setLoading(false);
        }).catch((error) => {
          setAlert(true);
          setAlertMessage(error.response.data);
          setAlertType("error");
          setLoading(false);
        });
      } else {
        axiosBearer.put(`projects/${projectInfo.id}/`, projectData).then((response) => {
          setAlertMessage("Projeto editado com sucesso!");
            setAlert(true);
            setAlertType("ok");  
            setLoading(false);        
        }).catch((error) => {
          setAlert(true);
          setAlertMessage("Erro ao editar projeto");
          setAlertType("error");
          setLoading(false);
        });
      }
    }
    setValidatedForm(true);
  };

  const onDelete = () => {
    //console.log("deleted project");    
    setDeleteAlert(false);
    setValidatedForm(true);
    setLoading(true);
    axiosBearer.delete(`projects/${projectInfo.id}/`).then((response) => {
      setAlert(true);
      setAlertMessage("Projeto excluído com sucesso!");
      setAlertType("ok");
      setLoading(false);
    }).catch((error) => {
      setAlert(true);
      setAlertMessage("Erro ao excluir projeto");
      setAlertType("error");
      setLoading(false);
    })
  };

  return (
    <>
      <Loading visible={loading} />
      <CustomAlert visible={alert} type={alertType} message={alertMessage} onAccept={confirmGoBack} />
      <CustomAlert visible={deleteAlert} type="prompt" message="Deseja realmente excluir este projeto?" onAccept={onDelete} onRefuse={() => setDeleteAlert(false) } />      
      <Row>
        <Col md={6} className="py-3">
          <HeadedCard title="Informações cadastrais">
            <ProjectInfo
              formRef={projectFormRef}
              validated={validatedForm}
              contentRefs={refs}
              fieldData={projectInfo}
              getMultiAnnual={getMultiAnnual}
            />
          </HeadedCard>
        </Col>
        <Col md={6} className="py-3">
          <HeadedCard title="Ano-Calendário">
            <AddYear
              initialYears={add ? [] : projectInfo.years}
              sendYears={setYearsInput}
              isMultiAnnual={isMultiAnnual}
            />
          </HeadedCard>
        </Col>
      </Row>
      <SaveAndDelete
        saveCallback={onSave}
        deleteCallback={() => setDeleteAlert(true)}
        addMode={add}
      />
    </>
  );
};
