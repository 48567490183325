import { Form, Row, Stack, Col, Button } from "react-bootstrap"
import { capitalControlador, incentivosLei, organismoOptions, relacaoGrupo, situacaoOptions } from "../NavLists";
import { useEffect, useRef, useState } from "react";
import { HeadedCard } from "../Components/HeadedCard";
import { IMaskInput } from "react-imask";
import { SaveAndDelete } from "../Components/SaveAndDelete";
import Loading from "../Components/Loading/Loading";
import { axiosBearer } from "../api/Api";
import { useSelector } from "react-redux";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import { useNavigate } from "react-router-dom";
import useDownloadIdentifier from "../Hooks/useDownloadIdentifier";

export const FormularioAtualizacao = ({ add = false, expired = false }) => {
  const navigate = useNavigate();
  const downloadRequest = useDownloadIdentifier();

  const [loading, setLoading] = useState(false)
  const companyId = useSelector((state) => {
    return state.currentUser.selectedCompany.id
  })
  const companyName = useSelector((state) => {
    return state.currentUser.selectedCompany.name
  })
  const userRole = useSelector((state) => {
    return state.currentUser.role
  })
  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState("ok");
  const [alertMessage, setAlertMessage] = useState("");


  const [formId, setFormId] = useState(null)
  const [newForm, setNewForm] = useState(false)
  const [receitaLiquidaValue, setReceitaLiquidaValue] = useState("")
  const [funcionarios, setFuncionarios] = useState(null)
  const [organismoValue, setOrganismoValue] = useState("")
  const [incentivosValue, setIncentivosValue] = useState("")
  const [capitalValue, setCapitalValue] = useState("")
  const [situacaoEmpresaValue, setSituacaoEmpresaValue] = useState("Em operação")
  const [relacaoGrupoValue, setRelacaoGrupoValue] = useState("Controladora")
  const formRef = useRef();

  const situacaoRef = useRef();
  const relacaoGrupoRef = useRef();
  const organismoRef = useRef();

  useEffect(() => {
    const request = async () => {
      try {
        const response = await axiosBearer.get(`update-form/by-company/${companyId}`)
        if (response.status === 200) {
          setFuncionarios(response.data.numero_funcionarios)
          setCapitalValue(response.data.origem_capital)
          setIncentivosValue(response.data.beneficios_lei_8248)
          setOrganismoValue(response.data.tipo_organismo)
          setSituacaoEmpresaValue(response.data.situacao_empresa)
          setRelacaoGrupoValue(response.data.relacao_grupo)
          setReceitaLiquidaValue(formatToCurrency(response.data.receita_liquida))
          setFormId(response.data.id)
          console.log("deu bom")
        }
      } catch (error) {
        if (error.response.status === 404) {
          setNewForm(true)
        }
      }
    }
    request()
  }, [])

  const confirmGoBack = () => {
    if (alertType !== "error") {
      setAlert(false);
      if (userRole === 'client') {
        navigate('/cliente/Dashboard');
      } else {
        navigate('/consultor/Dashboard');
      }
    } else {
      setAlert(false);
    }
  };

  function formatToCurrency(value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  function formatToFloat(value) {
    const cleanValue = value.replace(/[R$.\s]/g, '').replace(',', '.');
    return parseFloat(cleanValue);
  }

  const handleInputChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^\d]/g, '');
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const formattedValue = new Intl.NumberFormat('pt-BR', options).format(value / 100);
    setReceitaLiquidaValue(formattedValue);
  };

  const onSave = (event) => {
    event.preventDefault()
    const formData = {
      tipo_organismo: organismoValue,
      situacao_empresa: situacaoEmpresaValue,
      beneficios_lei_8248: incentivosValue,
      origem_capital: capitalValue,
      relacao_grupo: relacaoGrupoValue,
      receita_liquida: formatToFloat(receitaLiquidaValue),
      numero_funcionarios: Number(funcionarios),
      company: companyId,
      expired: expired
    }

    if (newForm) {
      axiosBearer
        .post("update-form/", formData)
        .then((res) => {
          setLoading(false);
          setAlert(true);
          setAlertType("ok")
          setAlertMessage("Formulário salvo com sucesso")
        })
        .catch((error) => {
          setLoading(false)
          setAlert(true);
          setAlertType("error");
          setAlertMessage("Erro ao criar o formulário");
          console.error("Erro ao criar o formulario")
        })
    } else {
      axiosBearer
        .patch(`update-form/${formId}/`, formData)
        .then((res) => {
          setLoading(false); setLoading(false);
          setAlert(true);
          setAlertType("ok")
          setAlertMessage("Formulário atualizado com sucesso")
          console.log("Formulário atualizado com sucesso", res.data);
        })
        .catch((error) => {
          setLoading(false);
          setAlert(true);
          setAlertType("error");
          setAlertMessage("Erro ao atualizar o formulário");
          console.error("Erro ao atualizar o formulário:", error);
        });
    }
  }

  return (
    <>

      <Loading visible={loading} />
      <CustomAlert
        visible={alert}
        type={alertType}
        message={alertMessage}
        onAccept={confirmGoBack}
      />
      {
        <div className="text-end">
          <Button size="md"
            onClick={() => downloadRequest(companyId, companyName)}
          >
            Download XLSX
          </Button>
        </div>
      }

      <HeadedCard >
        <Form onSubmit={onSave}>
          <Stack gap={4} className="p-3">
            <Row>
              <Form.Group as={Col} md="6" ref={organismoRef}>
                <Form.Label>Tipo de Organismo*</Form.Label>
                {organismoOptions.map((option) =>
                  <>
                    <Form.Check
                      type="radio"
                      id={option}
                      label={option}
                      name="organismo"
                      value={option}
                      checked={option === organismoValue}
                      onChange={(e) => setOrganismoValue(e.target.value)}
                      required
                    />
                  </>
                )
                }
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Situação da Empresa*</Form.Label>
                <Form.Select
                  aria-label="Seleção de situação da empresa"
                  value={situacaoEmpresaValue}
                  ref={situacaoRef}
                  onChange={(e) => setSituacaoEmpresaValue(e.target.value)}
                >
                  {situacaoOptions.map((op, index) => {
                    return <option key={index}>{op}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label>Nos termos do Art, 26 da Lei 11.196/05. A empresa se beneficia dos incentivos da Lei nº 8.248/1991 (Relacionada à informática e automação)?*</Form.Label>
                {incentivosLei.map((option, index) =>
                  <>
                    <Form.Check
                      type="radio"
                      id={option}
                      label={option.label}
                      name="incentivos"
                      value={option.value}
                      checked={option.value === incentivosValue}
                      onChange={() => setIncentivosValue(option.value)}
                      required
                    />
                  </>
                )
                }
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label>Origem do capital controlador da empresa*</Form.Label>
                {capitalControlador.map((option) =>
                  <>
                    <Form.Check
                      type="radio"
                      id={option}
                      label={option}
                      name="capital"
                      value={option}
                      checked={option === capitalValue}
                      onChange={(e) => setCapitalValue(e.target.value)}
                      required
                    />
                  </>
                )
                }
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="3">
                <Form.Label>Qual a sua relação com o grupo?*</Form.Label>
                <Form.Select
                  aria-label="relação com o grupo"
                  value={relacaoGrupoValue}
                  ref={relacaoGrupoRef}
                  onChange={(e) => setRelacaoGrupoValue(e.target.value)}
                >
                  {relacaoGrupo.map((op, index) => {
                    return <option key={index}>{op}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label>Qual o valor da receita líquida da empresa no ano base?*</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="500.000,00"
                  value={receitaLiquidaValue}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group as={Col} md="5">
                <Form.Label>O número total de funcionários com vínculo empregatício com a empresa*</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="25"
                  value={funcionarios}
                  onChange={(e) => setFuncionarios(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <h6 className="text-danger">* campo obrigatório</h6>
            </Row>
          </Stack>
          <div className="text-end">
            <Button type="submit">Salvar</Button>

          </div>
        </Form>
      </HeadedCard>
    </>
  )
}