import React, { useCallback, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { useFormatedDate } from "../Hooks/useFormatedDate";
import { ConfirmDeleteModal } from "./ConfirmDeleteModal";

export const TemplateCard = ({ templateData, deletingSignal }) => {
  const [showModal, setShowModal] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const renderDate = useFormatedDate();

  const axiosBearer = useAxiosBearer();
  const deleteFile = () => {
    axiosBearer.delete(`documents/templates/${templateData.id}/`);
    setShowModal(false);
    deletingSignal(true);
  };

  const DownloadFile = useCallback(async () => {
    if (templateData) {
      const fileData = {};
      //find where the name of the file begins on the string
      const indexOfFilename = templateData.file.lastIndexOf("/");
      fileData.name = templateData.file.slice(indexOfFilename + 1); //excludes the "/"
      fileData.uploadDate = new Date(templateData.upload_date);
      fileData.segment = templateData.segment;
      const fileToDownload = await axiosBearer.get(
        `documents/templates/${templateData.id}/download/`,
        { responseType: "blob" }
      );
      fileData.fileURL = URL.createObjectURL(fileToDownload.data);
      setFileInfo(fileData);
    }
  }, [axiosBearer]);

  useEffect(() => {
    DownloadFile();
  }, [DownloadFile]);

  return (
    <>
      <Card>
        <Container>
          <Row className="p-3">
            <Col as="div" md={7} className="d-flex flex-column gap-2">
              <Card.Title className="custom-title">
                {templateData.file}
              </Card.Title>
              <Card.Subtitle className="small">
                <p>{fileInfo?.segment ? `${fileInfo.segment}` : ""}</p>
                <p>
                  {fileInfo?.uploadDate
                    ? `Atualização: ${renderDate(fileInfo.uploadDate)}`
                    : "Nenhum template existente"}
                </p>
              </Card.Subtitle>
            </Col>
            <Col
              as="div"
              md={5}
              className="d-flex align-items-center justify-content-center gap-2"
            >
              <Button>
                <a
                  download={fileInfo?.name}
                  href={fileInfo?.fileURL}
                  className="download-button"
                >
                  Download template
                </a>
              </Button>
              <Button
                variant="outline-danger"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Deletar template
              </Button>
            </Col>
          </Row>
        </Container>
      </Card>
      <ConfirmDeleteModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        confirmAction={deleteFile}
      />
    </>
  );
};
