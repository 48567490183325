import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import useAxiosBearer from "./useAxiosBearer"

const useRefresh = (documentID) => {
	const [count, setCount] = useState(0)
	const [files, setFiles] = useState([])
	const company = useSelector((state)=>state.currentUser.selectedCompany)
	const currentYear = useSelector((state)=>state.currentUser.currentYear)
	const axiosBearer = useAxiosBearer()
	const noFilterAreas = [
		"document-Dossie", "document-Calculo", "document-Parecer", "document-Auditoria", "document-Forms", "document-Obligations"
	]
	const docTypes = useSelector((state) => state.docTypes.docTypes);

  const getDocTypeID = (areaName) => {
    const docTypeObject = docTypes.find((type) => type.name === areaName);

    if (docTypeObject) return docTypeObject.id;
    return
  };

  const handleRefresh = () => {
    //increases by 1 when a file is uploaded, forcing the rerender of the component
    setCount(state=>state+1)
		setTimeout(()=>{
			setCount(state=>state+1)
		}, 500) //Não remover o setTimeout
    //console.log(count)
  }

	useEffect(()=>{
		const request = async () => {
			let filters = { company: company.id, document_type: documentID }
			let areas = noFilterAreas.map((item)=>{
				return getDocTypeID(item)
			})

			if(!areas.includes(documentID))
				filters = { ...filters, year: currentYear }

			const response = await axiosBearer.get("companies/documents/", { params: filters})
			setFiles(response.data)
		}
		request()
	},[count, axiosBearer, company, documentID, currentYear])

	return [files, handleRefresh]
}

export default useRefresh