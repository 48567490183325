import React from "react";
import { HeadedCard } from "../Components/HeadedCard";
import { InteractionsDivider } from "../Components/InteractionsDivider";
import { PageHeader } from "../Components/PageHeader";
import { FileTable } from "../Components/FileTable";
import { YearSelectorContainer } from "../Components/YearSelectorContainer";
import useRefresh from "../Hooks/useRefresh";
import { useGetDescription } from "../Hooks/useGetDescription";

const PAGE_TITLE = "Folha de pagamento"

export const FolhaDePagamento = ({ mode = "client", DOC_ID, TEMPLATE_ID }) => {
  const [files, handleRefresh] = useRefresh(DOC_ID);
  const description = useGetDescription(PAGE_TITLE)

  return (
    <>
      <YearSelectorContainer />
      <PageHeader title={PAGE_TITLE}>
        {description}
      </PageHeader>
      {mode === "client" ? (
        <InteractionsDivider
          download
          upload
          documentID={TEMPLATE_ID}
          tableDocumentID={DOC_ID}
          refresh={handleRefresh}
        />
      ) : (
        <InteractionsDivider download documentID={TEMPLATE_ID} />
      )}
      <HeadedCard title="Arquivos para download">
        <FileTable filesArray={files} refreshTable={handleRefresh} />
      </HeadedCard>
    </>
  );
};
