import React from "react";
import Badge from "react-bootstrap/Badge";

export const CustomBadge = ({ type }) => {
  //types: success, principal, inactive

  return (
    <Badge pill className={`${type}-badge`} bg={""}>
      .
    </Badge>
  );
};
