import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { StepProgressBar } from "./StepProgressBar";
import {
  apuracaoLeiBemRota2030,
  apuracaoLeiInformatica,
  avaliacaoLeiInformatica,
  avaliacaoLeiBem,
  avaliacaoRota2030,
  analiseRota2030LeiInformatica,
  analiseLeiBem,
} from "../benefitRules.js";
import useAxiosBearer from "../Hooks/useAxiosBearer";

export const StepProgressCard = ({
  hasEdit = false,
  stepProgressData = {},
}) => {
  const axiosBearer = useAxiosBearer();
  const [isEditing, setIsEditing] = useState(false);
  const [progressSteps, setProgressSteps] = useState([]);
  const [stepToUpdate, setStepToUpdate] = useState(null);

  const bars = [
    {
      name: "Ciclo de Apuração do Benefício",
      benefit: [2, 3, 4],
      bar: apuracaoLeiBemRota2030,
    },
    {
      name: "Ciclo de Apuração do Benefício",
      benefit: [1],
      bar: apuracaoLeiInformatica,
    },
    {
      name: "Ciclo de Avaliação dos Projetos",
      benefit: [2],
      bar: avaliacaoLeiBem,
    },
    {
      name: "Ciclo de Avaliação dos Projetos",
      benefit: [1],
      bar: avaliacaoLeiInformatica,
    },
    {
      name: "Ciclo de Avaliação dos Projetos",
      benefit: [3, 4],
      bar: avaliacaoRota2030,
    },
    {
      name: "Ciclo de Análise MCTI",
      benefit: [2],
      bar: analiseLeiBem,
    },
    {
      name: "Ciclo de Análise MCTI e Auditoria",
      benefit: [1, 3, 4],
      bar: analiseRota2030LeiInformatica,
    },
  ];

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
    if (isEditing && stepToUpdate !== null) {
      axiosBearer
        .patch(`benefitsteps/${stepProgressData.id}/`, { step: stepToUpdate })
        .then(() => {
          setStepToUpdate(null);
        });
    }
  };

  useEffect(() => {
    const barData = bars.find((item) => {
      return (
        item.name === stepProgressData.name &&
        item.benefit.includes(stepProgressData.benefit)
      );
    });
    setProgressSteps(barData?.bar);
  }, []);

  return (
    <section>
      {hasEdit && (
        <div className="py-2">
          <Button onClick={toggleEditMode}>
            {isEditing ? "Salvar" : "Editar"}
          </Button>
        </div>
      )}
      <Card
        className={`p-2 ${isEditing ? "border border-3 border-success" : ""}`}
      >
        <Card.Title className="text-center p-3">
          {stepProgressData?.name || "insert title here"}
        </Card.Title>
        <StepProgressBar
          editMode={isEditing}
          stepProgressData={progressSteps}
          step={stepProgressData.step}
          setStep={(value) => {
            setStepToUpdate(value);
          }}
        />
      </Card>
    </section>
  );
};
