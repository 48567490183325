import React, { useCallback, useRef, useState } from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { useFormatedDate } from "../Hooks/useFormatedDate";
import { StatusPopover } from "./StatusPopover";
import useDownloadFile from "../Hooks/useDownloadFile";
import { createPortal } from "react-dom";
import CustomAlert from "./CustomAlert/CustomAlert";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { Icon } from "./Icon";

export const StoryTableRow = ({ file, refreshTable, index }) => {
  const userRole = useSelector((state) => state.currentUser.role);
  const renderDate = useFormatedDate();
  const downloadFile = useDownloadFile();
  const axiosBearer = useAxiosBearer();
  const [deleteAlert, setDeleteAlert] = useState(false);

  const dateUploaded = new Date(file.upload_date);
  let author;
  if (file?.user_role === "consultant") {
    author = "Martinelli";
  } else {
    author = "Cliente";
  }

  const statusTypes = {
    aguardandoAnalise: 0,
    revisado: 1,
  };

  const statusInfo = [
    { text: "Aguardando análise", color: "secondary" },
    { text: "Revisado", color: "danger" },
  ];

  //overlay variables
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const overlayRef = useRef();

  const handleOverlay = (e) => {
    /*if (userRole === "consultant") {
      if (show) refreshTable();
      setShow(!show);
      setTarget(e.target);
    }*/
    setShow((show) => {
      return !show;
    });

    if (!show) {
      document.addEventListener("click", handleClickOutside, true);
      document.fileRowID = file.id;
    }

    if (show && userRole === "consultant") {
      refreshTable();
    }
    setTarget(e.target);
  };

  const handleClickOutside = useCallback((e) => {
    if (
      !overlayRef.current.contains(e.target) &&
      document.fileRowID === file.id
    ) {
      //console.log(`executing click outside ${file.id}`);
      setShow(false);
      refreshTable();
      document.removeEventListener("click", handleClickOutside, true);
    }
  }, []);

  const deleteFile = () => {
    axiosBearer.delete(`companies/documents/${file.id}/`).then(() => {
      refreshTable();
    });
  };

  return (
    <>
      {createPortal(
        <CustomAlert
          visible={deleteAlert}
          type="prompt"
          message="Deseja realmente excluir este arquivo?"
          onAccept={()=>{
            deleteFile()
            setDeleteAlert(false)
          }}
          onRefuse={() => setDeleteAlert(false)}
        />,
        document.body
      )}
      <tr style={{ height: "55px" }}>
        <td className="text-center">{file?.description}</td>
        <td className="text-center">{author}</td>
        <td className="text-center">{renderDate(dateUploaded)}</td>
        <td className="text-center" /*ref={overlayRef}*/>
          {file?.status === "none" ? (
            "-"
          ) : (
            <>
              <Badge
                bg={statusInfo[statusTypes[file?.status]].color}
                style={userRole === "consultant" ? { cursor: "pointer" } : {}}
                onClick={handleOverlay}
                ref={overlayRef}
              >
                {statusInfo[statusTypes[file?.status]].text}
              </Badge>
              <StatusPopover
                show={show}
                target={target}
                overlayRef={overlayRef}
                changeStatus
                table={"storyTable"}
                initialStatus={file.status}
                file={file}
              />
            </>
          )}
        </td>
        <td>
          <div className="d-flex gap-2">
            <Button
              className="w-75"
              variant="outline-secondary"
              onClick={() => {
                downloadFile(file);
              }}
            >
              Download
            </Button>
            {userRole === file?.user_role && ((userRole === "consultant" && index === 0 && file?.description.includes("Revisão")) || (userRole === "client" && file.status === "aguardandoAnalise")) && (
              <Button
                variant="danger"
                onClick={() => {
                  setDeleteAlert(true);
                }}
              >
                <Icon icon={"Trash"} />
              </Button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};
