export const apuracaoLeiBemRota2030 = [
  {
    name: "CICLO A INICIAR",
    left: "null",
    right: false,
    completed: true,
  },
  {
    name: "CÁLCULO EM ANDAMENTO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "CÁLCULO FINALIZADO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "UTILIZAÇÃO DO BENEFÍCIO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "VALIDAÇÃO REGISTRO BENEFÍCIO EFC",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "RETIFICAÇÕES",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "CICLO FINALIZADO",
    left: false,
    right: "null",
    completed: false,
  },
];

export const apuracaoLeiInformatica = [
  {
    name: "CICLO A INICIAR",
    left: "null",
    right: false,
    completed: true,
  },
  {
    name: "CÁLCULO EM ANDAMENTO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "CÁLCULO FINALIZADO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "UTILIZAÇÃO DO BENEFÍCIO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "CÁLCULO FINALIZADO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "COMPENSAÇÃO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "CICLO FINALIZADO",
    left: false,
    right: "null",
    completed: false,
  },
];

export const avaliacaoLeiInformatica = [
  {
    name: "CICLO A INICIAR",
    left: "null",
    right: false,
    completed: true,
  },
  {
    name: "PROJETOS EM ANÁLISE",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "AGUARDANDO INFORMAÇÕES ADICIONAIS",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "INFORMAÇÕES SUFICIENTES",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "RDA ENTREGUE",
    left: false,
    right: "null",
    completed: false,
  },
]

export const avaliacaoLeiBem = [
  {
    name: "CICLO A INICIAR",
    left: "null",
    right: false,
    completed: true,
  },
  {
    name: "PROJETOS EM ANÁLISE",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "AGUARDANDO INFORMAÇÕES ADICIONAIS",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "INFORMAÇÕES SUFICIENTES",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "FORMULÁRIO ENTREGUE",
    left: false,
    right: "null",
    completed: false,
  },
]

export const avaliacaoRota2030 = [
  {
    name: "CICLO A INICIAR",
    left: "null",
    right: false,
    completed: true,
  },
  {
    name: "PROJETOS EM ANÁLISE",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "AGUARDANDO INFORMAÇÕES ADICIONAIS",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "INFORMAÇÕES SUFICIENTES",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "FORMULÁRIO ENTREGUE",
    left: false,
    right: false,
    completed: false,
  },
	{
    name: "PORTARIA Nº 165/2019",
    left: false,
    right: false,
    completed: false,
  },
	{
    name: "CICLO FINALIZADO",
    left: false,
    right: "null",
    completed: false,
  },
]

export const analiseRota2030LeiInformatica = [
  {
    name: "CICLO A INICIAR",
    left: "null",
    right: false,
    completed: true,
  },
  {
    name: "AUDITORIA EM ANDAMENTO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "INFORMAÇÕES ADICIONAIS",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "INFORMAÇÕES SUFICIENTES",
    left: false,
    right: false,
    completed: false,
  },
	{
    name: "PROTOCOLO DE ENTREGA",
    left: false,
    right: "null",
    completed: false,
  },
]

export const analiseLeiBem = [
  {
    name: "CICLO A INICIAR",
    left: "null",
    right: false,
    completed: true,
  },
  {
    name: "PARECER TÉCNICO PEDIDO CONTESTAÇÃO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "PARECER COMPLEMENTAR RECURSO ADMINISTRATIVO",
    left: false,
    right: false,
    completed: false,
  },
  {
    name: "OFÍCIO",
    left: false,
    right: false,
    completed: false,
  },
	{
    name: "CICLO FINALIZADO",
    left: false,
    right: "null",
    completed: false,
  },
]