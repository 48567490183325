import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  docTypes: []
}

const docTypesSlice = createSlice({
  name: "docTypes",
  initialState,
  reducers: {
    setDocTypes(state, action){
      state.docTypes = action.payload
    },
    resetData(state){
      state.docTypes = []
    }
  }
})

export const docTypesActions = docTypesSlice.actions;

export default docTypesSlice;