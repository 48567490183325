import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../Hooks/useAuth";
import jwt_decode from "jwt-decode";

export const RequireAuth = ({ allowedRole }) => {
  const auth = useAuth();
  const location = useLocation();
  let decodedInfo;

  if (auth?.access) {
    decodedInfo = jwt_decode(auth?.access);
  }

  return decodedInfo?.role === allowedRole ? ( //shows the route only if user is logged in
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
