import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const ConfirmDeleteModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirmação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Certeza que deseja excluir o arquivo?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={()=>{props.onHide()}}>Cancelar</Button>
          <Button variant="danger" onClick={props.confirmAction}>Deletar</Button>
        </Modal.Footer>
    </Modal>
  );
};
