import React, { useEffect, useState, useCallback } from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { UploadManager } from "./UploadManager";
import useDownloadTemplate from "../Hooks/useDownloadTemplate";

export const InteractionsDivider = ({
  download,
  upload,
  documentID = null,
  refresh,
  tableDocumentID = null,
  mode = "common",
  segment = null
}) => {
  const [triggerModal, setTriggerModal] = useState(false);
  const [fileInfo, setFileInfo] = useState([]);
  const axiosBearer = useAxiosBearer();
  const downloadTemplate = useDownloadTemplate()

  const handleModal = useCallback(() => {
    setTriggerModal(false);
    refresh();
  }, [refresh]);

  const fetchTemplates = useCallback(async () => {
    const getTemplates = await axiosBearer.get("documents/templates/", {
      params: { document_type: documentID, segment: segment },
    });
    
    const templateArray = getTemplates.data.map((temp) => {
      const fileData = {};

      fileData.id = temp.id
      fileData.file = temp.file;
      
      return fileData;
    });

    setFileInfo(templateArray);
  }, [axiosBearer, documentID]);

  useEffect(() => {
    if(documentID){
      fetchTemplates();
    }
  }, [fetchTemplates]);

  return (
    <>
      <Stack direction="horizontal" gap={2}>
        {download && (
          <>
            <DropdownButton
              id="dropdown-basic-button"
              title="Templates"
              variant="outline-secondary"
            >
              {fileInfo.map((file) => {
                return (
                  <Dropdown.Item
                    key={file.id}
                    onClick={()=>{downloadTemplate(file)}}
                  >
                    {file?.file}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </>
        )}
        {upload && (
          <Button
            onClick={() => {
              setTriggerModal(true);
            }}
            className="ms-auto px-4"
          >
            Adicionar arquivo
          </Button>
        )}
      </Stack>
      {upload && (
        <UploadManager
          documentID={tableDocumentID}
          triggerModal={triggerModal}
          handleModal={handleModal}
          mode={mode}
        />
      )}
    </>
  );
};
