import React, { useCallback, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { useSelector } from "react-redux";
import { UploadManager } from "./UploadManager";
import { StatusPopover } from "./StatusPopover";
import { useFormatedDate } from "../Hooks/useFormatedDate";
import { Icon } from "./Icon";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { createPortal } from "react-dom";
import CustomAlert from "./CustomAlert/CustomAlert";
import useDownloadFile from "../Hooks/useDownloadFile";

export const TimesheetTable = ({ file, refreshTable }) => {
  const [triggerModal, setTriggerModal] = useState(false);
  const userRole = useSelector((state) => state.currentUser.role);
  const dateUploaded = new Date(file?.upload_date);
  const renderDate = useFormatedDate();
  const handleModal = useCallback(() => {
    setTriggerModal(false);
    refreshTable();
  }, [refreshTable]);
  const axiosBearer = useAxiosBearer();
  const downloadFile = useDownloadFile()
  const [deleteAlert, setDeleteAlert] = useState(false)

  const statusTypes = {
    aguardandoAnalise: 0,
    aprovado: 1,
    reprovado: 2,
  };

  const statusInfo = [
    { text: "Aguardando análise", color: "secondary" },
    { text: "Aprovado", color: "success" },
    { text: "Reprovado", color: "danger" },
  ];

  //overlay variables
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const overlayRef = useRef();

  const handleOverlay = (e) => {
    if (userRole === "consultant") {
      setShow(!show);
      setTarget(e.target);
      refreshTable();
    }
  };

  const deleteFile = () => {
    axiosBearer.delete(`companies/documents/${file.id}/`).then(() => {
      refreshTable();
    });
  };

  return (
    <>
      {createPortal(
        <CustomAlert
          visible={deleteAlert}
          type="prompt"
          message="Deseja realmente excluir este arquivo?"
          onAccept={()=>{
            deleteFile()
            setDeleteAlert(false)
          }}
          onRefuse={() => {setDeleteAlert(false)}}
        />,
        document.body
      )}
      <Table className="align-middle" responsive>
        <thead>
          <tr>
            <th className="text-center">Nome do arquivo</th>
            <th className="text-center">Data do upload</th>
            <th className="text-center">Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!file && (
            <tr className={userRole === "client" ? "custom-table-bg" : ""}>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">
                <Badge
                  bg={`${userRole === "client" ? "warning" : "secondary"}`}
                  text={`${userRole === "client" ? "dark" : "light"}`}
                >
                  Aguardando Upload
                </Badge>
              </td>
              <td>
                {userRole === "client" && (
                  <Button
                    className="w-75"
                    onClick={() => {
                      setTriggerModal(true);
                    }}
                  >
                    Upload
                  </Button>
                )}
              </td>
            </tr>
          )}
          {file && (
            <tr
              className={
                userRole === "consultant" && file.status === "aguardandoAnalise"
                  ? "custom-table-bg"
                  : ""
              }
            >
              <td className="text-center">{file.description}</td>
              <td className="text-center">{renderDate(dateUploaded)}</td>
              <td className="text-center" ref={overlayRef}>
                <Badge
                  bg={
                    file.status === "aguardandoAnalise"
                      ? "warning"
                      : statusInfo[statusTypes[file?.status]]?.color
                  }
                  text={file.status === "aguardandoAnalise" ? "dark" : null}
                  style={userRole === "consultant" ? { cursor: "pointer" } : {}}
                  onClick={handleOverlay}
                >
                  {statusInfo[statusTypes[file?.status]]?.text}
                </Badge>
                <StatusPopover
                  show={show}
                  target={target}
                  overlayRef={overlayRef}
                  table={"timesheet"}
                  changeStatus
                  initialStatus={file.status}
                  file={file}
                />
              </td>
              <td>
                {userRole === "consultant" && (
                  <>
                    <Button
                      className="w-75"
                      variant="outline-secondary"
                      onClick={()=>{downloadFile(file)}}
                    >
                      Download
                    </Button>
                  </>
                )}
                {userRole === "client" && (
                  <Button
                    variant="danger"
                    onClick={() => {
                      setDeleteAlert(true);
                    }}
                  >
                    <Icon icon={"Trash"} />
                  </Button>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <UploadManager
        documentID={9}
        triggerModal={triggerModal}
        handleModal={handleModal}
      />
    </>
  );
};
