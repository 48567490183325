import React from "react";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { Icon } from "./Icon";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"

export const ClientBanner = ({type}) => {
  const companyInfo = useSelector((state) => state.currentUser.selectedCompany);
  const navigate = useNavigate()

  const chooseClient = () => {
    if(type === "client"){
      navigate("/cliente/Empresas/", {replace:true})
    }
    if(type === "consultant"){
      navigate("/consultor/Empresas", {replace:true})
    }
  }

  return (
    <Card className="p-2">
      <Card.Body
        className="d-flex flex-column justify-content-center align-items-center flex-md-row flex-md-wrap"
        style={{ gap: "1rem" }}
      >
        <Badge className="icon-badge" bg={""}>
          <Icon icon={"BuildingOffice"} className="icon-big" />
        </Badge>
        <Card.Title
          as="p"
          className="flex-grow-1 p-0 px-xl-4 m-0 fs-3 fw-bold align-banner-text"
        >
          {companyInfo.name}
          <span className="custom-span">
            {" - "}
            {companyInfo?.cnpj.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            )}
          </span>
        </Card.Title>
        <div className="d-flex align-items-center">
          <Button className="fs-4 flex-shrink-0 py-2 px-4" onClick={chooseClient}>Trocar</Button>
        </div>
      </Card.Body>
    </Card>
  );
};
