import {useState, useEffect} from "react"
import useAxiosBearer from "./useAxiosBearer"
import {useSelector} from "react-redux";

const useBackPagination = (config, type) => {
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(config.initialPage)
    const [itemsToShow, setItemsToShow] = useState()
    const [amountOfItems, setAmountOfItems] = useState()
    const axiosBearer = useAxiosBearer()

    const request = async () => {
        const response = await axiosBearer.get(config.path, {
            params: {
                search: config.searchedName,
                page: currentPage,
                page_size: config.amountPerPage,
                ...config.reqParams
            }
        })
        setAmountOfItems(response.data.count)
        const items = response.data.results?.map(item => {
            const itemData = {}

            if (type === "ClientesAdminList") {
                const isMain = item.company_group?.main_company === item.id;

                return {
                    id: item.id,
                    name: item.name,
                    cnpj: item.cnpj,
                    uf: item.uf,
                    obs: item.obs,
                    group: item.company_group ? item.company_group.name : null,
                    principal: isMain,
                    emailsCliente: item.emails
                        .filter((email) => {
                            return !email.ismartinelli;
                        })
                        .map((e) => {
                            return e.email;
                        })
                        .join("; "),
                    emailsMartinelli: item.emails
                        .filter((email) => {
                            return email.ismartinelli;
                        })
                        .map((e) => {
                            return e.email;
                        })
                        .join("; "),
                    benefits: item.benefits,
                    template_ocr: item.template_ocr ? item.template_ocr.name : null,
                    date_revision: item.date_revision,
                    next_validation: item.next_validation,
                };
            }
            if (type === "ConsultoresList") {
                return {
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    isActive: item.is_active,
                    user_companies: item.company,
                };
            }
            if (type === "Project") {
                return {
                    id: item.id,
                    number: item.number,
                    macroproject: item.name.split("?")[0],
                    microproject: item.name.split("?")[1],
                    multiannual: item.multiannual,
                    leader: item.leader,
                    years: item.years,
                    company: item.company,
                    benefit: item.benefit,
                    segment: item.segment
                };
            }
            if (type === "Users") {
                return {
                    id: item.id,
                    name: item.name,
                    email: item.email,
                    isActive: item.is_active,
                    isController: item.is_controller,
                    permissions: item.user_permissions
                }
            }

            for (let key of config.objectFields) {
                itemData[key] = item[key]
            }

            return itemData
        })
        setItemsToShow(items)
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        request()
    }, [currentPage])

    useEffect(() => {
        request()
    }, [config.searchedName])

    return [itemsToShow, setCurrentPage, loading, amountOfItems]
}

export default useBackPagination