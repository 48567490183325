import React, { useRef, useState } from "react";
import { HeadedCard } from "../Components/HeadedCard";
import { UserInfo } from "../Components/UserInfo";
import { SaveAndDelete } from "../Components/SaveAndDelete";
import { AccessControl } from "../Components/AccessControl";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import Loading from "../Components/Loading/Loading";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import { permissionAreas } from "../NavLists";
import { MarkAllOptions } from "../Components/MarkAllOptions";

export const ClientesAdminUsuariosEdit = ({ add = false }) => {
  const [validatedUserForm, setValidatedUserForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertType, setAlertType] = useState("ok");
  const [alertMessage, setAlertMessage] = useState(null);
  const [deletionAlert, setDeletionAlert] = useState(false);
  
  const axiosBearer = useAxiosBearer();
  const navigate = useNavigate();
  const companyID = useSelector((state) => state.clientToEdit.clientToEdit.id);
  
  const stateUserInfo = useSelector((state) => {
    return add ? {} : state.userToEdit.userToEdit;
  });
  const userFormRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const isActiveRef = useRef();
  const isControllerRef = useRef();
  const checkboxRef = useRef();
  const refs = {
    name: nameRef,
    email: emailRef,
    isActive: isActiveRef,
    isController: isControllerRef,
  };
  const [controllerSelect, setControllerSelect] = useState(stateUserInfo.isController ? "SIM" : "NÃO")
  
  const changeControllerState = (value) => {
    setControllerSelect(value)
  }

  const verifyChecks = () => {
    const permissionMap = new Map();
    permissionAreas.forEach((permission) => {
      permissionMap.set(permission.areaName, permission.permissionName);
    });
    const perms = [];
    if(checkboxRef.current){
      const checks = Array.from(checkboxRef.current);
      for (const e of checks) {
        if (e.checked) {
          perms.push(permissionMap.get(e.id));
        }
      }
    }

    return perms;
  };

  const confirmGoBack = () => {
    if (alertType !== "error") {
      setAlert(false);
      navigate(-1);
    } else {
      setAlert(false);
    }
  };

  const onSave = async () => {
    setLoading(true);
    if (userFormRef.current.checkValidity() === false) {
      setValidatedUserForm(false);
      setLoading(false);
      setAlert(true);
      setAlertType("error");
      setAlertMessage("Preencha os campos antes de continuar");
      return;
    } else {
      const permissions = verifyChecks();
      const userToPost = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        username: emailRef.current.value,
        password: "usuario@123",
        password2: "usuario@123",
        user_groups: [2],
        is_active: isActiveRef.current.value === "SIM" ? true : false,
        is_controller: isControllerRef.current.value === "SIM" ? true : false,
      };

      if (add) {
        axiosBearer
          .post("users/", {
            ...userToPost,
            user_perms: permissions,
          })
          .then((res) => {
            const userPostedID = res.data.id;

            axiosBearer
              .patch(`companies/${companyID}/add_users/`, {
                users: [userPostedID],
              })
              .then((res) => {
                setLoading(false);
                setAlert(true);
                setAlertType("ok");
                setAlertMessage("Usuário adicionado com sucesso!");
              })
              .catch((error) => {
                setLoading(false);
                setAlert(true);
                setAlertType("error");
                setAlertMessage("Erro ao adicionar usuário!");
              });
          })
          .catch((err) => {
            setLoading(false);
            setAlert(true);
            setAlertType("error");
            setAlertMessage(err.response.data.username[0]);
            return;
          });
      } else {
        axiosBearer
          .put(`users/${stateUserInfo.id}/`, {
            ...userToPost,
            perms: permissions,
            is_active: isActiveRef.current.value === "SIM" ? true : false,
          })
          .then((response) => {
            setLoading(false);
            setAlert(true);
            setAlertType("ok");
            setAlertMessage("Usuário editado com sucesso!");
          })
          .catch((error) => {
            setLoading(false);
            setAlert(true);
            setAlertType("error");
            setAlertMessage("Erro ao editar usuário!");
          });
      }
    }
    setValidatedUserForm(true);
  };

  const onDelete = () => {
    setDeletionAlert(false);
    setLoading(true);
    axiosBearer
      .delete(`users/${stateUserInfo.id}/`)
      .then((res) => {
        setLoading(false);
        setAlert(true);
        setAlertType("ok");
        setAlertMessage("Usuário excluído com sucesso!");
        navigate(-1);
      })
      .catch((err) => {
        setLoading(false);
        setAlert(true);
        setAlertType("error");
        setAlertMessage("Erro ao excluir usuário!");
      });
  };

  return (
    <>
      <Loading visible={loading} />
      <CustomAlert
        type={alertType}
        visible={alert}
        message={alertMessage}
        onAccept={confirmGoBack}
      />
      <CustomAlert
        type="prompt"
        visible={deletionAlert}
        message="Tem certeza que deseja excluir este usuário?"
        onAccept={onDelete}
        onRefuse={() => setDeletionAlert(false)}
      />

      <HeadedCard title="Informações cadastrais">
        <UserInfo
          formRef={userFormRef}
          contentRef={refs}
          validatedForm={validatedUserForm}
          userData={stateUserInfo}
          addMode={add}
          controllerStateFunction={(value)=>changeControllerState(value)}
        />
      </HeadedCard>
      {controllerSelect === "NÃO" ? <HeadedCard
        title="Área de Acesso"
        hasFooter
        footerContent={<MarkAllOptions />}
      >
        <AccessControl
          checkboxRef={checkboxRef}
          userPerms={stateUserInfo?.permissions}
        />
      </HeadedCard> : null}
      <SaveAndDelete
        saveCallback={onSave}
        deleteCallback={() => setDeletionAlert(true)}
        addMode={add}
      />
    </>
  );
};
