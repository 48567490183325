import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import { FeedbackToast } from "../Components/FeedbackToast";
import { PageContainer } from "../Components/PageContainer";
import { TemplateArea } from "../Components/TemplateArea";
import { UploadTemplateModal } from "../Components/UploadTemplateModal";
import useAxiosBearerFile from "../Hooks/useAxiosBearerFile";
import useDocTypes from "../Hooks/useDocTypes";

export const Templates = () => {
  const templateNames = [
    { name: "Recursos humanos", templateName: "template-RH" },
    { name: "Serviços de terceiros", templateName: "template-Terceiros" },
    { name: "Materiais utilizados em testes", templateName: "template-Materiais" },
    { name: "Despesas com viagens", templateName: "template-Viagens" },
    { name: "Despesas com treinamentos", templateName: "template-Treinamentos" },
    { name: "Ficha Técnica Modelo", templateName: "template-Projeto" },
    { name: "Timesheet", templateName: "template-Timesheet" },
    { name: "Folha de Pagamento", templateName: "template-Payroll" },
    { name: "Memória de Cálculo", templateName: "template-Memoria-Calculo" },
  ];
  const setDocTypes = useDocTypes()
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false)
  const [file, setFile] = useState(false);
  const [docID, setDocID] = useState(false);
  const [segment, setSegment] = useState(false);
  const axiosBearerFile = useAxiosBearerFile()
  const docTypes = useSelector((state) => state.docTypes.docTypes);
  useEffect(() => {
    setDocTypes()
  },[]);

  const getDocTypeID = (areaName) => {
    const docTypeObject = docTypes.find((type) => type.name === areaName);

    if (docTypeObject) return docTypeObject.id;
    return
  };

  const getFile = (uploadedFile, areaName, segment="") => {
    if (!uploadedFile) return;

    const searchID = templateNames.find((item)=>{return item.name === areaName})

    setFile(uploadedFile);
    setDocID(getDocTypeID(searchID.templateName))
    setSegment(segment);
  };

  const uploadFile = useCallback(async () => {
    const formData = new FormData();

    if (!file) return;

    formData.append("file", file);
    formData.append("document_type", docID);
    formData.append("segment", segment)

    const response = await axiosBearerFile.post(
      "documents/templates/",
      formData
    );
    if (response.status === 201) {
      setShowToast(true);
    }
    setShowModal(false);
  }, [axiosBearerFile, file, docID]);

  useEffect(() => {
    if (file) {
      uploadFile();
    }
  }, [file, uploadFile]);

  return (
    <>
      <PageContainer>
        <div className="d-flex justify-content-end">
          <Button onClick={() => {
            setShowModal(true);
          }}>Novo Template</Button>
        </div>
        {templateNames.map((template) => {
          return (
            <TemplateArea
              key={template.name}
              documentID={getDocTypeID(template.templateName)}
              areaName={template.name}
              refresh={showModal}
            />
          );
        })}
      </PageContainer>
      <UploadTemplateModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        getFile={getFile}
      />
      <FeedbackToast
        show={showToast}
        onClose={() => {
          setShowToast(false);
        }}
        title="Upload de Arquivo"
        subtitle="agora"
      >
        Sucesso! Template enviado!
      </FeedbackToast>
    </>
  );
};
