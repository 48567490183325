import React, {useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/Form";
import {useDispatch, useSelector} from "react-redux";
import {currentUserActions} from "../store/currentUser";
import {axiosBearer} from "../api/Api";

export const YearSelector = () => {
    const CurrentYear = useSelector((state) => state.currentUser.currentYear || new Date().getFullYear())
    const dispatch = useDispatch()
    const yearRef = useRef()
    const [YearsList, setYearsList] = useState([])
    const currentCompany = useSelector(
        (state) => state.currentUser.selectedCompany
    );
    const handleChange = () => {
        dispatch(currentUserActions.setCurrentYear(Number(yearRef.current.value)))
    }
    useEffect(() => {
        axiosBearer.get(`companies/${currentCompany.id}/per_project_years/`)
            .then((yearList) => {
                if (yearList.data.length !== YearsList.length && yearList.data.sort().every(
                    (value, index)=> value !== YearsList.sort()[index])) {
                    setYearsList(yearList.data)
                }
                if (!YearsList.includes(Math.max(...yearList.data))) {
                    dispatch(currentUserActions.setCurrentYear(Number(Math.max(...yearList.data))))
                }
            });
    }, [CurrentYear, YearsList, currentCompany.id, dispatch]);

    return (
        <div className="d-flex">
            <Form.Select className="form-control m-1 mt-0 mb-0" aria-label="Seleção de Ano-Calendário" ref={yearRef}
                         onChange={handleChange} value={CurrentYear}>
                {YearsList.map((year) => {
                    if (year !== null && year !== undefined) {
                        return <option key={year}>{year}</option>
                    }
                    return null
                })}
            </Form.Select>
        </div>
    );
};
