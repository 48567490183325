import { useNavigate } from "react-router-dom";
import useAxiosBearer from "./useAxiosBearer";
import { axiosBearer } from "../api/Api";
import { useDispatch } from "react-redux";
import { currentUserActions } from "../store/currentUser";

const useCheckValidation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkValidation = (dateRevision, year, client) => {
    
    // dispatch(currentUserActions.setSelectedCompany(client))

    const [day, month] = dateRevision.split('/').map(Number);
    const userDateObj = new Date(year, month - 1, day);
    const currentDate = new Date();

    if (userDateObj > currentDate) {
      console.log('vai direto');
      navigate('/cliente/Dashboard', {
        replace: true,
      })

    } else {
      console.log('tem que responder o formulario');
      navigate('/Atualizacao', {replace: true, state: { expired: true}})
    }
  }
  return checkValidation;
};

export default useCheckValidation;
