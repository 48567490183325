import { useCallback } from "react";
import useAxiosBearer from "./useAxiosBearer";

const useDownloadExcel = () => {
  const axiosBearer = useAxiosBearer();

  const downloadRequest = useCallback(async () => {
    const res = await axiosBearer.get(
      "excel/template/",
      { responseType: "blob" }
    );

    const element = document.createElement("a");
    element.href = URL.createObjectURL(res.data);
    element.download = "Template_Excel.xlsx";

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }, []);

  return downloadRequest;
};

export default useDownloadExcel;
