import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userToEdit: {},
  checkAll: false
};

const userToEditSlice = createSlice({
  name: "userToEdit",
  initialState,
  reducers: {
    setUserToEdit(state, action) {
      state.userToEdit = action.payload;
    },
    setCheckAll(state, action){
      state.checkAll = action.payload
    },
    resetData(state){
      state.userToEdit = {}
      state.checkAll = false
    }
  },
});

export const userToEditActions = userToEditSlice.actions;

export default userToEditSlice;
