import React, { useEffect, useState } from "react";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import useDownloadFile from "../Hooks/useDownloadFile";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import { useSelector } from "react-redux";

export const StatusPopover = ({
  show,
  target,
  overlayRef,
  download,
  changeStatus,
  file,
  table,
  initialStatus = "",
  forceClose,
  handleLoading,
}) => {
  const [status, setStatus] = useState(initialStatus);
  const axiosBearer = useAxiosBearer();
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMesage] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const role = useSelector((state) => {
    return state.currentUser.role;
  });
  const downloadFile = useDownloadFile();

  const storyTableStatus = [
    {
      id: 1,
      bg: "danger",
      text: "Revisado",
      status: "revisado",
    },
  ];

  const timesheetStatus = [
    {
      id: 1,
      bg: "success",
      text: "Aprovado",
      status: "aprovado",
    },
    {
      id: 2,
      bg: "danger",
      text: "Reprovado",
      status: "reprovado",
    },
  ];

  const evidenceStatus = [
    {
      id: 1,
      bg: "danger",
      text: "Reprovado",
      status: "reprovado",
    },
    {
      id: 2,
      bg: "success",
      text: "Aprovado",
      status: "aprovado",
    },
  ];

  const generateXlsx = (type) => {
    handleLoading(true);
    let data = {};
    data.type = type;
    axiosBearer
      .post(`companies/documents/${file.id}/parser_ocr/`, data)
      .then((res) => {
        setAlert(true);
        setAlertType("ok");
        setAlertMesage(
          "Extração realizada com sucesso. Aguarde um momento que a tabela será atualizada."
        );
        handleLoading(false);
      })
      .catch((res) => {
        setAlert(true);
        setAlertType("error");
        setAlertMesage(
          "Extração falhou. Por favor verifique o arquivo e o modelo."
        );
        handleLoading(false);
      });
  };

  useEffect(() => {
    //console.log(file.file);
    if (!show && file && status !== initialStatus && status !== "") {
      //console.log("fechando")
      axiosBearer.patch(`companies/documents/${file.id}/`, { status: status });
    }
    if (show) {
      setStatus(initialStatus);
    }
  }, [show]);

  return (
    <>
      <CustomAlert
        visible={alert}
        type={alertType}
        message={alertMessage}
        onAccept={() => {
          setAlert(false);
        }}
      />
      <Overlay
        show={show}
        target={target}
        placement="left"
        container={overlayRef}
        containerPadding={20}
        rootClose={true}
        rootCloseEvent={"click"}
      >
        <Popover id="popover-contained">
          <Popover.Body className="d-flex flex-column gap-2">
            {changeStatus && table === "timesheet"
              ? timesheetStatus.map((item) => {
                  return (
                    <Badge
                      key={item.id}
                      bg={item.bg}
                      style={{ cursor: "pointer" }}
                      className={
                        status === item.status ? "selected-status" : ""
                      }
                      onClick={() => {
                        setStatus(item.status);
                      }}
                    >
                      {item.text}
                    </Badge>
                  );
                })
              : null}
            {changeStatus && table === "storyTable"
              ? storyTableStatus.map((item) => {
                  return (
                    <Badge
                      key={item.id}
                      bg={item.bg}
                      style={{ cursor: "pointer" }}
                      className={
                        status === item.status ? "selected-status" : ""
                      }
                      onClick={() => {
                        setStatus(item.status);
                      }}
                    >
                      {item.text}
                    </Badge>
                  );
                })
              : null}
            {changeStatus && table === "evidence"
              ? evidenceStatus.map((item) => {
                  return (
                    <Badge
                      key={item.id}
                      bg={item.bg}
                      style={{ cursor: "pointer" }}
                      className={
                        status === item.status ? "selected-status" : ""
                      }
                      onClick={() => {
                        setStatus(item.status);
                      }}
                    >
                      {item.text}
                    </Badge>
                  );
                })
              : null}
            {file?.file?.match(/.+(.pdf)/i) &&
              download &&
              role === "consultant" && (
                <>
                  <Button
                    className={changeStatus ? "mt-0" : ""}
                    variant="outline-secondary"
                    onClick={() => {
                      generateXlsx('k300');
                      forceClose();
                    }}
                  >
                    Gerar K300
                  </Button>
                  <Button
                    className={changeStatus ? "mt-0" : ""}
                    variant="outline-secondary"
                    onClick={() => {
                      generateXlsx('k50');
                      forceClose();
                    }}
                  >
                    Gerar K050
                  </Button>
                </>
              )}
            {download && (
              <>
                <Button
                  className={changeStatus ? "mt-0" : ""}
                  variant="outline-secondary"
                  onClick={() => {
                    downloadFile(file);
                    forceClose();
                  }}
                >
                  Download
                </Button>
              </>
            )}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};
