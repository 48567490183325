import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { segmentSelect } from "../NavLists";

const benefitsArray = ["Lei da Informática", "Lei do Bem", "Rota 2030", "Mover"];

export const ProjectInfo = ({
  formRef,
  validated,
  contentRefs,
  fieldData,
  getMultiAnnual,
}) => {
  const [microName, setMicroName] = useState(fieldData?.microproject || "");
  const [number, setNumber] = useState(fieldData?.number || "");

  return (
    <Form className="p-3" noValidate validated={validated} ref={formRef}>
      <Stack gap={3}>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>
              Número<sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Número do projeto"
              value={number}
              ref={contentRefs.num}
              isInvalid={validated ? number.length === 0 : null}
              isValid={validated ? number.length > 0 : null}
              onChange={(e) => {
                setNumber(e.target.value);
              }}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              Insira um número para o projeto
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Plurianual</Form.Label>
            <Form.Select
              aria-label="Default select example"
              defaultValue={fieldData?.multiannual ? "SIM" : "NÃO"}
              ref={contentRefs.multiannual}
              onChange={(e) => {
                getMultiAnnual(e.target.value);
              }}
            >
              <option>SIM</option>
              {fieldData?.multiannual ? null : <option>NÃO</option>}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Macroprojeto</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome do Macroprojeto"
              defaultValue={fieldData?.macroproject}
              ref={contentRefs.macro}
            />
            <Form.Control.Feedback type="invalid">
              Insira um nome para o macroprojeto
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>
              Microprojeto<sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Nome do Microprojeto"
              maxLength="45"
              value={microName}
              ref={contentRefs.micro}
              isInvalid={validated ? microName.length === 0 : null}
              isValid={validated ? microName.length > 0 : null}
              onChange={(e) => setMicroName(e.target.value)}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              Insira um nome para o microprojeto.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>
              Líder<sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              required
              placeholder="Líder do projeto"
              defaultValue={fieldData?.leader}
              ref={contentRefs.leader}
            />
            <Form.Control.Feedback type="invalid">
              Insira o nome do líder do projeto
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6">
            <Form.Label>Benefício</Form.Label>
            <Form.Select
              aria-label="Default select example"
              defaultValue={
                fieldData?.benefit
                  ? benefitsArray[fieldData.benefit - 1]
                  : "Lei da Informática"
              }
              ref={contentRefs.benefit}
            >
              <option>Lei da Informática</option>
              <option>Lei do Bem</option>
              <option>Rota 2030</option>
              <option>Mover</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} md="6">
            <Form.Label>Segmento</Form.Label>
            <Form.Select
              aria-label="Default select example"
              defaultValue={fieldData?.segment}
              ref={contentRefs.segment}
            >
              {segmentSelect.map((item)=>{
                return <option>{item}</option>
              })}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              Insira o nome do segmento do projeto
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <h6 className="text-danger">* campo obrigatório</h6>
        </Row>
      </Stack>
    </Form>
  );
};
