import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { consultorMainShade, clientMainShade, moeda } from "../utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export const ChartDispendio = ({ mode, graphData }) => {
    const options = {
        indexAxis: 'y',
        responsive: true,
        plugins: {
            ChartDataLabels,
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Total de Dispêndios por Projeto",
            },
            tooltip: {
                enabled: false,
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        return this.getLabelForValue(value);
                    },
                    crossAlign: "center",
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            x: {
                display: false,
            },
        },
    };

    const formatedData = graphData?.project_expenditure.data.map((item) => {
        return {
            label: item.project_name.substr(0, 40),
            value: item.value,
        };
    });

    const total = graphData?.project_expenditure.sum

    const breakString = (string, n) => {
        let words = string.split(' ');
        let lines = [];
        let line = '';
        for (let i = 0; i < words.length; i++) {
            let word = words[i];
            if (line.length + word.length + 1 <= n) {
                line += word + ' ';
            } else {
                lines.push(line.trim());
                line = word + ' ';
            }
        }
        lines.push(line.trim());
        return lines;
    }

    const labels = formatedData?.map((item) => {
        return breakString(item.label, 20);
    });

    const data = {
        labels,
        datasets: [
            {
                data: formatedData?.map((item) => {
                    return item.value;
                }),
                backgroundColor: mode === "consultant" ? consultorMainShade : clientMainShade,
                datalabels: {
                    color: "#fff",
                    anchor: "start",
                    align: "end",
                    formatter: function (value) {
                        return moeda.format(value)
                    }
                },
            },
        ],
    };

    return total !== 0 ? <div style={{ position: "relative" }}>
        <div
            className="d-flex justify-content-center m-auto"
            style={{ width: "85%", position: "relative" }}
        ></div>
        <div
            className="text-white px-2 py-1"
            style={{
                position: "absolute",
                top: "0",
                right: "0",
                backgroundColor:
                    mode === "consultant" ? consultorMainShade : clientMainShade,
            }}
        >
            {moeda.format(total)}
        </div>
        <Bar options={options} data={data} />
    </div> : null
};
