import React, { useEffect, useState } from "react";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { TemplateCard } from "./TemplateCard";

export const TemplateArea = ({ areaName, documentID, refresh }) => {
  const axiosBearer = useAxiosBearer();
  const [templates, setTemplates] = useState([]);
  const [deletedFlag, setDeletedFlag] = useState(false);

  useEffect(() => {
    const request = async () => {
      const response = await axiosBearer.get("documents/templates/", {
        params: { document_type: documentID },
      });

      setTemplates(response.data);
    };
    if (!refresh || deletedFlag) {
      request();
      setDeletedFlag(false);
    }
  }, [refresh, deletedFlag]);

  return (
    <section>
      <div style={{ fontSize: "1.25rem" }}>{areaName}</div>
      <div
        style={{ height: "2px", width: "100%", backgroundColor: "#ddd" }}
        className="mb-4"
      ></div>
      <div className="d-flex flex-column gap-2">
        {templates.map((template) => {
            return (
              <TemplateCard
                key={template.id}
                templateData={template}
                deletingSignal={(state) => {
                  setDeletedFlag(state);
                }}
              />
            );
        })}
      </div>
    </section>
  );
};
