import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useFormatedDate } from "../Hooks/useFormatedDate";
import { useDispatch } from "react-redux";
import { projectOrTermToEditActions } from "../store/project-to-edit-slice";

export const TermTable = ({ termsArray = [], viewButtonCallback }) => {
  const renderDate = useFormatedDate();
  const dispatch = useDispatch()

  return (
    <Table className="align-middle" responsive>
      <thead>
        <tr>
          <th className="text-center">Título</th>
          <th className="text-center">Data da criação</th>
          <th className="text-center">Versão</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {termsArray.map((term) => {
          return (
            <tr key={term.id}>
              <td className="text-center">{term?.title}</td>
              <td className="text-center">{renderDate(term?.created)}</td>
              <td className="text-center">{term?.version}</td>
              <td className="text-center">
                <Button onClick={()=>{
                  dispatch(projectOrTermToEditActions.setProjectOrTermToEdit(term))
                  viewButtonCallback()
                }}>Visualizar</Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
