import axios from "axios";

//generalização da rota
const BASE_URL = window.location.origin.replace(".", "-api.").replace("3000", "8000") + "/api/v1/"

//rota de desenvolvimento
// const BASE_URL = "https://martinelli-ped-api.youtan.com.br/api/v1/";
// const BASE_URL = "http://192.168.15.139:8000/api/v1/";

export default axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
});

export const axiosBearer = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
});

export const axiosBearerFile = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "multipart/form-data" },
});
