import React from "react";
import Form from 'react-bootstrap/Form';
import { useDispatch } from "react-redux";
import { userToEditActions } from "../store/user-to-edit-slice";

export const MarkAllOptions = () => {
	const dispatch = useDispatch()

	const handleChange = (e) => {
		dispatch(userToEditActions.setCheckAll(e.target.checked))
	}

  return (
    <Form>
      <Form.Check
				onChange={(e)=>{handleChange(e)}}
        type="checkbox"
        id="check"
        label="Preencher todos os campos"
        defaultChecked={false}
      />
    </Form>
  );
};
