import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { AppNavbar } from "../Components/AppNavbar";
import { ClientBanner } from "../Components/ClientBanner";
import "bootstrap/dist/css/bootstrap.min.css";
import "../custom.scss";
import { PageContainer } from "../Components/PageContainer";
import useBreakpoint from "../Hooks/useBreakpoint";

export const MainView = ({ type, banner = false }) => {
  const colorMap = {
    admin: "#74c15d",
    client: "#406d93",
    consultant: "#525252",
  };

  const breakpoint = useBreakpoint();

  return (
    <Container fluid>
      <Row className="min-vh-100">
        <Col
          md={3}
          xxl={2}
          style={{ backgroundColor: colorMap[type] }}
        >
          <AppNavbar type={type} />
        </Col>
        <Col
          md={9}
          xxl={10}
          className="bg-light min-vh-100 p-0"
        >
          <PageContainer>
            {banner && <ClientBanner type={type} />}
            <Outlet />
          </PageContainer>
        </Col>
      </Row>
    </Container>
  );
};
