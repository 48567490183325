import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { Icon } from "./Icon";
import { IMaskInput } from "react-imask";

export const AddYear = ({ initialYears, sendYears, isMultiAnnual }) => {
  const [years, setYears] = useState(initialYears);
  const [validYear, setValidYear] = useState(null);
  const [yearValue, setYearValue] = useState("")

  const handleAddYear = () => {
    const alreadyExists = years.includes(yearValue);
    const isANumber = Number(yearValue);
    const isInRange = 1900 < isANumber && isANumber < 2100;
    const isInvalid = alreadyExists || !isANumber || !isInRange;

    //console.log(isMultiAnnual)

    if (isInvalid) {
      setValidYear(false);
    } else {
      const newYearsArray = [...years, Number(yearValue)];
      const sortedYears = newYearsArray.sort();
      setYears(sortedYears);
      sendYears(sortedYears);
    }

    setYearValue("")
  };

  const removeYear = (value) => {
    const newArray = years.filter((year) => {
      return year !== value;
    });
    setYears(newArray);
    sendYears(newArray);
  };

  return (
    <Form className="p-3" onSubmit={(e)=>{e.preventDefault()}}>
      {years.length > 0 ? (
        <ListGroup className="mb-4">
          {years.map((year, index) => {
            return (
              <ListGroup.Item
                key={index}
                className="d-flex align-items-center justify-content-between"
              >
                {year}
                <Icon
                  icon={"Trash"}
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={() => {
                    removeYear(year);
                  }}
                />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : (
        <h6 className="text-center mb-4">Insira um ano</h6>
      )}
      <InputGroup className="mb-3">
        <Form.Control
          as={IMaskInput}
          value={yearValue}
          onChange={(e)=>{setYearValue(e.target.value)}}
          mask="0000"
          placeholder="Adicionar novo ano"
          aria-label="Adicionar novo Ano-Calendário ao projeto"
          aria-describedby="basic-addon2"
          isInvalid={validYear === null ? null : validYear}
        />
        <Button id="button-addon2" disabled={isMultiAnnual === "NÃO" && years.length === 1} onClick={handleAddYear}>
          Adicionar
        </Button>
      </InputGroup>
    </Form>
  );
};
