import React, { useCallback, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { UploadManager } from "./UploadManager";
import { StoryTableRow } from "./StoryTableRow";
import { useSelector } from "react-redux";

export const StoryTable = ({ filesArray = [], refresh }) => {
  const userRole = useSelector((state)=>state.currentUser.role)
  const [triggerModal, setTriggerModal] = useState(false);
  const handleModal = useCallback(() => {
    setTriggerModal(false);
    refresh();
  }, [refresh]);

  return (
    <>
      <Table className="align-middle" responsive>
        <thead>
          <tr>
            <th className="text-center">Descrição</th>
            <th className="text-center">Autor</th>
            <th className="text-center">Data do upload</th>
            <th className="text-center">Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filesArray.length < 6 && filesArray.length % 2 === 0 && (
            <tr className={userRole === "client" ? "custom-table-bg" : ""}>
              <td className="text-center">{`Versão ${Math.floor(filesArray.length / 2) + 1}`}</td>
              <td className="text-center">Cliente</td>
              <td className="text-center">-</td>
              <td className="text-center">
                <Badge bg={`${userRole === "client" ? "warning" : "secondary"}`} text={`${userRole === "client" ? "dark" : "light"}`}>
                  Aguardando Upload
                </Badge>
              </td>
              <td>
                {userRole === "client" && <Button className="w-75"
                  onClick={() => {
                    setTriggerModal(true);
                  }}
                >
                  Upload
                </Button>}
              </td>
            </tr>
          )}
          {filesArray.length < 6 && filesArray.length % 2 === 1 && filesArray[0].status !== "aguardandoAnalise" && (
            <tr className={userRole === "consultant" ? "custom-table-bg" : ""}>
              <td className="text-center">{`Revisão ${Math.floor(filesArray.length / 2) + 1}`}</td>
              <td className="text-center">Martinelli</td>
              <td className="text-center">-</td>
              <td className="text-center">
                <Badge bg={`${userRole === "consultant" ? "warning" : "secondary"}`} text={`${userRole === "consultant" ? "dark" : "light"}`}>
                  Aguardando Upload
                </Badge>
              </td>
              <td>
                {userRole === "consultant" && <Button
                  className="w-75"
                  onClick={() => {
                    setTriggerModal(true);
                  }}
                >
                  Upload
                </Button>}
              </td>
            </tr>
          )}
          {filesArray.length > 0 &&
            filesArray.map((file, index) => {
              return <StoryTableRow key={index} file={file} refreshTable={refresh} index={index}/>
            })}
        </tbody>
      </Table>
      <UploadManager
        documentID={7}
        triggerModal={triggerModal}
        handleModal={handleModal}
        currentTableSize={filesArray.length}
        mode={"story"}
      />
    </>
  );
};
