export const useFormatedDate = () => {
  const renderDate = (dateString) => {
    let day, month;
    const date = new Date(dateString)

    if(!dateString) return ""
    
    date.getUTCDate() < 10
      ? day = `0${date.getUTCDate()}`
      : day = date.getUTCDate();

    date.getUTCMonth() + 1 < 10
      ? month = `0${date.getUTCMonth() + 1}`
      : month = date.getUTCMonth();

    return `${day}/${month}/${date.getUTCFullYear()}`;
  };

  return renderDate;
};
