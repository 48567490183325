import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { ClientTable } from "../Components/ClientTable";
import { HeadedCard } from "../Components/HeadedCard";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { AddGroupPopover } from "../Components/AddGroupPopover";
import { useDispatch } from "react-redux";
import { clientToEditActions } from "../store/client-to-edit-slice";
import Loading from "../Components/Loading/Loading";
import { PaginationComponent } from "../Components/PaginationComponent";
import useDocTypes from "../Hooks/useDocTypes";
import { SearchBar } from "../Components/SearchBar";
import useBackPagination from "../Hooks/useBackPagination";

const COMPANIES_PER_PAGE = 5;

export const ClientesAdminList = () => {
  const setDocTypes = useDocTypes();
  const axiosBearer = useAxiosBearer();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groups, setGroups] = useState([]);
  const [ocrs, setOcrs] = useState([]);

  const [companyToSearch, setCompanyToSearch] = useState("");
  const [forcePaginationPageChange, setForcePaginationPageChange] =
    useState(false);
  const [itemsToShow, setCurrentPage, loading, amountOfItems] = useBackPagination({
    path: "companies/",
    objectFields: ["id", "name", "cnpj", "uf", "group", "principal"],
    initialPage: 1,
    searchedName: companyToSearch,
    amountPerPage: COMPANIES_PER_PAGE,
    reqParams: null
  }, "ClientesAdminList");

  //overlay variables
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const overlayRef = useRef();

  const handleOverlay = (e) => {
    setShow(!show);
    setTarget(e.target);
  };

  const getCompanyToSearch = (value) => {
    setCompanyToSearch(value);
    setCurrentPage(1);
    setForcePaginationPageChange((prev) => !prev);
  };

  const goToEditClient = () => {
    dispatch(clientToEditActions.setAllGroups(groups));
    dispatch(clientToEditActions.setAllOcrs(ocrs));
    navigate("edit");
  };
  const goToAddClient = () => {
    dispatch(clientToEditActions.setAllGroups(groups));
    dispatch(clientToEditActions.setAllOcrs(ocrs));
    navigate("add");
  };
  const goToClientProjects = () => {
    navigate("Projetos");
  };
  const goToClientUsers = () => {
    navigate("Usuarios");
  };

  useEffect(() => {
    const get_group = async () => {
      const response = await axiosBearer.get("companygroups/");
      const groupOptions = response.data.map((companyGroup) => {
        return {
          id: companyGroup.id,
          name: companyGroup.name,
        };
      });
      setGroups(groupOptions);
    };
    get_group();
    const get_ocr = async () => {
      const response = await axiosBearer.get("ocr/");
      const ocrList = response.data.map((ocr) => {
        return {
          id: ocr.id,
          name: ocr.name,
        };
      });
      setOcrs(ocrList);
    };
    get_ocr();
    setDocTypes();
  }, [axiosBearer, show]);

  return (
    <>
      <Loading visible={loading} />
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-start" ref={overlayRef}>
          <Button className="px-3" onClick={handleOverlay}>
            Criar Grupo
          </Button>
          <AddGroupPopover
            show={show}
            target={target}
            overlayRef={overlayRef}
            sendData={() => {
              setShow(false);
            }}
          />
        </div>
        <Button className="px-3" onClick={goToAddClient}>
          Adicionar
        </Button>
      </div>
      <div>
        <SearchBar
          getInput={(v) => getCompanyToSearch(v)}
          placeholder="Pesquisar empresa"
        />
        <HeadedCard title="Empresas">
          <ClientTable
            type="edit"
            editCallback={goToEditClient}
            projectCallback={goToClientProjects}
            userCallback={goToClientUsers}
            companies={itemsToShow}
          />
        </HeadedCard>
      </div>
      {amountOfItems > COMPANIES_PER_PAGE && (
        <PaginationComponent
          size={amountOfItems}
          itemsPerPage={COMPANIES_PER_PAGE}
          pageChanger={(page) => {
            setCurrentPage(page);
          }}
          forcePageChangeFromOutside={forcePaginationPageChange}
        />
      )}
    </>
  );
};
