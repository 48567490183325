import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import useLogout from "../Hooks/useLogout";

export const LogoutPopover = ({ children }) => {
  const logout = useLogout();

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement={"top"}
        overlay={
          <Popover id={"popover-positioned-top"}>
            <Popover.Body>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </Button>
            </Popover.Body>
          </Popover>
        }
      >
        {children}
      </OverlayTrigger>
    </>
  );
};
