import React, { useCallback, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { useSelector } from "react-redux";
import { StatusPopover } from "./StatusPopover";
import { Icon } from "./Icon";
import { useFormatedDate } from "../Hooks/useFormatedDate";
import Loading from "./Loading/Loading";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import CustomAlert from "./CustomAlert/CustomAlert";

export const FileTableRow = ({ file, refreshTable }) => {
  const userRole = useSelector((state) => state.currentUser.role);
  const dateUploaded = new Date(file.upload_date);
  const renderDate = useFormatedDate();
  const axiosBearer = useAxiosBearer();

  const statusTypes = {
    aguardandoAnalise: 0,
    aprovado: 1,
    reprovado: 2,
  };

  const statusInfo = [
    { text: "Aguardando análise", color: "secondary" },
    { text: "Aprovado", color: "success" },
    { text: "Reprovado", color: "danger" },
  ];

  const [showLoading, setShowLoading] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false)

  const handleLoading = (value) => {
    setShowLoading(value);
  };

  //overlay variables
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const overlayRef = useRef();

  const forceClose = () => {
    setShow(false);
  };

  const handleOverlay = (e) => {
    setShow((show) => {
      return !show;
    });

    //console.log(`handleOverlay - ${show} - ${file.id}`);
    //document.removeEventListener("click", handleClickOutside, true)

    if (!show) {
      document.addEventListener("click", handleClickOutside, true);
      document.fileRowID = file.id;
    }

    if (show && userRole === "consultant") {
      refreshTable();
    }
    setTarget(e.target);
  };

  const handleClickOutside = useCallback((e) => {
    if (
      !overlayRef.current.contains(e.target) &&
      document.fileRowID === file.id
    ) {
      //console.log(`executing click outside ${file.id}`);
      setShow(false);
      refreshTable();
      document.removeEventListener("click", handleClickOutside, true);
    }
  }, []);

  const deleteFile = () => {
    axiosBearer.delete(`companies/documents/${file.id}/`).then(()=>{
      refreshTable();
    })
  };

  return (
    <>
      {createPortal(
        <Loading visible={showLoading} text={"Extração em andamento"} />,
        document.body
      )}
      {createPortal(
        <CustomAlert
          visible={deleteAlert}
          type="prompt"
          message="Deseja realmente excluir este arquivo?"
          onAccept={()=>{
            deleteFile()
            setDeleteAlert(false)
          }}
          onRefuse={() => setDeleteAlert(false)}
        />,
        document.body
      )}
      <tr>
        <td>{file?.description}</td>
        <td className="text-center">{renderDate(dateUploaded)}</td>
        <td className="text-center">
          <Badge bg={statusInfo[statusTypes[file?.status]]?.color}>
            {statusInfo[statusTypes[file?.status]]?.text}
          </Badge>
        </td>
        <td ref={overlayRef}>
          <div className="d-flex gap-2">
            <Button variant="outline-secondary" onClick={handleOverlay}>
              ...
            </Button>
            <StatusPopover
              show={show}
              target={target}
              overlayRef={overlayRef}
              table={"evidence"}
              download
              changeStatus={userRole === "consultant"}
              initialStatus={file.status}
              file={file}
              forceClose={forceClose}
              handleLoading={handleLoading}
            />
            {userRole === "client" && (
              <Button
                variant="danger"
                onClick={() => {
                  setDeleteAlert(true)
                }}
              >
                <Icon icon={"Trash"} />
              </Button>
            )}
          </div>
        </td>
      </tr>
    </>
  );
};
