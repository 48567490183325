import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  excelSent: false
};

const refreshDashboardSlice = createSlice({
  name: "refreshDashboard",
  initialState,
  reducers: {
    setFlag(state, action) {
      state.excelSent = action.payload;
    },
  },
});

export const refreshDashboardActions = refreshDashboardSlice.actions;

export default refreshDashboardSlice;
