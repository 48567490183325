import React from "react";
import { HeadedCard } from "../Components/HeadedCard";
import { InteractionsDivider } from "../Components/InteractionsDivider";
import { PageHeader } from "../Components/PageHeader";
import { DownloadTable } from "../Components/DownloadTable";
import useRefresh from "../Hooks/useRefresh";
import { useGetDescription } from "../Hooks/useGetDescription";

const PAGE_TITLE = "Dossiê"

export const Dossie = ({ mode = "client", DOC_ID }) => {
  const [files, handleRefresh] = useRefresh(DOC_ID);
  const description = useGetDescription(PAGE_TITLE)

  return (
    <>
      <PageHeader title={PAGE_TITLE}>
        {description}
      </PageHeader>
      {mode === "consultant" ? (
        <InteractionsDivider
          upload
          tableDocumentID={DOC_ID}
          refresh={handleRefresh}
        />
      ) : null}
      <HeadedCard title="Arquivos para download">
        <DownloadTable type={1} filesArray={files} refreshTable={handleRefresh}/>
      </HeadedCard>
    </>
  );
};
