import { useCallback } from "react";
import useAxiosBearer from "./useAxiosBearer";

const useDownloadTemplate = () => {
  const axiosBearer = useAxiosBearer();

  const downloadRequest = useCallback(async (fileParam) => {
    const res = await axiosBearer.get(
      `documents/templates/${fileParam.id}/download/`,
      { responseType: "blob" }
    );

    const element = document.createElement("a");
    element.href = URL.createObjectURL(res.data);
    element.download = fileParam.file;

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }, []);

  return downloadRequest;
};

export default useDownloadTemplate;
