import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { permissionAreas } from "../NavLists";
import { useSelector } from "react-redux";

export const AccessControl = ({ checkboxRef, userPerms }) => {
  const markEveryOption = useSelector((state)=>state.userToEdit.checkAll)

  const checkEveryOption = () => {
    if(!markEveryOption)
      return

    for(let checkbox of checkboxRef.current){
      checkbox.checked = true
    }
  }
  
  useEffect(()=>{
    checkEveryOption()
  },[markEveryOption])

  return (
    <section className="p-3">
      <Form ref={checkboxRef}>
        <Row>
          {permissionAreas.map((area, index) => {
            let isChecked;
            if (userPerms) {
              const searchResult = userPerms.find((perm) => {
                return perm === area.permissionName;
              });
              isChecked = Boolean(searchResult);
            } else {
              isChecked = false;
            }

            return (
              <Col key={index} sm={6} md={4} className="py-2">
                <Form.Check
                  type="checkbox"
                  id={area.areaName}
                  label={area.areaName}
                  defaultChecked={isChecked}
                />
              </Col>
            );
          })}
        </Row>
      </Form>
    </section>
  );
};
