import { useCallback } from "react";
import useAxiosBearer from "./useAxiosBearer";

const useDownloadIdentifier = () => {
  const axiosBearer = useAxiosBearer();

  const downloadRequest = useCallback(async (company_id, company_name) => {
    const res = await axiosBearer.get(
      `update-form/export_form/${company_id}/`,
      { responseType: "blob" }
    );

    const element = document.createElement("a");
    element.href = URL.createObjectURL(res.data);
    element.download = `Identificação_${company_name}.xlsx`;

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }, []);

  return downloadRequest;
};

export default useDownloadIdentifier;
