import React from "react";
import Stack from "react-bootstrap/Stack";

export const PageContainer = ({ children }) => {
  return (
    <div className="d-flex justify-content-center">
      <Stack
        gap={5}
        className="p-3 p-sm-4 p-lg-5"
        style={{ width: "100%", maxWidth: "1550px" }}
      >
        {children}
      </Stack>
    </div>
  );
};
