import React from "react";
import {
  AcademicCapIcon,
  BookOpenIcon,
  BuildingOfficeIcon,
  CalculatorIcon,
  CheckIcon,
  ChevronDownIcon,
  CurrencyDollarIcon,
  DocumentChartBarIcon,
  DocumentCheckIcon,
  DocumentTextIcon,
  FolderIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  PencilSquareIcon,
  ScaleIcon,
  SquaresPlusIcon,
  SwatchIcon,
  TableCellsIcon,
  TrashIcon,
  TruckIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon,
  VariableIcon,
  XMarkIcon
} from "@heroicons/react/20/solid";
// Add here all the icons

export const Icon = ({icon, className = "", style = {}, onClick}) => {
    const iconMap = {
        AcademicCap: AcademicCapIcon, //Treinamentos
        BookOpen: BookOpenIcon, //Docs. Complementares
        BuildingOffice: BuildingOfficeIcon, //User Header
        Calculator: CalculatorIcon, //Docs. Contábeis
        Check: CheckIcon, //Dashboard when step is completed
        ChevronDown: ChevronDownIcon,
        CurrencyDollar: CurrencyDollarIcon, //Folha de Pagamento
        DocumentChartBar: DocumentChartBarIcon, //Dossiê, Hist. Parecer Técnico, Hist. Relatório Auditoria
        DocumentCheck: DocumentCheckIcon, //Termos
        DocumentText: DocumentTextIcon,
        Folder: FolderIcon, //Projetos e Evidências
        MagnifyingGlass: MagnifyingGlassIcon,
        MapPin: MapPinIcon, //Viagens
        PencilSquare: PencilSquareIcon, //Project Edit page
        Scale: ScaleIcon, // Obrigações Acessórias
        SquaresPlus: SquaresPlusIcon, //Dashboard
        Swatch: SwatchIcon, //Materiais
        TableCells: TableCellsIcon, //Hist Forms
        Trash: TrashIcon, //Deleting Year
        Truck: TruckIcon, //Terceiros
        UserCircle: UserCircleIcon, //Consultores
        UserGroup: UserGroupIcon, //Clientes
        User: UserIcon, //RH
        Variable: VariableIcon, //Memória de Cálculo
        XMark: XMarkIcon
    };

    const IconComponent = iconMap[icon];

    return (
        <IconComponent
            className={`icon ${className}`}
            style={style}
            onClick={onClick}
        />
    );
};
