import React from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

export const SaveAndDelete = ({
  saveCallback,
  deleteCallback,
  addMode = false,
}) => {
  return (
    <section>
      <Stack direction="horizontal">
        {!addMode && (
          <Button
            variant="outline-danger"
            onClick={deleteCallback}
            className="px-3"
          >
            Excluir
          </Button>
        )}
        <Button onClick={saveCallback} className="px-3 ms-auto">
          Salvar
        </Button>
      </Stack>
    </section>
  );
};
