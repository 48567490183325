import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie } from "react-chartjs-2";
import { clientMainShade, consultorMainShade, formatDivision, moeda, pieShades } from "../utils";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export const ChartRelevanciaDispendio = ({ mode, graphData }) => {
  const options = {
    plugins: {
      ChartDataLabels,
      /* legend: {
        display: false,
        //position: 'top',
      }, */
      title: {
        display: true,
        text: "Relevância dos Dispêndios no Período",
        align: "start",
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const formatedData = graphData?.expenditure_relevance[0]?.data;
  const total = graphData?.expenditure_relevance[0]?.sum

  let labels = [];
  let values = [];

  const textMap = {
    RH: "RH",
    ThirdParties: "Terceiros",
    Materials: "Materiais",
    Training: "Treinamentos",
    Travels: "Viagens",
    Others: "outros",
  };

  for (let key in formatedData) {
    if (formatedData[key] !== 0) {
      labels.push(textMap[key]);
      values.push(formatedData[key]);
    }
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: values,
        backgroundColor: pieShades,
        datalabels: {
          color: "#fff",
          anchor: "end",
          align: "start",
          formatter: function (value) {
            return formatDivision(value, total)
          }
        },
      },
    ],
  };

  return graphData ? (labels.length === 0 ? null : (
    <div
      className="d-flex justify-content-left m-auto"
      style={{ width: "85%", position: "relative" }}
    >
      <div
        className="text-white px-2 py-1"
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          backgroundColor:
            mode === "consultant" ? consultorMainShade : clientMainShade,
        }}
      >
        {moeda.format(total)}
      </div>
      <Pie options={options} data={data} />
    </div>
  )) : null;
};
