import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Icon } from "./Icon";
import { useFormatedDate } from "../Hooks/useFormatedDate";
import { useSelector } from "react-redux";
import { createPortal } from "react-dom";
import CustomAlert from "./CustomAlert/CustomAlert";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import useDownloadFile from "../Hooks/useDownloadFile";
import MemoriaCalculoTable from "./MemoriaCalculoTable";


export const DownloadTableRow = ({ file, type, refreshTable, isSelected, handleSelectedRow, selectedTrue=false }) => {
  const renderDate = useFormatedDate();
  const dateUploaded = new Date(file.upload_date);
  const format = file.description.slice(file.description.lastIndexOf(".") + 1);
  const name = file.description.slice(0, file.description.lastIndexOf("."));
  const userRole = useSelector((state) => state.currentUser.role);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const axiosBearer = useAxiosBearer();
  const downloadFile = useDownloadFile()
  const [calcMemoryData, setCalcMemoryData] = useState({});
  const [calcMemoryError, setCalcMemoryError] = useState(null);

  const deleteFile = () => {
    axiosBearer.delete(`companies/documents/${file.id}/`).then(() => {
      refreshTable();
    });
  };

  const selectCalcMemory = () => {
    axiosBearer.post(`companies/documents/${file.id}/calc_memory_parser/`).then(response => {
      setCalcMemoryData(response.data);
    })
    .catch(error => {
      setCalcMemoryError("Erro ao carregar os detalhes de cálculo. Verifique o preenchimento da planilha.");
    });
  }

  return (
    <>
      {createPortal(
        <CustomAlert
          visible={deleteAlert}
          type="prompt"
          message="Deseja realmente excluir este arquivo?"
          onAccept={()=>{
            deleteFile()
            setDeleteAlert(false)
          }}
          onRefuse={() => setDeleteAlert(false)}
        />,
        document.body
      )}
      <tr>
        <td className="text-center">{file.year}</td>
        {type !== 1 && <td className="text-center">{name}</td>}
        {type !== 3 && <td className="text-center">{format}</td>}
        <td className="text-center">{renderDate(dateUploaded)}</td>
        <td>
          <Button
            variant="outline-secondary"
            onClick={()=>{downloadFile(file)}}
          >
            Download
          </Button>
        </td>
        <td>
          {selectedTrue ?
          <Button
            variant="outline-secondary"
            onClick={()=>{
              handleSelectedRow(file.id)
              selectCalcMemory()
            }}
          >
            Selecionar
          </Button>
          : null }
        </td>
        {userRole === "consultant" && (
          <td>
            <Button
              variant="danger"
              onClick={() => {
                setDeleteAlert(true);
              }}
            >
              <Icon icon={"Trash"} />
            </Button>
          </td>
        )}
      </tr>
      {calcMemoryError && ( 
        <tr>
          <td colSpan={6}>
            <p className="text-danger text-center">{calcMemoryError}</p>
          </td>
        </tr>
      )}
      {isSelected && !calcMemoryError && (
        <tr key={`expanded_${file.id}`}>
          <td colSpan="6">
            <MemoriaCalculoTable calcMemory={calcMemoryData} year={file.year} />
          </td>
        </tr>
      )}
    </>
  );
};
