import { useState } from "react"

const usePagination = (activePage, dataArray = [], amountPerPage) => {
	const [currentPage, setCurrentPage] = useState(activePage)
  const [dataPerPage] = useState(amountPerPage)

	const lastItemIndex = currentPage * dataPerPage
  const firstItemIndex = lastItemIndex - dataPerPage
  const itemsToShow = dataArray.slice(firstItemIndex, lastItemIndex)

	return [itemsToShow, setCurrentPage]
}

export default usePagination