import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";
import Layout from "./Layout";
import { Login } from "./Login";
import { MainView } from "./Pages/MainView";
import { Dashboard } from "./Pages/Dashboard";
import { ClientesAdmin } from "./Pages/ClientesAdmin";
import { Templates } from "./Pages/Templates";
import { Consultores } from "./Pages/Consultores";
import { HistRelatorioAuditoria } from "./Pages/HistRelatorioAuditoria";
import { HistParecerTecnico } from "./Pages/HistParecerTecnico";
import { RequireAuth } from "./Components/RequireAuth";
import { Dossie } from "./Pages/Dossie";
import { MemoriaCalculo } from "./Pages/MemoriaCalculo";
import { DocsComplementares } from "./Pages/DocsComplementares";
import { DocsContabeis } from "./Pages/DocsContabeis";
import { Treinamentos } from "./Pages/Treinamentos";
import { Viagens } from "./Pages/Viagens";
import { RH } from "./Pages/RH";
import { Terceiros } from "./Pages/Terceiros";
import { Materiais } from "./Pages/Materiais";
import { Clientes } from "./Pages/Clientes";
import { ProjetosEvidencias } from "./Pages/ProjetosEvidencias";
import { ProjetosList } from "./Pages/ProjetosList";
import { ProjetosEdit } from "./Pages/ProjetosEdit";
import { ConsultoresList } from "./Pages/ConsultoresList";
import { ConsultoresEdit } from "./Pages/ConsultoresEdit";
import { ClientesAdminProjetos } from "./Pages/ClientesAdminProjetos";
import { ClientesAdminUsuarios } from "./Pages/ClientesAdminUsuarios";
import { ClientesAdminList } from "./Pages/ClientesAdminList";
import { ClientesAdminEdit } from "./Pages/ClientesAdminEdit";
import { ClientesAdminProjetosList } from "./Pages/ClientesAdminProjetosList";
import { ClientesAdminProjetosEdit } from "./Pages/ClientesAdminProjetosEdit";
import { ClientesAdminUsuariosList } from "./Pages/ClientesAdminUsuariosList";
import { ClientesAdminUsuariosEdit } from "./Pages/ClientesAdminUsuariosEdit";
import { ADRedirect } from "./Pages/ADRedirect";
import { FolhaDePagamento } from "./Pages/FolhaDePagamento";
import { HistForms } from "./Pages/HistForms";
import { ObrigacoesAcessorias } from "./Pages/ObrigacoesAcessorias";
import { Descricoes } from "./Pages/Descricoes";
import { useSelector } from "react-redux";
import { TermsOfService } from "./Pages/TermsOfService";
import { NotFound } from "./Pages/NotFound";
import { TermsHome } from "./Pages/TermsHome";
import { TermsList } from "./Pages/TermsList";
import { TermsView } from "./Pages/TermsView";
import { IdentificacaoEmpresa } from "./Pages/IdentificacaoEmpresa";
import { Atualizacao } from "./Pages/Atualizacao";

function App() {
  const docTypes = useSelector((state) => state.docTypes.docTypes);

  const getDocTypeID = (areaName) => {
    const docTypeObject = docTypes.find((type) => type.name === areaName);

    if (docTypeObject) return docTypeObject.id;
    return;
  };

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="Termos" element={<TermsOfService />} />
        <Route path="ad" element={<ADRedirect />} />

        {/*----------------------------------------ADMIN------------------------------------------------ */}
        <Route element={<RequireAuth allowedRole="admin" />}>
          <Route path="admin" element={<MainView type="admin" />}>
            <Route path="Dashboard" element={<Dashboard />} />
            <Route path="Empresas" element={<ClientesAdmin />}>
              <Route index element={<ClientesAdminList />} />
              <Route path="edit" element={<ClientesAdminEdit />} />
              <Route path="add" element={<ClientesAdminEdit add />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="Empresas/Projetos" element={<ClientesAdminProjetos />}>
              <Route index element={<ClientesAdminProjetosList />} />
              <Route path="edit" element={<ClientesAdminProjetosEdit />} />
              <Route path="add" element={<ClientesAdminProjetosEdit add />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="Empresas/Usuarios" element={<ClientesAdminUsuarios />}>
              <Route index element={<ClientesAdminUsuariosList />} />
              <Route path="edit" element={<ClientesAdminUsuariosEdit />} />
              <Route path="add" element={<ClientesAdminUsuariosEdit add />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="Templates" element={<Templates />} />
            <Route path="Descricoes" element={<Descricoes />} />
            <Route path="Consultores" element={<Consultores />}>
              <Route index element={<ConsultoresList />} />
              <Route path="edit" element={<ConsultoresEdit />} />
              <Route path="add" element={<ConsultoresEdit add />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="Termos" element={<TermsHome />}>
              <Route index element={<TermsList />} />
              <Route path="add" element={<TermsView add />} />
              <Route path="view" element={<TermsView />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Route>

        {/*----------------------------------------CLIENTE------------------------------------------------ */}

        <Route element={<RequireAuth allowedRole="client" />}>
          <Route path="Atualizacao" element={<Atualizacao />} />
          <Route path="cliente" element={<MainView type="client" />}>
            <Route path="Empresas" element={<Clientes mode="cliente" />} />
          </Route>
          <Route path="cliente" element={<MainView type="client" banner />}>
            <Route path="Dashboard" element={<Dashboard mode="client" />} />
            <Route
              path="Identificacao-da-empresa"
              element={
                <IdentificacaoEmpresa
                  mode="client"
                />
              }
            />

            <Route
              path="Recursos-Humanos"
              element={
                <RH
                  mode="client"
                  DOC_ID={getDocTypeID("document-RH")}
                  TEMPLATE_ID={getDocTypeID("template-RH")}
                />
              }
            />
            <Route
              path="Servicos-de-Terceiros"
              element={
                <Terceiros
                  mode="client"
                  DOC_ID={getDocTypeID("document-Terceiros")}
                  TEMPLATE_ID={getDocTypeID("template-Terceiros")}
                />
              }
            />
            <Route
              path="Materiais-para-testes"
              element={
                <Materiais
                  mode="client"
                  DOC_ID={getDocTypeID("document-Materiais")}
                  TEMPLATE_ID={getDocTypeID("template-Materiais")}
                />
              }
            />
            <Route
              path="Viagens"
              element={
                <Viagens
                  mode="client"
                  DOC_ID={getDocTypeID("document-Viagens")}
                  TEMPLATE_ID={getDocTypeID("template-Viagens")}
                />
              }
            />
            <Route
              path="Treinamentos"
              element={
                <Treinamentos
                  mode="client"
                  DOC_ID={getDocTypeID("document-Treinamentos")}
                  TEMPLATE_ID={getDocTypeID("template-Treinamentos")}
                />
              }
            />
            <Route
              path="Docs.-contabeis"
              element={
                <DocsContabeis
                  mode="client"
                  DOC_ID={getDocTypeID("document-Contabeis")}
                />
              }
            />
            <Route
              path="Docs.-complementares"
              element={
                <DocsComplementares
                  mode="client"
                  DOC_ID={getDocTypeID("document-Complementares")}
                />
              }
            />
            <Route
              path="Folha-de-Pagamento"
              element={
                <FolhaDePagamento
                  mode="client"
                  DOC_ID={getDocTypeID("document-Payroll")}
                  TEMPLATE_ID={getDocTypeID("template-Payroll")}
                />
              }
            />
            <Route
              path="Projetos-e-evidencias"
              element={<ProjetosEvidencias />}
            >
              <Route index element={<ProjetosList />} />
              <Route
                path="edit"
                element={
                  <ProjetosEdit
                    mode="client"
                    HISTORICO_ID={getDocTypeID("project-Historico")}
                    TIMESHEET_ID={getDocTypeID("project-Timesheet")}
                    EVIDENCIAS_ID={getDocTypeID("project-Evidencias")}
                    TEMP_TIMESHEET_ID={getDocTypeID("template-Timesheet")}
                    TEMP_PROJETO_ID={getDocTypeID("template-Projeto")}
                  />
                }
              />
              <Route path="*" element={<div>backrooms 2</div>} />
            </Route>
            <Route
              path="Memoria-de-Calculo"
              element={
                <MemoriaCalculo
                  mode="client"
                  DOC_ID={getDocTypeID("document-Calculo")}
                />
              }
            />
            <Route
              path="Dossie"
              element={
                <Dossie
                  mode="client"
                  DOC_ID={getDocTypeID("document-Dossie")}
                />
              }
            />
            <Route
              path="Hist.-Parecer-Tecnico"
              element={
                <HistParecerTecnico
                  mode="client"
                  DOC_ID={getDocTypeID("document-Parecer")}
                />
              }
            />
            <Route
              path="Hist.-Relatorio-Auditoria"
              element={
                <HistRelatorioAuditoria
                  mode="client"
                  DOC_ID={getDocTypeID("document-Auditoria")}
                />
              }
            />
            <Route
              path="Hist.-Forms"
              element={
                <HistForms
                  mode="client"
                  DOC_ID={getDocTypeID("document-Forms")}
                />
              }
            />
            <Route
              path="Obrigacoes-Acessorias"
              element={
                <ObrigacoesAcessorias
                  mode="client"
                  DOC_ID={getDocTypeID("document-Obligations")}
                />
              }
            />
          </Route>
        </Route>

        {/*----------------------------------------CONSULTOR------------------------------------------------ */}

        <Route element={<RequireAuth allowedRole="consultant" />}>
          <Route path="consultor" element={<MainView type="consultant" />}>
            <Route path="Empresas" element={<Clientes mode="consultor" />} />
          </Route>
          <Route
            path="consultor"
            element={<MainView type="consultant" banner />}
          >
            <Route path="Dashboard" element={<Dashboard mode="consultant" />} />
            <Route
              path="Identificacao-da-empresa"
              element={
                <IdentificacaoEmpresa
                  mode="consultant"
                />
              }
            />
            <Route
              path="Recursos-Humanos"
              element={
                <RH
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-RH")}
                  TEMPLATE_ID={getDocTypeID("template-RH")}
                />
              }
            />
            <Route
              path="Servicos-de-Terceiros"
              element={
                <Terceiros
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Terceiros")}
                  TEMPLATE_ID={getDocTypeID("template-Terceiros")}
                />
              }
            />
            <Route
              path="Materiais-para-testes"
              element={
                <Materiais
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Materiais")}
                  TEMPLATE_ID={getDocTypeID("template-Materiais")}
                />
              }
            />
            <Route
              path="Viagens"
              element={
                <Viagens
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Viagens")}
                  TEMPLATE_ID={getDocTypeID("template-Viagens")}
                />
              }
            />
            <Route
              path="Treinamentos"
              element={
                <Treinamentos
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Treinamentos")}
                  TEMPLATE_ID={getDocTypeID("template-Treinamentos")}
                />
              }
            />
            <Route
              path="Docs.-contabeis"
              element={
                <DocsContabeis
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Contabeis")}
                />
              }
            />
            <Route
              path="Docs.-complementares"
              element={
                <DocsComplementares
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Complementares")}
                />
              }
            />
            <Route
              path="Folha-de-Pagamento"
              element={
                <FolhaDePagamento
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Payroll")}
                  TEMPLATE_ID={getDocTypeID("template-Payroll")}
                />
              }
            />
            <Route
              path="Projetos-e-evidencias"
              element={<ProjetosEvidencias />}
            >
              <Route index element={<ProjetosList />} />
              <Route
                path="edit"
                element={
                  <ProjetosEdit
                    mode="consultant"
                    HISTORICO_ID={getDocTypeID("project-Historico")}
                    TIMESHEET_ID={getDocTypeID("project-Timesheet")}
                    EVIDENCIAS_ID={getDocTypeID("project-Evidencias")}
                    TEMP_TIMESHEET_ID={getDocTypeID("template-Timesheet")}
                    TEMP_PROJETO_ID={getDocTypeID("template-Projeto")}
                  />
                }
              />
              <Route path="*" element={<div>backrooms 2</div>} />
            </Route>
            <Route
              path="Memoria-de-Calculo"
              element={
                <MemoriaCalculo
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Calculo")}
                  TEMPLATE_ID={getDocTypeID("template-Memoria-Calculo")}
                />
              }
            />
            <Route
              path="Dossie"
              element={
                <Dossie
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Dossie")}
                />
              }
            />
            <Route
              path="Hist.-Parecer-Tecnico"
              element={
                <HistParecerTecnico
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Parecer")}
                />
              }
            />
            <Route
              path="Hist.-Relatorio-Auditoria"
              element={
                <HistRelatorioAuditoria
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Auditoria")}
                />
              }
            />
            <Route
              path="Hist.-Forms"
              element={
                <HistForms
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Forms")}
                />
              }
            />
            <Route
              path="Obrigacoes-Acessorias"
              element={
                <ObrigacoesAcessorias
                  mode="consultant"
                  DOC_ID={getDocTypeID("document-Obligations")}
                />
              }
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
