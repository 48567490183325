import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authActions } from "../store/auth-slice";
import { currentUserActions } from "../store/currentUser";
import useCheckTerms from "../Hooks/useCheckTerms";

export const ADRedirect = () => {
  const dispatch = useDispatch();
	const navigate = useNavigate()
	const [searchParams, setSearchParams] = useSearchParams();
  const checkTerms = useCheckTerms()
  //const setDocTypes = useDocTypes()

  useEffect(() => {
		const access = searchParams.get("access")
		const refresh = searchParams.get("refresh")

    const request = async () => {
      const role = jwt_decode(access).role;
      const ID = jwt_decode(access).user_id;

      dispatch(
        authActions.setUser({
          access: access,
          refresh: refresh,
          userID: ID,
        })
      );

			//console.log(`role: ${role} ID:${ID}`)
			//console.log(`access: ${access} refresh: ${refresh}`)

      dispatch(currentUserActions.setRole(role));
      
      if (role === "admin") {
        navigate("/admin/Empresas", { replace: true });
      } else if (role === "client") {
        checkTerms("/cliente/Empresas", access)
        //navigate("/cliente/Empresas", { replace: true });
      } else if (role === "consultant") {
        navigate("/consultor/Empresas", { replace: true });
      }
      
      //setDocTypes()
    };
    request();
  }, []);

  return <></>
};
