import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HeadedCard } from "../Components/HeadedCard";
import { PageHeader } from "../Components/PageHeader";
import { ProjectsTable } from "../Components/ProjectsTable";
import { YearSelectorContainer } from "../Components/YearSelectorContainer";
import Loading from "../Components/Loading/Loading";
import { useGetDescription } from "../Hooks/useGetDescription";
import useBackPagination from "../Hooks/useBackPagination";
import { PaginationComponent } from "../Components/PaginationComponent";

const PAGE_TITLE = "Projetos e evidências"
const PROJECTS_PER_PAGE = 5;

export const ProjetosList = () => {
    const navigate = useNavigate();
    const currentCompany = useSelector(
        (state) => state.currentUser.selectedCompany
    );
    const currentYear = useSelector((state) => state.currentUser.currentYear);

    const [itemsToShow, setCurrentPage, loading, amountOfItems] = useBackPagination({
        path: "projects/",
        objectFields: [],
        initialPage: 1,
        searchedName: null,
        amountPerPage: PROJECTS_PER_PAGE,
        reqParams: { company: currentCompany.id, year: currentYear }
    }, "Project");

    const description = useGetDescription(PAGE_TITLE)

    const goToEditPage = () => {
        navigate("edit");
    };

    return (
        <>
            <Loading visible={loading} />
            <YearSelectorContainer />
            <PageHeader title={PAGE_TITLE}>
                {description}
            </PageHeader>
            <HeadedCard title="Projetos">
                <ProjectsTable projectsData={itemsToShow} editCallback={goToEditPage} />
            </HeadedCard>
            {amountOfItems > PROJECTS_PER_PAGE && (
                <PaginationComponent
                    size={amountOfItems}
                    itemsPerPage={PROJECTS_PER_PAGE}
                    pageChanger={(page) => {
                        setCurrentPage(page);
                    }}
                />
            )}
        </>
    );
};
