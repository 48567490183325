import Backdrop from '../Backdrop/Backdrop';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import './Loading.css'

export default function Loading({visible, text = "carregando"}) {

    function conditionalRender(){
        if(visible){
            return (
                <Backdrop visible={visible}>
                <div className='loadingDiv'>
                    <AiOutlineLoading3Quarters className='icon-spin' style={{fontSize: '60px', color: 'white'}} />
                    <p className='loadingText'>{text}</p>
                </div>            
                </Backdrop>
            );
        }
        return null;
    }

    return conditionalRender()

    
}