import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const BenefitAccess = ({ checkboxRef, companyBenefits, allowValidation }) => {
  const benefits = ["Lei da Informática", "Lei do bem", "Rota 2030", "Mover"];

  const atLeastOneCheck = () => {
    if (checkboxRef.current) {
      const inputs = Array.from(checkboxRef.current);
      const mappedInputs = inputs.map((item) => item.checked);
      const hasOneOrMoreChecked = mappedInputs.find((check) => {return check === true});
      return hasOneOrMoreChecked ? true : false;
    }
    return false;
  };

  return (
    <section className="p-3">
      <Form ref={checkboxRef}>
        <Form.Group>
          <Row>
            {benefits.map((benefitToRender, index) => {
              let isChecked;
              if (companyBenefits) {
                const searchResult = companyBenefits.find((ben) => {
                  return ben === index+1;
                });
                isChecked = Boolean(searchResult);
              } else {
                isChecked = false;
              }

              return (
                <Col
                  key={index}
                  sm={6}
                  md={3}
                  className="py-2 d-flex justify-content-center"
                >
                  <Form.Check id={benefitToRender}>
                    <Form.Check.Input
                      type="checkbox"
                      isValid={allowValidation && atLeastOneCheck()}
                      isInvalid={allowValidation && !atLeastOneCheck()}
                      defaultChecked={isChecked}
                    />
                    <Form.Check.Label>{benefitToRender}</Form.Check.Label>
                    <Form.Control.Feedback type="invalid">
                      Marque pelo menos um.
                    </Form.Control.Feedback>
                  </Form.Check>
                </Col>
              );
            })}
          </Row>
        </Form.Group>
      </Form>
    </section>
  );
};
