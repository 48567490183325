import { createSlice } from "@reduxjs/toolkit";
const currentYear = new Date().getFullYear()

const initialState = {
  username: "",
  role: "",
  currentUserCompanies: [],
  selectedCompany: null,
  currentNav: "",
  currentYear: currentYear
};

const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setUsername(state, action){
      state.username = action.payload
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setCurrentUserCompanies(state, action) {
      state.currentUserCompanies = action.payload;
    },
    setSelectedCompany(state, action){
      state.selectedCompany = action.payload
    },
    setCurrentNav(state, action){
      state.currentNav = action.payload
    },
    setCurrentYear(state, action){
      state.currentYear = action.payload
    },
    resetData(state){
      state.username = "";
      state.role = "";
      state.currentUserCompanies = [];
      state.selectedCompany = null;
      state.currentNav = "";
      state.currentYear = currentYear
    }
  },
});

export const currentUserActions = currentUserSlice.actions;

export default currentUserSlice;
