import React from "react";
import { Outlet } from "react-router-dom";
import { PageHeader } from "../Components/PageHeader";

export const ClientesAdmin = () => {
  return (
    <>
      <PageHeader title="Empresas">
        Gerenciamento de clientes P&D Nacional.
      </PageHeader>
      <Outlet />
    </>
  );
};
