import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { IMaskInput } from "react-imask";

export const UploadFileModal = (props) => {
  const [validated, setValidated] = useState(null);
  const fileRef = useRef();
  const [fileYear, setFileYear] = useState(null);
  const fileYearRef = useRef();
  const [uploaded, setUploaded] = useState(false)

  useEffect(() => {
    setValidated(null);
    setUploaded(false)
  }, [props.show]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    //console.log(fileYearRef);
    if (
      form.checkValidity() === false ||
      fileYearRef.current?.props.className.includes("is-invalid")
    ) {
      e.stopPropagation();
      setValidated(false);
      return;
    }

    setUploaded(true)
    props.getFile(fileRef.current.files[0], fileYear ? Number(fileYear) : 2023);
    setValidated(true);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setValidated(null);
        props.onHide();
        setUploaded(false)
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload de arquivo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Selecione um arquivo</Form.Label>
            <Form.Control type="file" required name="file" ref={fileRef} />
            <Form.Control.Feedback type="invalid">
              Selecione um arquivo para ser enviado
            </Form.Control.Feedback>
          </Form.Group>
          {!props.excel ? (
            <Form.Group>
              <Form.Label>Ano-Calendário</Form.Label>
              <Form.Control
                as={IMaskInput}
                mask="0000"
                type="text"
                ref={fileYearRef}
                required
                placeholder="Ano"
                isInvalid={
                  fileYear === null
                    ? null
                    : Number(fileYear) < 2000
                    ? true
                    : false
                }
                isValid={
                  fileYear === null
                    ? null
                    : Number(fileYear) > 2000
                    ? true
                    : false
                }
                value={fileYear === null ? "2023" : fileYear}
                onChange={(e) => setFileYear(e.target.value)}
                style={{ width: "100px" }}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Entre um ano válido.
              </Form.Control.Feedback>
            </Form.Group>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" disabled={uploaded}>
            {uploaded && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />}
            {!uploaded && "Upload"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
