import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosBearerFile from "../Hooks/useAxiosBearerFile";
import { refreshDashboardActions } from "../store/refresh-dashboard-slice";
import CustomAlert from "./CustomAlert/CustomAlert";
import { FeedbackToast } from "./FeedbackToast";
import { UploadFileModal } from "./UploadFileModal";

export const UploadManager = ({
  documentID,
  triggerModal,
  handleModal,
  currentTableSize = 0,
  mode = "project",
}) => {
  //modes: story, project, excel, common
  const currentCompanyID = useSelector(
    (state) => state.currentUser.selectedCompany.id
  );
  const currentCompanyCNPJ = useSelector(
    (state) => state.currentUser.selectedCompany.cnpj
  );
  const userRole = useSelector((state) => state.currentUser.role);
  const projectData = useSelector((state) => state.projectOrTermToEdit.projectOrTermToEdit);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [file, setFile] = useState(null);
  const [fileYear, setFileYear] = useState(2023);
  const [showAlert, setShowAlert] = useState(false);
  const [excelErrorMessage, setExcelErrorMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const dispatch = useDispatch()

  const getFile = (uploadedFile, uploadedFileYear) => {
    if (!uploadedFile) return;

    setFileYear(uploadedFileYear);
    setFile(uploadedFile);
  };

  const axiosBearerFile = useAxiosBearerFile();

  const uploadFile = useCallback(async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    const uploadDate = new Date();
    if (mode === "project") {
      formData.append("year", uploadDate.getUTCFullYear());
    } else if (mode !== "excel") {
      formData.append("year", fileYear);
    }

    if (mode === "excel") {
      //console.log(file)
      dispatch(refreshDashboardActions.setFlag(false))
      formData.append("cnpj", currentCompanyCNPJ);

      axiosBearerFile
        .post("excel/", formData)
        .then((res) => {
          setShowModal(false);
          handleModal();
          setShowToast(true);
          dispatch(refreshDashboardActions.setFlag(true))
        })
        .catch((err) => {
          setShowModal(false);
          handleModal();
          setShowAlert(true);
          setExcelErrorMessage(err.response.data.includes("<") ? "O arquivo enviado possui um erro não documentado. Por favor comunicar os desenvolvedores." : err.response.data)
        });
    } else {
      const description =
        mode === "story"
          ? `${userRole === "client" ? "Versão" : "Revisão"} ${Math.floor(
              currentTableSize / 2 + 1
            )}`
          : file.name;
      const status = userRole === "client" ? "aguardandoAnalise" : "none";
      //const currentDate = new Date();

      //console.log(file)
      formData.append("document_type", documentID);
      //formData.append("year", currentDate.getUTCFullYear());
      formData.append("company", currentCompanyID);
      formData.append("status", status);
      formData.append("description", description);
      if (mode === "project" || mode === "story")
        formData.append("project", projectData.id);

      /*const response = await axiosBearerFile.post(
        "companies/documents/",
        formData
      );
      if (response.status === 201) {
        setShowToast(true);
      }*/

      axiosBearerFile
        .post("companies/documents/", formData)
        .then((res) => {
          setShowToast(true);
          setShowModal(false);
          handleModal();
        })
        .catch((err) => {
          setShowAlert(true);
          setShowModal(false);
          setErrorMessage(err.response?.data?.non_field_errors?.[0] || null)
          handleModal();
        });
    }
    //setShowModal(false);
    //handleModal();
    setFile(null);
  }, [
    axiosBearerFile,
    file,
    documentID,
    currentCompanyID,
    handleModal,
    currentTableSize,
    userRole,
  ]);

  useEffect(() => {
    if (file) {
      uploadFile();
    }
  }, [file, uploadFile]);

  useEffect(() => {
    if (triggerModal) {
      setShowModal(true);
    }
  }, [triggerModal]);

  return (
    <>
      <CustomAlert
        visible={showAlert}
        type={"error"}
        message={
          excelErrorMessage || errorMessage || "Erro no envio de arquivo. Tente novamente ou verifique suas permissões."
        }
        onAccept={() => {
          setShowAlert(false);
        }}
      />
      <UploadFileModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          handleModal();
          setFile(null);
        }}
        getFile={getFile}
        excel={mode === "excel"}
      />
      <FeedbackToast
        show={showToast}
        onClose={() => {
          setShowToast(false);
        }}
        title="Upload de Arquivo"
        subtitle="agora"
      >
        Sucesso! Arquivo enviado!
      </FeedbackToast>
    </>
  );
};
