import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading/Loading";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { TermTable } from "../Components/TermTable";
import { PaginationComponent } from "../Components/PaginationComponent";
import usePagination from "../Hooks/usePagination";
import { HeadedCard } from "../Components/HeadedCard";

const TERMS_PER_PAGE = 5;

export const TermsList = () => {
  const navigate = useNavigate();
  const axiosBearer = useAxiosBearer();
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [itemsToShow, setCurrentPage] = usePagination(1, terms, TERMS_PER_PAGE);

  const goToViewPage = () => {
    navigate("view");
  };
  const goToAddPage = () => {
    navigate("add");
  };

  useEffect(() => {
    setLoading(true);
    const request = async () => {
      const response = await axiosBearer.get("terms/");
      setLoading(false);
      const responseTerms = response.data.map((term) => {
        setLoading(false);
        return {
          id: term.id,
          title: term.title,
          text: term.text,
          version: term.version,
          created: term.created,
        };
      });
      setTerms(responseTerms.reverse());
    };
    request();
  }, [axiosBearer]);

  return (
    <>
      <Loading visible={loading} />
      <div className="d-flex justify-content-end">
        <Button className="px-3" onClick={goToAddPage}>
          Adicionar
        </Button>
      </div>
      <HeadedCard title="Termos de Uso e Privacidade">
        <TermTable termsArray={itemsToShow} viewButtonCallback={goToViewPage} />
      </HeadedCard>
      {terms.length > TERMS_PER_PAGE && (
        <PaginationComponent
          size={terms.length}
          itemsPerPage={TERMS_PER_PAGE}
          pageChanger={(page) => {
            setCurrentPage(page);
          }}
        />
      )}
    </>
  );
};
