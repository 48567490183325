import React from "react";
import Table from "react-bootstrap/Table";
import { FileTableRow } from "./FileTableRow";

export const FileTable = ({filesArray = [], refreshTable}) => {
  return (
    <Table className="align-middle" responsive>
      <thead>
        <tr>
          <th>Nome do arquivo</th>
          <th className="text-center">Data do upload</th>
          <th className="text-center">Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {filesArray.map((file) => {
          return <FileTableRow key={file.id} file={file} refreshTable={refreshTable}/>
        })}
      </tbody>
    </Table>
  );
};
