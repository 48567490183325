import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { HeadedCard } from "../Components/HeadedCard";
import { ProjectsTable } from "../Components/ProjectsTable";
import { useSelector } from "react-redux";
import Loading from "../Components/Loading/Loading";
import { PaginationComponent } from "../Components/PaginationComponent";
import useBackPagination from "../Hooks/useBackPagination";

const PROJECTS_PER_PAGE = 5;

export const ClientesAdminProjetosList = () => {
  const navigate = useNavigate();
  const companyID = useSelector((state) => state.clientToEdit.clientToEdit.id);
  const [itemsToShow, setCurrentPage, loading, amountOfItems] = useBackPagination({
    path: `companies/${companyID}/projects/`,
    objectFields: [],
    initialPage: 1,
    searchedName: null,
    amountPerPage: PROJECTS_PER_PAGE,
    reqParams: null
  }, "Project");

  const goToEditPage = () => {
    navigate("edit");
  };
  const goToAddPage = () => {
    navigate("add");
  };

  console.log(itemsToShow)

  return (
    <>
      <Loading visible={loading} />
      <div className="d-flex justify-content-end">
        <Button className="px-3" onClick={goToAddPage}>
          Adicionar
        </Button>
      </div>
      <HeadedCard title="Projetos">
        <ProjectsTable
          editCallback={goToEditPage}
          projectsData={itemsToShow}
          admin
        />
      </HeadedCard>
      {amountOfItems > PROJECTS_PER_PAGE && (
        <PaginationComponent
          size={amountOfItems}
          itemsPerPage={PROJECTS_PER_PAGE}
          pageChanger={(page) => {
            setCurrentPage(page);
          }}
        />
      )}
    </>
  );
};
