import React from "react";
import Table from "react-bootstrap/Table";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { CustomBadge } from "./CustomBadge";
import { useDispatch } from "react-redux";
import { clientToEditActions } from "../store/client-to-edit-slice";
import { currentUserActions } from "../store/currentUser";
import { useNavigate } from "react-router-dom";
import useCheckValidation from "../Hooks/useCheckValidation";

export const ClientTable = ({
  mode,
  type,
  projectCallback = null,
  userCallback = null,
  editCallback = null,
  companies = [],
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkValidation = useCheckValidation()

  const handleSelect = (client) => {
    dispatch(currentUserActions.setSelectedCompany(client));
    if (mode === "consultor") {
      navigate(`/${mode}/Dashboard`, {
        replace: true,
      });
      dispatch(currentUserActions.setCurrentNav("Dashboard"));
    } else {
      checkValidation(client.dateRevision, client.nextValidation, client)
    }
  }

  return (
    <Table className="align-middle" responsive>
      <thead>
        <tr>
          <th>Razão Social</th>
          <th className="text-center">CNPJ</th>
          <th className="text-center">UF</th>
          <th className="text-center">Grupo</th>
          <th className="text-center">Principal</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {companies.map((client) => {
          return (
            <tr key={client?.id}>
              <td>{client?.name}</td>
              <td className="text-center" style={{ minWidth: "180px" }}>
                {client?.cnpj.replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  "$1.$2.$3/$4-$5"
                )}
              </td>
              <td className="text-center">{client?.uf}</td>
              <td className="text-center">
                {client?.group ? client.group : "-"}
              </td>
              <td className="text-center">
                <CustomBadge
                  type={client.principal ? "principal" : "inactive"}
                />
              </td>
              <td>
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="d-flex justify-content-center"
                >
                  {type === "edit" ? (
                    <>
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          dispatch(clientToEditActions.setClientToEdit(client));
                          projectCallback();
                        }}
                      >
                        Projetos
                      </Button>
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          dispatch(clientToEditActions.setClientToEdit(client));
                          userCallback();
                        }}
                      >
                        Usuários
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          dispatch(clientToEditActions.setClientToEdit(client));
                          editCallback();
                        }}
                      >
                        Editar
                      </Button>
                    </>
                  ) : (
                    <Button
                      onClick={() => handleSelect(client)}
                    >
                      Selecionar
                    </Button>
                  )}
                </Stack>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
