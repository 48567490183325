import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Icon } from "./Icon";

export const ProjectHeader = ({ data }) => {
  return (
    <Container>
      <Row className="p-0 py-md-2">
        <Col
          md={6}
          className="d-flex justify-content-start align-items-center gap-2"
        >
          <Icon icon={"PencilSquare"} className="text-black my-2" />
          Macroprojeto: <span className="fw-bold">{data.macroproject}</span>
        </Col>
        <Col md={3} className="d-flex justify-content-start align-items-center gap-2">
          Número: <span className="fw-bold">{data.number}</span>
        </Col>
        <Col
          md={3}
          className="d-flex justify-content-start justify-content-md-end align-items-center gap-2"
        >
          Plurianual: <span className="fw-bold">{data.multiannual ? " SIM" : " NÃO"}</span>
        </Col>
      </Row>
      <Row>
        <Col
          md={6}
          className="d-flex justify-content-start align-items-center gap-2"
        >
          <Icon icon={"PencilSquare"} className="text-black" />
          Microprojeto: <span className="fw-bold">{data.microproject}</span>
        </Col>
        <Col md={3} >
          Líder: <span className="fw-bold">{data.leader}</span>
        </Col>
        <Col
          md={3}
          className="d-flex justify-content-start justify-content-md-end gap-2"
        >
          Ano-Calendário: <span className="fw-bold">{data.years.join("/")}</span>
        </Col>
      </Row>
    </Container>
  );
};
