import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

export const PaginationComponent = ({
  size,
  itemsPerPage,
  pageChanger,
  forcePageChangeFromOutside
}) => {
  const pagesAmount = Math.ceil(size / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  let items = [];
  for (let number = 1; number <= pagesAmount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={()=>{setCurrentPage(number);pageChanger(number)}}>
        {number}
      </Pagination.Item>
    );
  }

  useEffect(()=>{
    setCurrentPage(1)
  },[forcePageChangeFromOutside])

  return (
    <div className="d-flex justify-content-center">
      {pagesAmount <= 5 && <Pagination>{items}</Pagination>}
      {pagesAmount > 5 && (
        <Pagination>
          {pagesAmount > 5 && currentPage !== 1 && (
            <>
              <Pagination.First
                onClick={() => {
                  setCurrentPage(1);
                  pageChanger(1);
                }}
              />
              <Pagination.Prev
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                  pageChanger(currentPage - 1);
                }}
              />
            </>
          )}

          <Pagination.Item
            active={currentPage === 1}
            onClick={() => {
              pageChanger(1);
              setCurrentPage(1);
            }}
          >
            {1}
          </Pagination.Item>
          {pagesAmount > 5 && currentPage >= 4 && <Pagination.Ellipsis />}

          <Pagination.Item
            onClick={() => {
              if (currentPage <= 3) {
                setCurrentPage(2);
                pageChanger(2);
              } else if (currentPage <= pagesAmount - 2) {
                setCurrentPage(currentPage - 1);
                pageChanger(currentPage - 1);
              } else {
                setCurrentPage(pagesAmount - 3);
                pageChanger(pagesAmount - 3);
              }
            }}
            active={currentPage === 2}
          >
            {currentPage <= 3
              ? 2
              : currentPage <= pagesAmount - 2
              ? currentPage - 1
              : pagesAmount - 3}
          </Pagination.Item>

          <Pagination.Item
            onClick={() => {
              if (currentPage < 3) {
                setCurrentPage(3);
                pageChanger(3);
              } else if (currentPage >= pagesAmount - 1) {
                setCurrentPage(pagesAmount - 2);
                pageChanger(pagesAmount - 2);
              }
            }}
            active={currentPage >= 3 && currentPage <= pagesAmount - 2}
          >
            {currentPage <= 3
              ? 3
              : currentPage <= pagesAmount - 2
              ? currentPage
              : pagesAmount - 2}
          </Pagination.Item>

          <Pagination.Item
            onClick={() => {
              if (currentPage <= 3) {
                setCurrentPage(4);
                pageChanger(4);
              } else if (currentPage <= pagesAmount - 1) {
                setCurrentPage(currentPage + 1);
                pageChanger(currentPage + 1);
              } else {
                setCurrentPage(pagesAmount - 1);
                pageChanger(pagesAmount - 1);
              }
            }}
            active={currentPage === pagesAmount - 1}
          >
            {currentPage <= 3
              ? 4
              : currentPage <= pagesAmount - 2
              ? currentPage + 1
              : pagesAmount - 1}
          </Pagination.Item>

          {pagesAmount > 5 && currentPage <= pagesAmount - 3 && (
            <Pagination.Ellipsis />
          )}
          <Pagination.Item
            onClick={() => {
              setCurrentPage(pagesAmount);
              pageChanger(pagesAmount);
            }}
            active={currentPage === pagesAmount}
          >
            {pagesAmount}
          </Pagination.Item>
          {pagesAmount > 5 && currentPage !== pagesAmount && (
            <>
              <Pagination.Next
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  pageChanger(currentPage + 1);
                }}
              />
              <Pagination.Last
                onClick={() => {
                  setCurrentPage(pagesAmount);
                  pageChanger(pagesAmount);
                }}
              />
            </>
          )}
        </Pagination>
      )}
    </div>
  );
};
