import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DescriptionCard } from "../Components/DescriptionCard";
import useAxiosBearer from "../Hooks/useAxiosBearer";

export const Descricoes = () => {
  const [areas, setAreas] = useState([])
  const axiosBearer = useAxiosBearer()

  useEffect(()=>{
    const request = async () => {
      const navigationTabs = await axiosBearer.get("tabs/")

      setAreas(navigationTabs.data)
    }
    request()
  },[])

  return (
    <Container>
      <Row>
        {areas.map((item) => {
          return (
          <Col md={12} xl={6} key={item.id} style={{padding: "12px"}}>
            <DescriptionCard descriptionData={item} />
          </Col>)
        })}
      </Row>
    </Container>
  );
};
