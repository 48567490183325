import React from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import { projectOrTermToEditActions } from "../store/project-to-edit-slice";

export const ProjectsTable = ({
  editCallback,
  projectsData,
  admin = false,
}) => {
  const dispatch = useDispatch();

  return (
    <Table className="align-middle" responsive>
      <thead>
        <tr>
          <th>Número</th>
          {!admin && <th>Macroprojeto</th>}
          <th>Microprojeto</th>
          <th>Líder</th>
          <th className="text-center">Ano-Calendário</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {projectsData?.map((project) => {
          return (
            <tr key={project.id}>
              <td>{project.number ? project.number : "-"}</td>
              {!admin && <td>{project.macroproject ? project.macroproject : "-"}</td>}
              <td>{project.microproject}</td>
              <td>{project.leader ? project.leader : "-"}</td>
              <td className="text-center">
                {project.years.length ? project.years.join(" / ") : ""}
              </td>
              <td className="align-right">
                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    onClick={() => {
                      dispatch(projectOrTermToEditActions.setProjectOrTermToEdit(project));
                      editCallback();
                    }}
                  >
                    {admin ? "Editar" : "Abrir"}
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
