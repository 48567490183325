import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { HeadedCard } from "../Components/HeadedCard";
import { UserInfo } from "../Components/UserInfo";
import { ClientRemoveTable } from "../Components/ClientRemoveTable";
import { SaveAndDelete } from "../Components/SaveAndDelete";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { AddClientPopover } from "../Components/AddClientPopover";
import { permissionAreas } from "../NavLists";
import Loading from "../Components/Loading/Loading";
import CustomAlert from "../Components/CustomAlert/CustomAlert";

export const ConsultoresEdit = ({ add = false }) => {
  const [validatedConsultantForm, setValidatedConsultantForm] = useState(null);
  const [userCompanies, setUserCompanies] = useState([]);
  const [consultingsToDelete, setConsultingsToDelete] = useState([]);
  const navigate = useNavigate();
  const stateUserInfo = useSelector((state) => {
    return add ? {} : state.userToEdit.userToEdit;
  });
  const axiosBearer = useAxiosBearer();
  const [showAlert, setShowAlert] = useState(false);
  const [alertStyle, setAlertStyle] = useState("ok");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertDelete, setAlertDelete] = useState(false);

  const [loading, setLoading] = useState(false);
  const consultantFormRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const isActiveRef = useRef();  
  const refs = {
    name: nameRef,
    email: emailRef,
    isActive: isActiveRef,
  };

  //overlay variables
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const overlayRef = useRef(null);

  const confirmGoBack = () => {
    if (validatedConsultantForm) {
    setShowAlert(false)
    navigate(-1);
    }
    else {
      setShowAlert(false);
    }    
  };     

  const handleRemove = (companies, consultings) => {
    //console.log(consultings);
    setUserCompanies(companies);
    setConsultingsToDelete(consultings);
  };

  const handleAdd = (companyToAdd) => {
    const newCompanies = [...userCompanies];
    newCompanies.push(companyToAdd);
    setUserCompanies(newCompanies);
    setShow(false);
  };

  useEffect(() => {
    if (stateUserInfo?.user_companies)
      setUserCompanies(stateUserInfo.user_companies);
  }, [stateUserInfo.user_companies]);

  const onSave = () => {
    if (consultantFormRef.current.checkValidity() === false) {
      setAlertMessage("Preencha todos os campos");
      setAlertStyle("error");
      setShowAlert(true);      
      setValidatedConsultantForm(false);
      return;
    } else {
      setLoading(true);
      const consultorToPost = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        username: emailRef.current.value,
        password: "usuario@123",
        password2: "usuario@123",
        user_groups: [1],
        is_active: isActiveRef.current.value === "SIM" ? true : false
      };

      if (add) {
        const userPermissions = permissionAreas.map((permission) => {
          return permission.permissionName
        })
        //console.log(consultorToPost, " add")
        axiosBearer
          .post("users/", consultorToPost)
          .then((res) => {
            userCompanies.forEach((comp) => {
              //console.log("inside forEach", consultorToPost)
              axiosBearer.post("consultings/", {
                consultor_email: consultorToPost.email,
                company_cnpj: comp.cnpj,
              }).catch((err)=>{
                setLoading(false);
                setAlertMessage("Erro ao cadastrar consultor");
                setAlertStyle("error");
                setShowAlert(true);                
                return;
              })
            });
            axiosBearer.patch(`users/${res.data.id}/`, {
              perms: userPermissions
            }).then((res)=>{
              setLoading(false);
              setAlertMessage("Consultor cadastrado com sucesso");
              setAlertStyle("ok");
              setShowAlert(true);              
            }).catch((err)=>{
              setLoading(false);
              setAlertMessage("Erro ao cadastrar consultor");
              setAlertStyle("error");
              setShowAlert(true);              
            })
          }).catch((err)=>{
            setLoading(false);
            setAlertMessage("Erro ao cadastrar consultor");
            setAlertStyle("error");
            setShowAlert(true);
          });
      } else {
        //console.log('caiu em edit')
        //console.log(consultorToPost, " edit")
        axiosBearer.put(`users/${stateUserInfo.id}/`, consultorToPost).then(()=>{
          userCompanies.forEach((comp)=>{
            if(!comp.consulting_id){
              axiosBearer.post("consultings/", {
                consultor_email: consultorToPost.email,
                company_cnpj: comp.cnpj,
              }).catch((err)=>{
                setLoading(false);
                setAlertMessage("Erro ao editar consultor");
                setAlertStyle("error");
                setShowAlert(true);   
                //console.log('erro em post consultings/')     
                return        
              })
            }
          })
          //console.log('cadastrou consultorias')
          setLoading(false);
          setAlertMessage("Consultor editado com sucesso");
          setAlertStyle("ok");
          setShowAlert(true);
        }).catch((err)=>{
          setLoading(false);
          setAlertMessage("Erro ao editar consultor");
          setAlertStyle("error");
          setShowAlert(true);
          //console.log('erro em put users/')
          return
        })
        consultingsToDelete.forEach((cons)=>{          
          axiosBearer.delete(`consultings/${cons}/`).catch((err)=>{
            setLoading(false);
            setAlertMessage("Erro ao editar consultor");
            setAlertStyle("error");
            setShowAlert(true);         
            //console.log('erro em delete consultings/')   
            return;
          })
        })
      }
    }
    setValidatedConsultantForm(true);
  };

  const onDelete = () => {
    setAlertDelete(false);
    setLoading(true);
    //console.log("deleted", consultingsToDelete);
    axiosBearer.delete(`users/${stateUserInfo.id}/`).then((res) => {
      setLoading(false);
      setAlertMessage("Consultor excluido com sucesso");
      setAlertStyle("ok");
      setValidatedConsultantForm(true);
      setShowAlert(true);
    }).catch((err)=>{
      setLoading(false);
      setAlertMessage("Erro ao excluir consultor");
      setAlertStyle("error");
      setShowAlert(true);      
    })
  };

  const handleOverlay = (e) => {
    setShow(!show);
    setTarget(e.target);
  };

  return (
    <>
      <Loading visible={loading} />
      <CustomAlert visible={showAlert} type={alertStyle} message={alertMessage} onAccept={confirmGoBack} />
      <CustomAlert visible={alertDelete} type="prompt" message="Deseja realmente excluir este consultor?" onAccept={onDelete} onRefuse={() => setAlertDelete(false)} />

      <HeadedCard title="Informações cadastrais">
        <UserInfo
          formRef={consultantFormRef}
          contentRef={refs}
          validatedForm={validatedConsultantForm}
          userData={stateUserInfo}
          addMode={add}
          consultant
        />
      </HeadedCard>
      <div className="d-flex justify-content-end" ref={overlayRef}>
        <Button className="px-3" onClick={handleOverlay}>
          Adicionar Cliente
        </Button>
        <AddClientPopover
          show={show}
          target={target}
          overlayRef={overlayRef}
          sendData={(c) => {
            handleAdd(c);
          }}
        />
      </div>
      <HeadedCard title="Clientes">
        <ClientRemoveTable
          clients={userCompanies}
          onRemove={(remainingCompanies, consultings) => {
            handleRemove(remainingCompanies, consultings);
          }}
          addMode={add}
        />
      </HeadedCard>
      <SaveAndDelete
        saveCallback={onSave}
        deleteCallback={() => setAlertDelete(true)}
        addMode={add}
      />
    </>
  );
};
