import React from "react";
import Button from "react-bootstrap/Button";
import { UserTable } from "../Components/UserTable";
import { HeadedCard } from "../Components/HeadedCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../Components/Loading/Loading";
import { PaginationComponent } from "../Components/PaginationComponent";
import useBackPagination from "../Hooks/useBackPagination";

const USERS_PER_PAGE = 5

export const ClientesAdminUsuariosList = () => {
  const companyID = useSelector((state) => state.clientToEdit.clientToEdit.id);
  const navigate = useNavigate();
  const [itemsToShow, setCurrentPage, loading, amountOfItems] = useBackPagination({
    path: `companies/${companyID}/users/`,
    objectFields: [],
    initialPage: 1,
    searchedName: null,
    amountPerPage: USERS_PER_PAGE,
    reqParams: null
  }, "Users");

  console.log(itemsToShow)

  const goToEditPage = () => {
    navigate("edit");
  };
  const goToAddPage = () => {
    navigate("add");
  };

  return (
    <>
      <Loading visible={loading} />
      <div className="d-flex justify-content-end">
        <Button className="px-3" onClick={goToAddPage}>
          Adicionar
        </Button>
      </div>
      <HeadedCard title="Usuários">
        <UserTable
          editButtonCallback={goToEditPage}
          userData={itemsToShow}
          mode="companyUser"
        />
      </HeadedCard>
      {amountOfItems > USERS_PER_PAGE && (
        <PaginationComponent
          size={amountOfItems}
          itemsPerPage={USERS_PER_PAGE}
          pageChanger={(page) => {
            setCurrentPage(page);
          }}
        />
      )}
    </>
  );
};
