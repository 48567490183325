import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectOrTermToEdit: {},
};

const projectOrTermToEditSlice = createSlice({
  name: "projectOrTermToEdit",
  initialState,
  reducers: {
    setProjectOrTermToEdit(state, action) {
      state.projectOrTermToEdit = action.payload;
    },
    resetData(state){
      state.projectOrTermToEdit = {}
    }
  },
});

export const projectOrTermToEditActions = projectOrTermToEditSlice.actions;

export default projectOrTermToEditSlice;
