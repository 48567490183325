import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

export const FeedbackToast = ({ show, onClose, title, subtitle, children }) => {
  return (
    <ToastContainer className="p-3 custom-toast-container">
      <Toast onClose={onClose} show={show} delay={4000} autohide>
        <Toast.Header closeButton={false}>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">{title}</strong>
          <small>{subtitle}</small>
        </Toast.Header>
        <Toast.Body>{children}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};
