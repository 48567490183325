import React, { useEffect, useState } from "react";
import {useNavigate} from "react-router-dom"
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import useAxiosBearer from "../Hooks/useAxiosBearer";

export const TermsOfService = () => {
  const [checkboxState, setCheckboxState] = useState(false)
  const [term, setTerm] = useState({})
  const navigate = useNavigate()
  const axiosBearer = useAxiosBearer()

  const handleAcceptTerms = () => {
    axiosBearer.post("users/accept_terms/").then((res)=>{
      navigate("/cliente/Empresas/",{replace: true})
    }).catch((err)=>{
      //console.log(err)
    })
  }

  useEffect(()=>{
    const request = () => {
      axiosBearer.get("terms/latest/").then((res)=>{
        setTerm(res.data)
      }).catch((err)=>{
        //console.log("Erro ao pegar o termo")
      })
    }
    request()
  }, [])

  return (
    <div
      className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center gap-4"
      style={{ backgroundColor: "#F8F9FA" }}
    >
      <h1>Termos de Uso e Privacidade</h1>
      <Card
        className="overflow-auto bg-white p-3 terms"
        style={{ width: "80%", height: "60%", margin: "0 auto" }}
      >
        {term?.text?.split("\n").map((text, index)=>{
          return <p key={index} style={{textAlign: "justify"}}>{text}</p>
        })}
      </Card>
      <div className="d-flex flex-column flex-sm-row gap-2 justify-content-between align-items-center mb-5" style={{width: "80%"}}>
        <div className="d-flex flex-nowrap">
          <input type="checkbox" onChange={()=>{setCheckboxState(prevState=> {return !prevState})}}/>
          <label className="mx-3">
          Declaro que li, estou ciente e concordo com as condições acima especificadas, sendo que manifesto o meu consentimento por meio do aceite eletrônico destes TERMOS.
          </label>
        </div>
        <Button disabled={!checkboxState} onClick={handleAcceptTerms}>Aceitar</Button>
      </div>
    </div>
  );
};
