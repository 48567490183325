import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../Images/martinelli-logo.png";
import { adminNavs, clienteNavs, consultorNavs } from "../NavLists";
import { Icon } from "./Icon";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { currentUserActions } from "../store/currentUser";
import useBreakpoint from "../Hooks/useBreakpoint";
import { Logout } from "./Logout";

export const AppNavbar = ({ type }) => {
  //type: admin, client, consultant
  const [arrayToMap, setArrayToMap] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const currentNav = useSelector((state) => state.currentUser.currentNav);
  const currentCompany = useSelector(
    (state) => state.currentUser.selectedCompany
  );
  const userID = useSelector((state) => state.auth.userID);
  const axiosBearer = useAxiosBearer();
  const dispatch = useDispatch();

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if(!currentNav)
      dispatch(currentUserActions.setCurrentNav("Empresas"));
    axiosBearer.get(`users/${userID}/`).then((res) => {
      setPermissions(res.data.user_permissions);
      dispatch(currentUserActions.setUsername(res.data.name))
    });

    if (type === "admin") {
      setArrayToMap(adminNavs);
    }
    if (type === "consultant") {
      setArrayToMap(consultorNavs);
    }
    if (type === "client") {
      setArrayToMap(clienteNavs);
    }
  }, []);

  return (
    <Navbar
      collapseOnSelect
      expand="md"
      variant="dark"
      className="flex-row flex-md-column h-100"
      style={breakpoint > 1921 ? { maxWidth: "500px" } : {}} 
    >
      <Navbar.Brand className="m-0">
        <img
          src={logo}
          alt="Logo Martinelli Advogados"
          className="img-fluid px-3 py-3 py-lg-5"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        id="basic-navbar-nav"
        className={`w-100 flex-md-column justify-content-md-between ${
          breakpoint > 1921 ? "d-flex align-items-start" : ""
        }`}
      >
        <Nav
          className="flex-column w-100 h-100 gap-1"
          variant="pills"
          activeKey={currentNav}
          onSelect={(key) => {
            if (currentCompany || type === "admin")
              dispatch(currentUserActions.setCurrentNav(key));
          }}
        >
          {arrayToMap.map((navigation, index) => {
            let path = navigation.content
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replaceAll(" ", "-");

            if (navigation?.alternativePath) path = navigation.alternativePath;

            if (
              !currentCompany &&
              type !== "admin" &&
              navigation.content !== "Empresas"
            )
              return null;

            if (
              navigation?.permission &&
              !permissions.includes(navigation.permission)
            )
              return null;

            return (
              <Fragment key={index}>
                <Nav.Item>
                  <Nav.Link
                    eventKey={navigation.content}
                    as={Link}
                    to={currentCompany || type === "admin" ? path : null}
                    className={`custom-nav ${type}-active px-2`}
                  >
                    <div>
                      <Icon icon={navigation.icon} />
                      {navigation.content}
                    </div>
                  </Nav.Link>
                </Nav.Item>
                {type !== "admin" && index === 0 && (
                  <hr className="w-100 m-0" style={{color: "#eee"}}/>
                )}
              </Fragment>
            );
          })}
        </Nav>
        <Logout/>
      </Navbar.Collapse>
    </Navbar>
  );
};
