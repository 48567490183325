import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { PageHeader } from "../Components/PageHeader";

export const ClientesAdminUsuarios = () => {
  const clientName = useSelector((state)=>state.clientToEdit.clientToEdit.name)

  return (
    <>
      <PageHeader title="Usuários">
        {"Gerenciamento de usuários do cliente "}
        <strong>{clientName}</strong>
      </PageHeader>
      <Outlet />
    </>
  );
};
