import React, { useEffect, useRef, useState } from "react";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import useAxiosBearer from "../Hooks/useAxiosBearer";

export const AddClientPopover = ({ show, target, overlayRef, sendData }) => {
  const [validatedCNPJ, setValidatedCNPJ] = useState(null);
  const [companyExists, setCompanyExists] = useState(false);
  const [companies, setCompanies] = useState([]);
  const cnpjRef = useRef();
  const axiosBearer = useAxiosBearer();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!companyExists) {
      return;
    }

    sendData(companyExists)
  };

  const handleCNPJChange = () => {
    const enteredCNPJ = cnpjRef.current?.value.replaceAll(/([./-])/g, "");
    enteredCNPJ.length === 14
      ? setValidatedCNPJ(true)
      : setValidatedCNPJ(false);
  };

  //Reset Popover initial state when show changes
  useEffect(()=>{
    setValidatedCNPJ(null)
    setCompanyExists(false)
  }, [show])

  useEffect(() => {
    const enteredCNPJ = cnpjRef.current?.value.replaceAll(/([./-])/g, "");

    if (validatedCNPJ) {
      const searchCNPJ = async () => {
        const result = await axiosBearer.get("companies/", {params: {search: enteredCNPJ}});
        
        setCompanyExists(result.data.results[0]);//set with company info which is truthy
      }

      searchCNPJ()
    } else {
      setCompanyExists(false);
    }
  }, [validatedCNPJ, companies]);

  return (
    <>
      <Overlay
        show={show}
        target={target}
        placement="left"
        container={overlayRef}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-2">
                <Form.Control
                  required
                  type="text"
                  ref={cnpjRef}
                  placeholder="Digite o CNPJ"
                  isInvalid={validatedCNPJ === null ? null : !companyExists}
                  isValid={validatedCNPJ === null ? null : companyExists}
                  onChange={handleCNPJChange}
                />
                <Form.Control.Feedback type="invalid">
                  {!validatedCNPJ && "Insira um CNPJ válido"}
                  {validatedCNPJ &&
                    !companyExists &&
                    "Este cliente não está no sistema. Adicione-o primeiro."}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit">Adicionar</Button>
            </Form>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};
