import { useNavigate } from "react-router-dom";
import useAxiosBearer from "./useAxiosBearer";
import { axiosBearer } from "../api/Api";

const useCheckTerms = () => {
  const navigate = useNavigate();
  const axiosBearerHook = useAxiosBearer();

  //checkTerms can be used passing a Bearer token or not.
  //when common login in it will use the hook to make the request
  //when login in with AD it will use the received token from the AD redirect
  const checkTerms = (path, token) => {
    (token ? axiosBearer : axiosBearerHook)
      .get("users/terms/", token ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      } : {})
      .then((res) => {
        navigate(path, { replace: true });
      })
      .catch((err) => {
        navigate("/Termos", { replace: true });
      });
  };

  return checkTerms;
};

export default useCheckTerms;
