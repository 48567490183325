import React, { useState } from "react";
import logo from "./Images/martinelli-logo-main.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.scss";
import { LoginModal } from "./Components/LoginModal";
import useLogout from "./Hooks/useLogout.js";

//login geral
const AD_ROUTE = window.location.origin.replace(".", "-api.").replace("3000", "8000") + "/aad/login";

export const Login = () => {
  const [show, setShow] = useState(false);
  const [showConventional, setShowConventional] = useState(0);
  const logout = useLogout();

  const handleSetShow = (value) => {
    setShow(value);
  };

  const handleConventionalLogin = () => {
    setShowConventional((state) => state + 1);
  };

  return (
    <div className="login">
      <Container className="overlay">
        <Row>
          <Col xs={12} className="d-flex justify-content-center px-4 py-5">
            <img
              src={logo}
              alt="Logo Martinelli"
              className="img-fluid"
              onClick={handleConventionalLogin}
            />
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column justify-content-center text"
          >
            <h1 className="title">Portal Martinelli</h1>
            <p>
              Esta é uma área restrita para usuários cadastrados e autorizados.
            </p>
            <p>Informe seu usuário e senha para obter acesso ao sistema.</p>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex flex-column gap-2 p-5 justify-content-center align-items-stretch"
          >
            <Button
              href={AD_ROUTE}
              variant="info"
              onClick={() => {
                logout();
              }}
            >
              Login Corporativo via AD
            </Button>
            {showConventional >= 3 ? (
              <>
                <p className="fw-bold m-0 mx-auto">ou</p>
                <Button
                  onClick={() => {
                    logout();
                    setShow(true);
                  }}
                  variant="outline-info"
                >
                  Login Convencional
                </Button>
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
      <LoginModal
        show={show}
        showActions={(param) => {
          handleSetShow(param);
        }}
      />
    </div>
  );
};
