import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileTable } from "../Components/FileTable";
import { HeadedCard } from "../Components/HeadedCard";
import { InteractionsDivider } from "../Components/InteractionsDivider";
import { PageHeader } from "../Components/PageHeader";
import { ProjectHeader } from "../Components/ProjectHeader";
import { StoryTable } from "../Components/StoryTable";
import { TimesheetTable } from "../Components/TimesheetTable";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { currentUserActions } from "../store/currentUser";

export const ProjetosEdit = ({ mode = "consultant", HISTORICO_ID, TIMESHEET_ID, EVIDENCIAS_ID, TEMP_TIMESHEET_ID, TEMP_PROJETO_ID }) => {
  const projectData = useSelector((state) => state.projectOrTermToEdit.projectOrTermToEdit);
  const selectedCompanyId = useSelector((state) => state.currentUser.selectedCompany.id)
  const [projectFiles, setProjectFiles] = useState([]);
  const [evidencesFiles, setEvidencesFiles] = useState([]);
  const [timesheetFile, setTimesheetFile] = useState([]);
  const [count, setCount] = useState(0);
  const axiosBearer = useAxiosBearer();
  const dispatch = useDispatch();

  const handleRefresh = () => {
    //increases by 1 when a file is uploaded, forcing the rerender of the component
    setCount((state) => state + 1);
    //console.log(count);
  };

  useEffect(() => {
    const request = async () => {    
      const searchCompany = await axiosBearer.get(`companies/${selectedCompanyId}/`);
      const projectsCompany = searchCompany.data

      const response = await axiosBearer.get("companies/documents/", {
        params: {
          company: projectsCompany.id,
          document_type: HISTORICO_ID,
          project: projectData.id,
        },
      });
      const responseEvidences = await axiosBearer.get("companies/documents/", {
        params: {
          company: projectsCompany.id,
          document_type: EVIDENCIAS_ID,
          project: projectData.id,
        },
      });
      const responseTimesheet = await axiosBearer.get("companies/documents/", {
        params: {
          company: projectsCompany.id,
          document_type: TIMESHEET_ID,
          project: projectData.id,
        },
      });

      setProjectFiles(response.data);
      setEvidencesFiles(responseEvidences.data);
      if (responseTimesheet.data.length === 0) setTimesheetFile(null);
      else setTimesheetFile(responseTimesheet.data[0]);
      dispatch(currentUserActions.setSelectedCompany(projectsCompany));
    };
    request();
  }, [axiosBearer, projectData, dispatch, count]);

  return (
    <>
      <PageHeader title="Projetos e evidências">
        <ProjectHeader data={projectData} />
      </PageHeader>
      <InteractionsDivider download documentID={TEMP_PROJETO_ID} segment={ projectData.segment} />
      <HeadedCard title="Histórico do projeto">
        <StoryTable filesArray={projectFiles} refresh={handleRefresh} />
      </HeadedCard>
      {mode === "client" && (
        <InteractionsDivider
          upload
          refresh={handleRefresh}
          tableDocumentID={EVIDENCIAS_ID}
          mode={"project"}
        />
      )}
      <HeadedCard title="Fotos e evidências">
        <FileTable filesArray={evidencesFiles} refreshTable={handleRefresh} />
      </HeadedCard>
      <InteractionsDivider download documentID={TEMP_TIMESHEET_ID} />
      <HeadedCard title="Timesheet">
        <TimesheetTable file={timesheetFile} refreshTable={handleRefresh} />
      </HeadedCard>
    </>
  );
};
