import React, { useEffect, useState } from "react";
import "../StepProgressBar.scss";
import { Icon } from "./Icon";

export const StepProgressBar = ({ editMode = false, stepProgressData = [], step = 0, setStep }) => {
  const [tasks, setTasks] = useState(stepProgressData);
  const [started, setStarted] = useState(false)

  const edit = (indexToEdit) => {
    //base function to edit mode
    const taskIsCompleted = tasks[indexToEdit].completed;

    if(taskIsCompleted){
      if(indexToEdit !== 0)
        setStep(indexToEdit-1)
      //else
      //  setStep(1000)
    }else{
      setStep(indexToEdit)
    }

    const newState = tasks.map((obj, mapIndex) => {
      if (taskIsCompleted) {
        if (mapIndex === 0 && indexToEdit === 0)
          //left = null in the first element of the array
          //return { ...obj, completed: false, right: false }; ==========> check project specifications
          return {...obj, completed: true, right: false}

        if (mapIndex === tasks.length - 1)
          //right = null in the last element of the array
          return { ...obj, left: false, completed: false };

        if (mapIndex >= indexToEdit)
          return { ...obj, left: false, right: false, completed: false };

        if (mapIndex === indexToEdit - 1) return { ...obj, right: false };
      }

      if (mapIndex === 0 && indexToEdit === 0)
        return { ...obj, completed: true };

      if (mapIndex === 0 && indexToEdit !== 0)
        return { ...obj, completed: true, right: true };

      if (mapIndex <= indexToEdit - 1 && indexToEdit !== 0)
        return { ...obj, left: true, right: true, completed: true };

      if (mapIndex === indexToEdit)
        return { ...obj, left: true, completed: true };

      return obj;
    });
    setTasks(newState);
  };

  useEffect(()=>{
    if(!started && tasks?.length > 0/* && step !== 1000*/){
      edit(step)
      setStarted(true)
    }
  },[tasks])

  useEffect(()=>{
    if(stepProgressData?.length > 0)
      setTasks(stepProgressData)
  },[stepProgressData])

  return (
    <div className="task-container" style={{ "--amount": tasks.length }}>
      {tasks.length === 0 && <div></div>}
      {tasks.length > 0 && tasks.map((task, index) => {
        return (
          <div key={index} className="task">
            <div className="task-up">
              <div
                className={`task-line ${task.left ? "" : "unchecked"} ${
                  task.left === "null" ? "inactive" : ""
                }`}
              ></div>
              <div
                onClick={() => {
                  return editMode ? edit(index) : null;
                }}
                className={`task-icon ${
                  task.completed ? "" : "unchecked"
                } d-flex justify-content-center align-items-center`}
              >
                {task.completed && <Icon icon={"Check"} />}
              </div>
              <div
                className={`task-line ${task.right ? "" : "unchecked"} ${
                  task.right === "null" ? "inactive" : ""
                }`}
              ></div>
            </div>
            <div className={`task-down ${task.completed ? "finished" : ""}`}>
              {task.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};
