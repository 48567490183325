import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import api from "../api/Api";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { authActions } from "../store/auth-slice";
import { currentUserActions } from "../store/currentUser";
import { validateEmail } from "./Validations";
import Loading from "./Loading/Loading";
import CustomAlert from "./CustomAlert/CustomAlert";
import useCheckTerms from "../Hooks/useCheckTerms";

export const LoginModal = ({ show, showActions }) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const checkTerms = useCheckTerms()

	const requestLogin = async () => {
    let res;
    const userData = {
      username: emailRef.current.value.replace("@a", ""),
      password: passwordRef.current.value,
    };

    try {
      res = await api.post("login/", userData);
      dispatch(
        authActions.setUser({
          access: res.data.access,
          refresh: res.data.refresh,
          userID: jwt_decode(res.data.access).user_id
        })
      );
    } catch (error) {
      //console.log(!error?.response);
      setLoading(false);
      setShowAlert(true);      
      setAlertMessage("Usuário ou senha incorretos");   
			setEmail("")   
    }

    if (!res?.data) {

      return null;
    }
    return [jwt_decode(res.data.access).role, jwt_decode(res.data.access).user_id];

  };

  const submitHandler = async () => {
    let role;
    let ID;
    showActions(false)

    setLoading(true);
    if (!(emailRef.current?.value && passwordRef.current?.value)) {
      //console.log("não autorizado");
      setLoading(false);
      setShowAlert(true);
      setAlertMessage("Preencha todos os campos");
      return;
    }
    [role, ID] = await requestLogin();

    if (!role) {
      //console.log("não autorizado");
      setLoading(false);
      setShowAlert(true);
      setAlertMessage("Usuário ou senha inválidos");
      return;
    }
    
    dispatch(currentUserActions.setRole(role));
    
    /*axiosBearer.get(`users/${ID}/`).then((res)=>{
      dispatch(
        currentUserActions.setCurrentUserCompanies(res.data.user_companies)
      );
    }).catch((err)=>{
      setLoading(false);
      setShowAlert(true);
      setAlertMessage("Erro na obtenção de dados");
    })*/

    setLoading(false);

    if (role === "admin") {
      navigate("/admin/Empresas", { replace: true });
    } else if (role === "client") {
      checkTerms("/cliente/Empresas")
      //navigate("/cliente/Empresas", { replace: true });
    } else if (role === "consultant") {
      navigate("/consultor/Empresas", { replace: true });
    } else {
      //console.log("não autorizado");
    }
  };

  return (
    <>
      <CustomAlert
        type={"error"}
        visible={showAlert}
        message={alertMessage}
        onAccept={() => setShowAlert(false)}
      />
      <Loading visible={loading} />
      <Modal
        show={show}
        onHide={() => {
          showActions(false);
        }}
				centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Login Convencional</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="form">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fw-bold">Usuário</Form.Label>
              <Form.Control
                type="text"
                placeholder="Usuário"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={email.length > 0 ? !validateEmail(email) : false}
                isValid={validateEmail(email)}
              />
              <Form.Control.Feedback type="invalid">
                O e-mail é inválido
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label className="fw-bold">Senha</Form.Label>
              <Form.Control
                aria-label="Senha para acessar o sistema"
                type="password"
                placeholder="Senha"
                ref={passwordRef}
              />
            </Form.Group>
				</Form>
        </Modal.Body>
        <Modal.Footer>
					<Button variant="primary" onClick={submitHandler}>
              Entrar
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
