export const adminNavs = [
    /*{
      content: "Dashboard",
      icon: "SquaresPlus",
      alternativePath: "DashboardAdmin",
    },*/

    { content: "Empresas", icon: "UserGroup" },
    { content: "Consultores", icon: "UserCircle" },
    { content: "Templates", icon: "DocumentText" },
    { content: "Descrições", icon: "BookOpen" },
    { content: "Termos", icon: "DocumentCheck" },
];

export const clienteNavs = [
    { content: "Empresas", icon: "UserGroup" },
    { content: "Dashboard", icon: "SquaresPlus" },
    { content: "Identificação da empresa", icon: "UserGroup" },
    { content: "Recursos Humanos", icon: "User", permission: "perm_hr" },
    {
        content: "Serviços de Terceiros",
        icon: "Truck",
        permission: "perm_third_party",
    },
    {
        content: "Materiais para testes",
        icon: "Swatch",
        permission: "perm_test_materials",
    },
    { content: "Viagens", icon: "MapPin", permission: "perm_travel_expenses" },
    {
        content: "Treinamentos",
        icon: "AcademicCap",
        permission: "perm_training_expenses",
    },
    {
        content: "Folha de Pagamento",
        icon: "CurrencyDollar",
        permission: "perm_payroll",
    },
    {
        content: "Docs. contábeis",
        icon: "Calculator",
        permission: "perm_accounting_materials",
    },
    {
        content: "Docs. complementares",
        icon: "BookOpen",
        permission: "perm_complementary_docs",
    },
    {
        content: "Projetos e evidências",
        icon: "Folder",
        permission: "perm_projects_evidences",
    },
    {
        content: "Memória de cálculo",
        icon: "Variable",
        permission: "perm_calculation_memory",
    },
    { content: "Dossiê", icon: "DocumentChartBar", permission: "perm_dossie" },
    {
        content: "Hist. Parecer Técnico",
        icon: "DocumentChartBar",
        permission: "perm_technical_opinion",
    },
    {
        content: "Hist. Relatório Auditoria",
        icon: "DocumentChartBar",
        permission: "perm_audit",
    },
    { content: "Hist. Forms", icon: "TableCells", permission: "perm_forms" },
    {
        content: "Obrigações Acessórias",
        icon: "Scale",
        permission: "perm_obligations",
    },
];

export const consultorNavs = [
    /*{
      content: "Dashboard",
      icon: "SquaresPlus",
      alternativePath: "DashboardConsultor",
    },*/
    { content: "Empresas", icon: "UserGroup" },
    { content: "Dashboard", icon: "SquaresPlus" },
    { content: "Identificação da empresa", icon: "UserGroup" },
    { content: "Recursos Humanos", icon: "User" },
    { content: "Serviços de Terceiros", icon: "Truck" },
    { content: "Materiais para testes", icon: "Swatch" },
    { content: "Viagens", icon: "MapPin" },
    { content: "Treinamentos", icon: "AcademicCap" },
    { content: "Folha de Pagamento", icon: "CurrencyDollar" },
    { content: "Docs. contábeis", icon: "Calculator" },
    { content: "Docs. complementares", icon: "BookOpen" },
    { content: "Projetos e evidências", icon: "Folder" },
    { content: "Memória de cálculo", icon: "Variable" },
    { content: "Dossiê", icon: "DocumentChartBar" },
    { content: "Hist. Parecer Técnico", icon: "DocumentChartBar" },
    {
        content: "Hist. Relatório Auditoria",
        icon: "DocumentChartBar",
    },
    { content: "Hist. Forms", icon: "TableCells" },
    { content: "Obrigações Acessórias", icon: "Scale" },
];

export const ufOptions = [
    "AC",
    "AL",
    "AM",
    "AP",
    "BA",
    "CE",
    "ES",
    "GO",
    "MA",
    "MG",
    "MS",
    "MT",
    "PA",
    "PB",
    "PE",
    "PI",
    "PR",
    "RJ",
    "RN",
    "RO",
    "RR",
    "RS",
    "SC",
    "SE",
    "SP",
    "TO",
];

export const permissionAreas = [
    {
        areaName: "Template - Recursos humanos",
        permissionName: "perm_hr",
    },
    {
        areaName: "Template - Serviços de terceiros",
        permissionName: "perm_third_party",
    },
    {
        areaName: "Template - Materiais utilizados em testes",
        permissionName: "perm_test_materials",
    },
    {
        areaName: "Template - Despesas com viagens",
        permissionName: "perm_travel_expenses",
    },
    {
        areaName: "Template - Despesas com treinamentos",
        permissionName: "perm_training_expenses",
    },
    {
        areaName: "Folha de Pagamento",
        permissionName: "perm_payroll",
    },
    {
        areaName: "Documentos contábeis",
        permissionName: "perm_accounting_materials",
    },
    {
        areaName: "Documentos complementares",
        permissionName: "perm_complementary_docs",
    },
    {
        areaName: "Projetos e evidências",
        permissionName: "perm_projects_evidences",
    },
    {
        areaName: "Memória de cálculo do benefício",
        permissionName: "perm_calculation_memory",
    },
    {
        areaName: "Dossiê",
        permissionName: "perm_dossie",
    },
    {
        areaName: "Hist. Parecer Técnico",
        permissionName: "perm_technical_opinion",
    },
    {
        areaName: "Hist. Relatório Auditoria",
        permissionName: "perm_audit",
    },
    {
        areaName: "Hist. Forms",
        permissionName: "perm_forms",
    },
    {
        areaName: "Obrigações Acessórias",
        permissionName: "perm_obligations",
    },
];

export const yearToSelect = ["2019", "2020", "2021", "2022", "2023", "2024"];

export const tabs = [
    "Recursos Humanos",
    'Identificação da empresa',
    "Serviços de Terceiros",
    "Materiais para testes",
    "Viagens",
    "Treinamentos",
    "Folha de Pagamento",
    "Docs. contábeis",
    "Docs. complementares",
    "Projetos e evidências",
    "Memória de cálculo",
    "Dossiê",
    "Hist. Parecer Técnico",
    "Hist. Relatório Auditoria",
    "Hist. Forms",
    "Obrigações Acessórias",
];

export const segmentSelect = [
    "Alimentos",
    "Bens de Consumo",
    "Celulose",
    "Construção Cívil",
    "Eletroeletrônica",
    "Farmacêutica",
    "Finança",
    "Mecânica",
    "Metalurgia",
    "Mineração",
    "Moveleira",
    "Papel",
    "Petroquímica",
    "Química",
    "Seguro",
    "Software",
    "Telecomunicações",
    "Têxtil",
    "Transporte",
    "TIC",
    "TIC - Software",
    "TIC - Telecomunicações",
    "Outros",
];


export const situacaoOptions = [
    'Em operação',
    'Fusão ou Cisão total',
    'Incorporação de/por outra empresa',
    'Cisão parcial',
    'Alteração de CNPJ por motivos distintos dos anteriores',
]

export const relacaoGrupo = [
    'Controladora',
    'Controlada',
    'Coligada',
    'Independente',
]

export const organismoOptions = [
    'Privado',
    'Público',
    'Misto',
]

export const incentivosLei = [
    {value: true, label: "Sim"},
    {value: false, label: "Não"}
]

export const capitalControlador = [
    'Nacional',
    'Estrangeiro'
]