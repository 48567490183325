import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientToEdit: {},
  allGroups: {},
  ocrs: {}
};

const clientToEditSlice = createSlice({
  name: "clientToEdit",
  initialState,
  reducers: {
    setClientToEdit(state, action) {
      state.clientToEdit = action.payload;
    },
    setAllGroups(state, action) {
      state.allGroups = action.payload
    },
    setAllOcrs(state, action) {
      state.ocrs = action.payload
    },
    resetData(state){
      state.clientToEdit = {}
      state.allGroups = {}
      state.ocrs = {}
    }
  },
});

export const clientToEditActions = clientToEditSlice.actions;

export default clientToEditSlice;
