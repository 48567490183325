import React from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { CustomBadge } from "./CustomBadge.js";
import { useDispatch } from "react-redux";
import { userToEditActions } from "../store/user-to-edit-slice";

export const UserTable = ({ editButtonCallback, userData = [] }) => {
  const dispatch = useDispatch();
  const status = (c) => {
    return c.isActive ? "success" : "inactive";
  };

  return (
    <Table className="align-middle" responsive>
      <thead>
        <tr>
          <th>Nome</th>
          <th className="text-center">E-mail</th>
          <th className="text-center">Ativo</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {userData?.map((user) => {
          return (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td className="text-center">{user.email}</td>
              <td className="text-center">
                <CustomBadge type={status(user)} />
              </td>
              <td>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => {
                      dispatch(userToEditActions.setUserToEdit(user));
                      dispatch(userToEditActions.setCheckAll(false))
                      editButtonCallback();
                    }}
                  >
                    Editar
                  </Button>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
