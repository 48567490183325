import React, { useEffect, useRef, useState } from "react";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import useAxiosBearer from "../Hooks/useAxiosBearer";

export const AddGroupPopover = ({
  show,
  target,
  overlayRef,
  sendData = null,
}) => {
  const groupNameRef = useRef();
  const axiosBearer = useAxiosBearer();
  const [validSize, setValidSize] = useState(null);
  const [groupExists, setGroupExist] = useState(null);
  const [groups, setGroups] = useState([]);

  const handleSubmit = (e) => {
    const groupName = groupNameRef.current.value;
    e.preventDefault();

    if (!validSize) {
      return;
    }

    axiosBearer.post("companygroups/", {
      name: groupName,
    });
    
    sendData();
  };

  const handleNameChange = () => {
    const enteredGroupName = groupNameRef.current.value;
    if (enteredGroupName.length === 0) {
      setValidSize(false);
    } else {
      setValidSize(true);
    }

    const exists = groups.find((el) => {
      return el.name === enteredGroupName;
    });
    setGroupExist(!!exists);
  };

  useEffect(() => {
    const request = async () => {
      const response = await axiosBearer.get("companygroups/");
      const responseGroups = response.data.map((companyGroup) => {
        return {
          id: companyGroup.id,
          name: companyGroup.name,
        };
      });
      setGroups(responseGroups);
    };
    request();
  }, [axiosBearer, show]);

  //Reset Popover initial state when show changes
  useEffect(() => {
    setValidSize(null);
    setGroupExist(null);
  }, [show]);

  return (
    <>
      <Overlay
        show={show}
        target={target}
        placement="right"
        container={overlayRef}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-2">
                <Form.Control
                  required
                  type="text"
                  ref={groupNameRef}
                  placeholder="Digite o nome"
                  onChange={handleNameChange}
                  isInvalid={
                    validSize === null ? null : !validSize || groupExists
                  }
                  isValid={
                    validSize === null ? null : validSize || !groupExists
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {!validSize && "Digite o nome da empresa."}
                  {groupExists && "Este grupo de clientes já existe."}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit">Adicionar</Button>
              <p className="mt-2 text-sm">
                Criação do grupo serve para matriz e filial e para empresas com
                CNPJ's distintos
              </p>
            </Form>
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};
