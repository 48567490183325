import { Outlet } from "react-router-dom";
import { Version } from "./Components/Version/Version";

const Layout = () => {
  return (
    <main>
      <Outlet />
      <Version/>
    </main>
  );
};

export default Layout;
