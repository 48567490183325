import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { UserTable } from "../Components/UserTable";
import { HeadedCard } from "../Components/HeadedCard";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading/Loading";
import { PaginationComponent } from "../Components/PaginationComponent";
import useBackPagination from "../Hooks/useBackPagination";
import { SearchBar } from "../Components/SearchBar";

const CONSULTORS_PER_PAGE = 5;

export const ConsultoresList = () => {
  const navigate = useNavigate();

  const [consultorToSearch, setConsultorToSearch] = useState("");
  const [forcePaginationPageChange, setForcePaginationPageChange] =
    useState(false);
  const [itemsToShow, setCurrentPage, loading, amountOfItems] =
    useBackPagination({
      path: "consultors/",
      objectFields: ["id", "name", "email", "isActive", "user_companies"],
      initialPage: 1,
      searchedName: consultorToSearch,
      amountPerPage: CONSULTORS_PER_PAGE,
      reqParams: null,
    }, "ConsultoresList");

  const getConsultorToSearch = (value) => {
    setConsultorToSearch(value);
    setCurrentPage(1);
    setForcePaginationPageChange((prev) => !prev);
  };

  const goToEditPage = () => {
    navigate("edit");
  };
  const goToAddPage = () => {
    navigate("add");
  };

  return (
    <>
      <Loading visible={loading} />
      <div className="d-flex justify-content-end">
        <Button className="px-3" onClick={goToAddPage}>
          Adicionar
        </Button>
      </div>
      <div>
        <SearchBar
          getInput={(v) => getConsultorToSearch(v)}
          placeholder="Pesquisar consultor"
        />
        <HeadedCard title="Usuários">
          <UserTable
            editButtonCallback={goToEditPage}
            userData={itemsToShow}
            mode="consultant"
          />
        </HeadedCard>
      </div>
      {amountOfItems > CONSULTORS_PER_PAGE && (
        <PaginationComponent
          size={amountOfItems}
          itemsPerPage={CONSULTORS_PER_PAGE}
          pageChanger={(page) => {
            setCurrentPage(page);
          }}
          forcePageChangeFromOutside={forcePaginationPageChange}
        />
      )}
    </>
  );
};
