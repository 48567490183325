import React from "react";
import { PageHeader } from "../Components/PageHeader";
import { YearSelectorContainer } from "../Components/YearSelectorContainer";
import { HeadedCard } from "../Components/HeadedCard";
import { FormularioAtualizacao } from "./FormularioAtualizacao";

const PAGE_TITLE = "Identificação Empresa"

export const IdentificacaoEmpresa = ({ mode = "client" }) => {

  return (
    <>
      <PageHeader title={PAGE_TITLE}>
        Formulário anual de atualização de dados da empresa
      </PageHeader>
      <FormularioAtualizacao />
    </>
  );
};
