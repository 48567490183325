import React, { useRef, useState } from "react";
import { ufOptions } from "../NavLists";
import { HeadedCard } from "../Components/HeadedCard";
import { SaveAndDelete } from "../Components/SaveAndDelete";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { useSelector } from "react-redux";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading/Loading";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import { IMaskInput } from "react-imask";
import { validateCNPJ, validateDate, validateMultipleEmail } from "../Components/Validations";
import { BenefitAccess } from "../Components/BenefitAccess";

export const ClientesAdminEdit = ({ add = false }) => {
  const navigate = useNavigate();
  const clientInfo = useSelector((state) => {
    return add ? {} : state.clientToEdit.clientToEdit;
  });
  const groupsToRender = useSelector((state) => {
    return state.clientToEdit?.allGroups;
  });
  const ocrsToRender = useSelector((state) => {
    return state.clientToEdit?.ocrs;
  });
  const [validated, setValidated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [alertType, setAlertType] = useState("ok");
  const [alertMessage, setAlertMessage] = useState("");
  const [allowValidation, setAllowValidation] = useState(false)

  const [cnpjValue, setCNPJValue] = useState(clientInfo?.cnpj || "");
  const [companyName, setCompanyName] = useState(clientInfo?.name || "");
  const [updateRevision, setUpdateRevision] = useState(clientInfo?.date_revision || "");


  const axiosBearer = useAxiosBearer();

  const formRef = useRef();

  const nameRef = useRef();
  const ufRef = useRef();
  const cnpjRef = useRef();
  const obsRef = useRef();
  const groupRef = useRef();
  const principalRef = useRef();
  const emailMartinelliRef = useRef();
  const emailClienteRef = useRef();
  const checkboxRef = useRef();
  const ocrRef = useRef();
  const updateRevisionRef = useRef();


  const verifyChecks = () => {
    const checkboxOptions = Array.from(checkboxRef.current);
    const notFilteredAvailableBenefits = checkboxOptions.map((item, index) => {
      if (item.checked) return index + 1;
      return null;
    });
    const filteredAvailableBenefits = notFilteredAvailableBenefits.filter(
      (item) => {
        return item !== null;
      }
    );
    return filteredAvailableBenefits;
    //Retorna os benefícios formatados ex: [1, 3]
  };

  const confirmGoBack = () => {
    //console.log(validated);
    if (alertType !== "error") {
      setAlert(false);
      navigate(-1);
    } else {
      setAlert(false);
    }
  };

  //create save and delete callbacks to pass to SaveAndDelete component
  const onSave = () => {
    //if (validated) console.log("salvou");
    setAllowValidation(true)
    const availableBenefits = verifyChecks();

    //console.log(emailClienteRef.current.value)
    //console.log(emailMartinelliRef.current.value)

    if (formRef.current.checkValidity() === false || availableBenefits.length === 0) {
      setValidated(false);
      setLoading(false);
      setAlert(true);
      setAlertType("error");
      setAlertMessage("Preencha os campos antes de continuar");
    } else {
      setLoading(true);
      let groupID;
      let groupToPatch;
      let ocrID;
      let ocrToPatch;


      if (groupRef.current.value === "Sem grupo") {
        groupID = null;
      } else {
        groupToPatch = groupsToRender.find((group) => {
          return group.name === groupRef.current.value;
        });
        groupID = groupToPatch.id;
      }

      if (ocrRef.current.value === "Selecione...") {
        ocrID = null;
      } else {
        ocrToPatch = ocrsToRender.find((ocr) => {
          return ocr.name === ocrRef.current.value;
        });
        ocrID = ocrToPatch.id;
      }

      const clientData = {
        name: companyName,
        cnpj: cnpjValue.replaceAll(/([./-])/g, ""),
        uf: ufRef.current.value,
        city: "cidade",
        martinelli_emails: emailMartinelliRef.current.value,
        company_emails: emailClienteRef.current.value,
        obs: obsRef.current.value,
        parent_company: principalRef.current.value === "SIM" ? true : false,
        company_group: groupID,
        benefits: availableBenefits,
        template_ocr: ocrID, //fase 2
        date_revision: updateRevision
      };

      if (add) {
        //console.log("caiu em add");
        axiosBearer
          .post("companies/", clientData)
          .then((res) => {
            setLoading(false);
            if (principalRef.current.value === "SIM") {
              axiosBearer
                .patch(`companygroups/${groupToPatch.id}/`, {
                  main_company: res.data.id,
                })
                .catch((err) => {
                  setAlert(true);
                  setAlertType("error");
                  setAlertMessage("Erro ao cadastrar Empresa");
                });
            }
          })
          .then((res) => {
            setAlert(true);
            setAlertType("ok");
            setAlertMessage("Cliente cadastrado com sucesso");
          })
          .catch((err) => {
            //console.log(Object.values(err.response.data))
            setLoading(false);
            setAlert(true);
            setAlertType("error");
            //setAlertMessage(err.response.data[0]);
            setAlertMessage(Object.values(err.response.data)[0]);
          });
      } else {
        axiosBearer
          .put(`companies/${clientInfo.id}/`, clientData)
          .then((res) => {
            //console.log(clientData);
            setLoading(false);
            if (principalRef.current.value === "SIM" && !clientInfo.principal) {
              axiosBearer
                .patch(`companygroups/${groupToPatch.id}/`, {
                  main_company: res.data.id,
                })
                .catch((err) => {
                  setAlert(true);
                  setAlertType("error");
                  setAlertMessage("Erro ao atualizar Empresa");
                  return;
                });
            } else if (
              principalRef.current.value === "NÃO" &&
              clientInfo.principal
            ) {
              axiosBearer
                .patch(`companygroups/${groupToPatch.id}/`, {
                  main_company: null,
                })
                .catch((err) => {
                  setAlert(true);
                  setAlertType("error");
                  setAlertMessage("Erro ao atualizar Empresa");
                });
            }
          })
          .then((res) => {
            setAlert(true);
            setAlertType("ok");
            setAlertMessage("Cliente atualizado com sucesso");
          })
          .catch((err) => {
            //console.log(Object.values(err.response.data))
            setLoading(false);
            setAlert(true);
            setAlertType("error");
            //setAlertMessage(err.response.data);
            setAlertMessage(Object.values(err.response.data)[0]);
          });
      }
    }
    setValidated(true);
  };

  const onDelete = () => {
    setValidated(true);
    setDeleteAlert(false);
    setLoading(true);
    axiosBearer
      .delete(`companies/${clientInfo.id}/`)
      .then((res) => {
        setLoading(false);
        setDeleteAlert(false);
        setAlert(true);
        setAlertType("ok");
        setAlertMessage("Cliente excluído com sucesso");
      })
      .catch((err) => {
        setLoading(false);
        setDeleteAlert(false);
        setAlert(true);
        setAlertType("error");
        setAlertMessage(err.response.data.detail);
      });
  };

  return (
    <>
      <Loading visible={loading} />
      <CustomAlert
        visible={alert}
        type={alertType}
        message={alertMessage}
        onAccept={confirmGoBack}
      />
      <CustomAlert
        visible={deleteAlert}
        type="prompt"
        message="Deseja realmente excluir esta Empresa?"
        onAccept={onDelete}
        onRefuse={() => setDeleteAlert(false)}
      />

      <Form noValidate validated={validated} ref={formRef}>
        <Stack gap={5}>
          <HeadedCard title="Informações cadastrais">
            <Stack gap={4} className="p-3">
              <Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Razão Social</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Acme Inc. - Matriz"
                    ref={nameRef}
                    pattern=".{4,}"
                    isInvalid={
                      companyName === clientInfo?.name || companyName === "" ? null : companyName.length < 4 ? true : false
                    }
                    isValid={companyName === clientInfo?.name || companyName === "" ? null : companyName.length >= 4 ? true : false}
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback type="invalid">
                    Nome curto demais
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} xs={8} md={8}>
                  <Form.Label>CNPJ</Form.Label>
                  <Form.Control
                    as={IMaskInput}
                    mask="00.000.000/0000-00"
                    required
                    type="text"
                    placeholder="10.000.000/0001-10"
                    ref={cnpjRef}
                    value={cnpjValue}
                    onChange={(e) => setCNPJValue(e.target.value)}
                    isValid={validateCNPJ(cnpjValue)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Insira um CNPJ
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={2}
                  md={2}
                >
                  <Form.Label>UF</Form.Label>
                  <Form.Select
                    aria-label="Seleção de unidade federativa"
                    defaultValue={clientInfo?.uf}
                    ref={ufRef}
                  >
                    {ufOptions.map((op, index) => {
                      return <option key={index}>{op}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} xs={2} md={2}>
                  <Form.Label>Atualização</Form.Label>
                  <Form.Control
                    as={IMaskInput}
                    mask="00/00"
                    required
                    type="text"
                    placeholder="01/01"
                    ref={updateRevisionRef}
                    value={updateRevision}
                    onChange={(e) => setUpdateRevision(e.target.value)}
                    isValid={validateDate(updateRevision)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Insira a data de revisão
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Obs.</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="observações"
                    defaultValue={clientInfo?.obs}
                    ref={obsRef}
                    autoComplete="off"
                  />
                </Form.Group>
              </Row>
            </Stack>
          </HeadedCard>
          <HeadedCard title="Grupo">
            <Row className="p-3">
              <Form.Group
                as={Col}
                sm={6}
                md={7}
                lg={9}
                xxl={10}
                className="mb-2"
              >
                <Form.Label>Pertence ao grupo:</Form.Label>
                <Form.Select
                  aria-label="Atribuição de grupo ao cliente"
                  defaultValue={
                    clientInfo.group ? clientInfo.group : "Sem grupo"
                  }
                  ref={groupRef}
                >
                  <option>Sem grupo</option>
                  {groupsToRender.map((options, index) => {
                    return <option key={index}>{options.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} sm={6} md={5} lg={3} xxl={2}>
                <Form.Label>Principal do grupo?</Form.Label>
                <Form.Select
                  aria-label="O cliente é a principal do grupo"
                  defaultValue={clientInfo?.principal ? "SIM" : "NÃO"}
                  ref={principalRef}
                >
                  <option>SIM</option>
                  <option>NÃO</option>
                </Form.Select>
              </Form.Group>
            </Row>
          </HeadedCard>
          <HeadedCard title="Benefícios">
            <BenefitAccess
              checkboxRef={checkboxRef}
              companyBenefits={clientInfo?.benefits}
              allowValidation={allowValidation}
            />
          </HeadedCard>
          <HeadedCard title="Notificações">
            <Row className="p-3">
              <Form.Group as={Col} md={6}>
                <Form.Label>E-mails Martinelli</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="fulano@martinelli.adv.br; outro@email.com"
                  defaultValue={clientInfo?.emailsMartinelli}
                  ref={emailMartinelliRef}
                  pattern="((?!\W)([&\w]+)(([-\._&\w])*)@{1}([\w]+)\.([\w]+)(([\.\w]+)))((;(\s)*(((?!\W)([&\w]+)(([-\._&\w])*)@{1}([\w]+)\.([\w]+)(([\.\w]+)))))*)"
                  required
                />
                <Form.Text className="text-muted">
                  Responsáveis Martinelli a serem notificados para este cliente.
                </Form.Text>
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Label>E-mails cliente</Form.Label>
                <Form.Control
                  type="text"
                  pattern="((?!\W)([&\w]+)(([-\._&\w])*)@{1}([\w]+)\.([\w]+)(([\.\w]+)))((;(\s)*(((?!\W)([&\w]+)(([-\._&\w])*)@{1}([\w]+)\.([\w]+)(([\.\w]+)))))*)"
                  placeholder="fulano@cliente.com.br; outro@email.com"
                  defaultValue={clientInfo?.emailsCliente}
                  ref={emailClienteRef}
                  required
                />
                <Form.Text className="text-muted">
                  Responsáveis do cliente a serem notificados.
                </Form.Text>
              </Form.Group>
            </Row>
          </HeadedCard>
          <HeadedCard title="Template Folha de Pagamento">
            <Row className="p-3">
              <Form.Group as={Col} md={10}>
                <Form.Label>Template para extração:</Form.Label>
                <Form.Select
                  aria-label="Atribuição de grupo ao cliente"
                  defaultValue={
                    clientInfo.template_ocr ? clientInfo.template_ocr : ""
                  }
                  ref={ocrRef}
                >
                  <option>Selecione...</option>
                  {ocrsToRender.map((options, index) => {
                    return <option key={index}>{options.name}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
          </HeadedCard>
        </Stack>
      </Form>
      <SaveAndDelete
        saveCallback={onSave}
        deleteCallback={() => setDeleteAlert(true)}
        addMode={add}
      />
    </>
  );
};
