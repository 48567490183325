import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeadedCard } from "../Components/HeadedCard";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import Loading from "../Components/Loading/Loading";
import CustomAlert from "../Components/CustomAlert/CustomAlert";
import useAxiosBearer from "../Hooks/useAxiosBearer";

export const TermsView = ({ add = false }) => {
  const termInfo = useSelector((state) => {
    return add ? {} : state.projectOrTermToEdit.projectOrTermToEdit;
  });
  const [title, setTitle] = useState(termInfo?.title || "");
  const [version, setVersion] = useState(termInfo?.version || "");
  const [termText, setTermText] = useState(termInfo?.text || "");
  const axiosBearer = useAxiosBearer();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("ok");
  const [allowValidation, setAllowValidation] = useState(false)

  const confirmGoBack = () => {
    if (alertType !== "error") {
      setAlert(false);
      navigate(-1);
    } else {
      setAlert(false);
    }
  };

  const checkValidation = () => {
    const titleValid = title.length > 0;
    const versionValid = version.length > 0;
    const textValid = termText.length > 0;

    if (titleValid && versionValid && textValid) return true;
    else return false;
  };

  const handleAlerts = (message, type) => {
    setAlert(true);
    setAlertMessage(message);
    setAlertType(type);
    setLoading(false);
  };

  const onSave = () => {
    setLoading(true);
    setAllowValidation(true)
    if (checkValidation() === false) {
      handleAlerts("Preencha todos os campos", "error");
    }else{
      const termData = {
        title: title,
        text: termText,
        version: version
      }

      axiosBearer.post("terms/", termData).then((res)=>{
        handleAlerts("Termos de Uso e Privacidade criado com sucesso", "ok")
      }).catch((err)=>{
        handleAlerts("Essa versão já existe", "error")
      })
    }
  };

  useEffect(()=>{
    if(add){
      const getLatestTerm = () => {
        axiosBearer.get("terms/latest/").then((res)=>{
          setVersion((Number(res.data.version)+1).toString())
        }).catch((err)=>{
          handleAlerts("Erro no carregamento de dados, por favor recarregue a página", "error")
        })
      }
      getLatestTerm()
    }
  },[])

  return (
    <>
      <Loading visible={loading} />
      <CustomAlert visible={alert} type={alertType} message={alertMessage} onAccept={confirmGoBack}/>
      <HeadedCard title="Dados">
        <Form>
          <Row>
            <Form.Group as={Col} className="mb-2" sm="12" md="6">
              <Form.Label className="m-0">Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Título do termo"
                readOnly={!add}
                value={title}
                isValid={allowValidation && title.length > 0}
                isInvalid={allowValidation && title.length === 0}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group as={Col} className="mb-2" sm="12" md="6">
              <Form.Label className="m-0">Versão</Form.Label>
              <Form.Control
                type="text"
                placeholder="Versão"
                readOnly
                value={version}
                isValid={allowValidation && version.length > 0}
                isInvalid={allowValidation && version.length === 0}
                onChange={(e) => setVersion(e.target.value)}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group>
              <Form.Label>Texto</Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                placeholder="Enter textarea"
                readOnly={!add}
                value={termText}
                isValid={allowValidation && termText.length > 0}
                isInvalid={allowValidation && termText.length === 0}
                onChange={(e) => setTermText(e.target.value)}
              />
            </Form.Group>
          </Row>
        </Form>
      </HeadedCard>
      {add && (
        <div className="d-flex justify-content-between">
          <Button variant="outline-danger" onClick={()=>{navigate(-1)}}>Cancelar</Button>
          <Button onClick={onSave}>Salvar</Button>
        </div>
      )}
    </>
  );
};
