import React from "react";
import Card from "react-bootstrap/Card";

export const HeadedCard = ({ title, children, hasFooter, footerContent }) => {
  return (
    <Card>
      <Card.Header>{title}</Card.Header>
      <Card.Body>{children}</Card.Body>
      {hasFooter && <Card.Footer>{footerContent}</Card.Footer>}
    </Card>
  );
};
