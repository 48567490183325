import React from "react";
import Form from "react-bootstrap/Form";

export const Selector = ({ options, label, setCurrentBenefit }) => {
  const handleChange = (e) => {
    const selected = options.find((opt) => opt.name === e.target.value);
    setCurrentBenefit(selected);
  };

  return (
    <Form.Select aria-label={label} onChange={handleChange}>
      {options.map((opt) => (
        <option key={opt.id}>{opt.name}</option>
      ))}
    </Form.Select>
  );
};
