import {useState} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { validateEmail } from "./Validations";

export const UserInfo = ({ formRef, contentRef, validatedForm, userData, addMode = false, consultant = false, controllerStateFunction}) => {
  
  const [name, setName] = useState(userData?.name || "");
  const [email, setEmail] = useState(userData?.email || "");

  return (
    <Form className="p-3" ref={formRef} validated={validatedForm}>
      <Stack gap={4}>
        <Row>
          <Form.Group as={Col} md="12">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Fulano da Silva"
              //defaultValue={userData?.name}
              ref={contentRef.name}
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={name.length > 0 ? name.length < 4 : false }   
              isValid={name.length > 4}           
            />
            <Form.Control.Feedback type="invalid">
                O nome é curto demais
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group as={Col} lg={consultant? 6 : 8} xl={6}>
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              required
              readOnly={!addMode}
              type="email"
              pattern="(?!\W)([&\w]+)(([._\-&\w])*)@{1}([\w]+)\.([\w]+)(([.\w]+))"
              placeholder="fulano@martinelli.adv.br"
              //defaultValue={userData?.email}
              ref={contentRef.email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={email.length > 0 ? !validateEmail(email) : false }      
              isValid={validateEmail(email)}        
            />
            <Form.Text className="text-muted">
              Usar endereço @martinelli.adv.br para acesso por AD.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
                O e-mail é inválido
            </Form.Control.Feedback>            
          </Form.Group>
          {!consultant && <Form.Group as={Col} xs={6} lg={2} xl={{ span: 2, offset: 2 }}>
            <Form.Label>Controller</Form.Label>
            <Form.Select
              aria-label="o usuário é controller?"
              defaultValue={userData?.isController ? "SIM" : "NÃO"}
              ref={contentRef.isController}
              onChange={(e)=>{controllerStateFunction(e.target.value)}}
            >
              <option>NÃO</option>
              <option>SIM</option>
            </Form.Select>
          </Form.Group>}
          <Form.Group as={Col} xs={6} lg={consultant?{span:3, offset:3} : 2} xl={consultant? {span:2, offset:4} : 2}>
            <Form.Label>Ativo</Form.Label>
            <Form.Select
              aria-label="Default select example"
              defaultValue={userData?.isActive ? "SIM" : "NÃO"}
              ref={contentRef.isActive}
            >
              <option>SIM</option>
              <option>NÃO</option>
            </Form.Select>
          </Form.Group>
        </Row>
      </Stack>
    </Form>
  );
};
