import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "./Icon";
import useAxiosBearer from "../Hooks/useAxiosBearer";

export const DescriptionCard = ({ descriptionData }) => {
  const axiosBearer = useAxiosBearer()
  const [editing, setEditing] = useState(false);
  const [descriptionText, setDescriptionText] = useState(descriptionData.description);
  const [initialText, setInitialText] = useState("");

  const sendText = (newDescription) => {
    const content = {...descriptionData, description: newDescription}
    axiosBearer.put(`tabs/${content.id}/`, content)
  }

  const handleEdit = () => {
    if (!editing){
      setInitialText(descriptionText);
    }else{
      sendText(descriptionText)
    }
    setEditing((state) => !state);
  }

  return (
    <Card body>
      <Card.Title>{descriptionData.name}</Card.Title>
      {editing ? (
        <Form>
          <Form.Group>
            <Form.Control
              className="mb-3"
              as="textarea"
              rows={5}
              value={descriptionText}
              onChange={(e) => {
                setDescriptionText(e.target.value);
              }}
            />
          </Form.Group>
        </Form>
      ) : (
        <Card.Text>{descriptionText}</Card.Text>
      )}
      <div className="d-flex gap-2">
        <Button
          className="d-flex align-items-center gap-1"
          variant={editing ? "success" : "primary"}
          onClick={handleEdit}
        >
          <Icon icon={"PencilSquare"} />
          {editing ? "Salvar" : "Editar"}
        </Button>
        {editing && (
          <Button
            className="d-flex align-items-center gap-1 cancel-button"
            variant={"outline-danger"}
            onClick={() => {
              setDescriptionText(initialText);
              setEditing(false);
            }}
          >
            <Icon icon={"XMark"} />
            Cancelar
          </Button>
        )}
      </div>
    </Card>
  );
};
