import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { DownloadTableRow } from "./DownloadTableRow";
import { useSelector } from "react-redux";

export const DownloadTable = ({ type, filesArray = [], refreshTable, selectedTrue=false}) => {
  /* type =>
    1 : Memória de Cálculo e Dossiê.
    2 : Hist. Parecer Técnico e Hist. Relatório Auditoria.
    3 : Obrigações Acessórias e Hist. Forms

    * key name could be changed.
  */
  const userRole = useSelector((state) => state.currentUser.role);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleSelectedRow = (fileId) => {
    if (selectedRow === fileId) {
      setSelectedRow(null);
    } else {
      setSelectedRow(fileId);
    }
  };

  return (
    <Table className="align-middle" responsive>
      <thead>
        <tr>
          {type === 1 && (
            <>
              <th className="text-center">Ano-Calendário</th>
              <th className="text-center">Formato</th>
              <th className="text-center">Data atualização</th>
            </>
          )}
          {type === 2 && (
            <>
              <th className="text-center">Ano Base</th>
              <th className="text-center">Nomenclatura</th>
              <th className="text-center">Formato</th>
              <th className="text-center">Data upload</th>
            </>
          )}
          {type === 3 && (
            <>
              <th className="text-center">Ano Base</th>
              <th className="text-center">Nomenclatura</th>
              <th className="text-center">Data upload</th>
            </>
          )}
          <th></th>
          {userRole === "consultant" && <th></th>}
        </tr>
      </thead>
      <tbody>
          {filesArray.map((file) => {
            return (
              <DownloadTableRow key={file.id} file={file} type={type} refreshTable={refreshTable}
              isSelected={selectedRow === file.id} handleSelectedRow={handleSelectedRow} selectedTrue={selectedTrue}/>
            );
          })}
      </tbody>
    </Table>
  );
};
