export const pieShades = [
    'rgba(172, 181, 210, 255)',
    'rgba(130, 145, 190, 255)',
    'rgba(91, 120, 188, 255)',
    'rgba(70, 109, 185, 255)',
    'rgba(64, 98, 166, 255)',
    'rgba(63, 90, 146, 255)',
];

export const consultorMainShade = "rgba(68,114,197,255)";

export const clientMainShade = "rgba(64,109,147,100)";

export const moeda = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const formatDivision = (value, total) => {
  const result = (Number(value) / Number(total)) * 100
  
  if(result % 1 !== 0)
    return `${result.toFixed(2)} %`
  
  return `${result.toFixed()} %`
}