import { useEffect, useState } from "react"
import useAxiosBearer from "./useAxiosBearer"

export const useGetDescription = (area) => {
  const [descriptionText, setDescriptionText] = useState("")
	const axiosBearer = useAxiosBearer()

	useEffect(()=>{
    const request = async () => {
      const response = await axiosBearer.get("tabs/", {params: {name: area}})
			
			setDescriptionText(response.data[0].description)
    }
    request()
  }, [area])

	return descriptionText
}