import { authActions } from "../store/auth-slice"
import { clientToEditActions } from "../store/client-to-edit-slice"
import { currentUserActions } from "../store/currentUser"
import { projectOrTermToEditActions } from "../store/project-to-edit-slice"
import { userToEditActions } from "../store/user-to-edit-slice"
import { useDispatch } from "react-redux"
import { docTypesActions } from "../store/doctypes-slice"

const useLogout = () => {
  const dispatch = useDispatch()

  const resetStoreData = () => {
    dispatch(authActions.resetData())
    dispatch(clientToEditActions.resetData())
    dispatch(currentUserActions.resetData())
    dispatch(projectOrTermToEditActions.resetData())
    dispatch(userToEditActions.resetData())
    dispatch(docTypesActions.resetData())
  }

  return resetStoreData
}

export default useLogout