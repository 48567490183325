import React from "react";
import Card from "react-bootstrap/Card";

export const PageHeader = ({ title, children }) => {
  return (
    <Card>
      <Card.Body className="p-4">
        <Card.Title className="display-5">{title}</Card.Title>
        <div>{children}</div>
      </Card.Body>
    </Card>
  );
};
