import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  access: null,
  refresh: null,
  userID: null
};

const authSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUser(state, action) {
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
      state.userID = action.payload.userID
    },
    updateAccess(state, action) {
      state.access = action.payload;
    },
    resetData(state){
      state.access = null;
      state.refresh = null;
      state.userID = null
    }
  },
});

export const authActions = authSlice.actions;

export default authSlice;
