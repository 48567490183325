import {
  BsFillExclamationCircleFill,
  BsCheckCircleFill,
  BsQuestionOctagonFill,
} from "react-icons/bs";
import Backdrop from "../Backdrop/Backdrop";

import "./CustomAlert.css";

export default function CustomAlert(props) {
  function icon() {
    if (props.type === "error") {
      return (
        <BsFillExclamationCircleFill
          style={{
            color: "#d51c1c",
            fontSize: 235,
            margin: 0,
            marginTop: "10px",
          }}
        ></BsFillExclamationCircleFill>
      );
    } else if (props.type === "ok") {
      return (
        <BsCheckCircleFill
          style={{
            color: "#5dbf40",
            fontSize: 250,
            margin: 0,
            marginTop: "10px",
          }}
        ></BsCheckCircleFill>
      );
    } else if (props.type === "prompt") {
      return (
        <BsQuestionOctagonFill
          style={{
            color: "#5dbf40",
            fontSize: 250,
            margin: 0,
            marginTop: "10px",
          }}
        ></BsQuestionOctagonFill>
      );
    }
    return <div></div>;
  }

  return (
    <Backdrop visible={props.visible}>
      <div className="customAlertDiv">
        {icon()}
        <div className="d-flex flex-column align-items-center gap-1">
          {Array.isArray(props.message) ? (
            props.message.map((mes, index) => {
							return <p key={index} className="customAlertText">{mes}</p>
						})
          ) : (
            <p className="customAlertText">{props.message}</p>
          )}
        </div>
        {props.type !== "prompt" ? (
          <button
            onClick={props.onAccept}
            className={
              "customAlertBtn " + (props.type !== "error" ? "green" : "red")
            }
          >
            ok
          </button>
        ) : (
          <div className="customAlertButtonDiv">
            <button onClick={props.onAccept} className="customAlertBtn green">
              sim
            </button>
            <button
              onClick={props.onRefuse}
              className="customAlertBtn borderlessRed"
            >
              cancelar
            </button>
          </div>
        )}
      </div>
    </Backdrop>
  );
}
