import React/* , { useEffect, useRef } */ from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { consultorMainShade, clientMainShade, moeda } from "../utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

export const ChartEconomiaTributaria = ({ mode, graphData }) => {
    const options = {
        responsive: true,
        //maintainAspectRatio: false,
        plugins: {
            ChartDataLabels,
            legend: {
                display: false,
                //position: 'top',
            },
            title: {
                display: true,
                text: "Histórico da Economia Tributária",
                padding: {
                    bottom: 30
                }
            },
            tooltip: {
                enabled: false,
            },
        },
        scales: {
            x: {
                ticks: {
                    /* callback: function (value) {
                      return this.getLabelForValue(value)
                    }, */
                    crossAlign: "center",
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
            y: {
                display: false,
            },
        },
    };

    const formatedData = graphData?.tax_savings.map((item) => {
        return {
            label: item.year,
            value: item.value,
        };
    });

    const labels = formatedData?.map((item) => {
        return item.label;
    });

    const data = {
        labels,
        datasets: [
            {
                data: formatedData?.map((item) => {
                    return item.value;
                }),
                backgroundColor: mode === "consultant" ? consultorMainShade : clientMainShade,
                datalabels: {
                    anchor: "end",
                    align: "top",
                    formatter: function (value) {
                        return moeda.format(value);
                    },
                },
            },
        ],
    };

    return formatedData.length > 0 ? <Bar options={options} data={data} /* ref={chartRef} */ /> : null
};
