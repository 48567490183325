export function validateEmail(email) {
  const re = /^(?!\W)([&\w]+)(([._\-&\w])*)@{1}([\w]+)\.([\w]+)(([.\w]+))$/;
  return re.test(email);
}

export function validateMultipleEmail(email) {
  const re = /^((?!\W)([&\w]+)(([._\-&\w])*)@{1}([\w]+)\.([\w]+)(([.\w]+))((;(\s)*(((?!\W)([&\w]+)(([\._\-&\w])*)@{1}([\w]+)\.([\w]+)(([\.\w]+)))))*))$/;
  return re.test(email);
}

// function that validates CNPJ with regex, returning true if valid
export function validateCNPJ(cnpj) {
  const re = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
  return re.test(cnpj);
}

export function validateDate(inputDate) {
  const re = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])$/;
  return re.test(inputDate);
}