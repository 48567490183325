import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { segmentSelect } from "../NavLists";

export const UploadTemplateModal = (props) => {
  const [segmentSelected, setSegmentSelected] = useState("");
  const [validated, setValidated] = useState(null);
  const fileRef = useRef();
  const selectRef = useRef();
  const segmentRef = useRef();
  const templateNames = [
    { name: "Recursos humanos", documentTypeID: 15 },
    { name: "Serviços de terceiros", documentTypeID: 16 },
    { name: "Materiais utilizados em testes", documentTypeID: 17 },
    { name: "Despesas com viagens", documentTypeID: 18 },
    { name: "Despesas com treinamentos", documentTypeID: 19 },
    { name: "Ficha Técnica Modelo", documentTypeID: 20 },
    { name: "Timesheet", documentTypeID: 21 },
    { name: "Folha de Pagamento", documentTypeID: 22 },
    { name: "Memória de Cálculo", documentTypeID: 27 },
  ];

  const handleSelectChange = (e) => {
    setSegmentSelected(e.target.value);
  };

  useEffect(() => {
    setValidated(null);
    setSegmentSelected('');
  }, [props.show]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(false);
      return;
    }
    if (segmentRef.current) {
      props.getFile(fileRef.current.files[0], selectRef.current.value, segmentRef.current.value);
    } else {
      props.getFile(fileRef.current.files[0], selectRef.current.value);
    }
    setValidated(true);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setValidated(null);
        props.onHide();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Upload de arquivo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Selecione um arquivo</Form.Label>
            <Form.Control type="file" required name="file" ref={fileRef} />
            <Form.Control.Feedback type="invalid">
              Selecione um arquivo para ser enviado
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Selecione a área desse template</Form.Label>
            <Form.Select
              aria-label="titulo template"
              onChange={handleSelectChange}
              ref={selectRef}
            >
              {templateNames.map((template) => {
                return <option key={template.name}>{template.name}</option>;
              })}
            </Form.Select>
          </Form.Group >
          {segmentSelected === "Ficha Técnica Modelo" && (
            <Form.Group className="mt-3">
              <Form.Label>Selecione o segmento desse template</Form.Label>
              <Form.Select aria-label="segmento template" ref={segmentRef}>
                {segmentSelect.map((item) => {
                  return <option key={item}>{item}</option>;
                })}
              </Form.Select>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">Upload</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
