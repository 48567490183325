import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {Icon} from "../Components/Icon";

export const SearchBar = ({getInput, placeholder = ""}) => {
    const handleSubmit = (e) => {
        getInput(e.target.value) //handle data to parent component
    }

    return (
        <Form onSubmit={(e) => {
            e.preventDefault()
        }}>
            <InputGroup className="my-3">
                <InputGroup.Text
                    id="search-icon"
                    className="d-flex justify-content-end"
                    style={{backgroundColor: "#FFF", borderRight: 0}}
                >
                    <Icon icon={"MagnifyingGlass"} style={{color: "#D6D6D6"}}/>
                </InputGroup.Text>
                <Form.Control
                    style={{borderLeft: 0}}
                    onChange={handleSubmit}
                    placeholder={placeholder}
                    aria-label={placeholder}
                    aria-describedby="search-icon"
                />
            </InputGroup>
        </Form>
    );
};
