import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Selector } from "../Components/Selector";
import { StepProgressCard } from "../Components/StepProgressCard";
import { YearSelector } from "../Components/YearSelector";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { useSelector } from "react-redux";
import { UploadManager } from "../Components/UploadManager";
import useDownloadExcel from "../Hooks/useDownloadExcel";
import { useQuery } from "@tanstack/react-query";
import { ChartDispendio } from "../Components/ChartDispendio";
import { ChartEconomiaTributaria } from "../Components/ChartEconomiaTributaria";
import { ChartRelevanciaDispendio } from "../Components/ChartRelevanciaDispendio";

// CSS
import "./../Dashboard.scss"

const initialBenefit = {
    id: 3,
    name: "Lei da Informática",
};

export const Dashboard = ({ mode = "client" }) => {
    const currentCompany = useSelector(
        (state) => state.currentUser.selectedCompany
    );
    const currentYear = useSelector((state) => state.currentUser.currentYear || new Date().getFullYear());
    const refreshDashboard = useSelector(
        (state) => state.refreshDashboard.excelSent
    );
    const [currentBenefit, setCurrentBenefit] = useState(initialBenefit);
    const [benefits, setBenefits] = useState([]);
    const [benefitsSteps, setBenefitsSteps] = useState([]);
    //const [graphImages, setGraphImages] = useState([]);
    const [triggerModal, setTriggerModal] = useState(false);
    const axiosBearer = useAxiosBearer();
    const downloadExcel = useDownloadExcel();

    const { data, refetch } = useQuery({
        queryKey: ["graphData"],
        queryFn: async () => {
            const response = await axiosBearer.get(`plot/${currentCompany.id}/`, {
                params: { year: currentYear },
            });
            return response.data;
        },
    });

    const handleYearChange = (newYear) => {
        dispatchEvent({ type: 'SET_CURRENT_YEAR', payload: newYear });
    }

    useEffect(() => {
        refetch(['graphData', currentCompany.id, currentYear]);
    }, [currentCompany.id, currentYear, refetch]);

    const handleModal = () => {
        setTriggerModal(false);
    };

    useEffect(() => {
        const request = async () => {
            const response = await axiosBearer.get("benefits/");
            const companyData = await axiosBearer.get(
                `companies/${currentCompany.id}/`
            );
            const filteredBenefits = response.data.filter((benefit) => {
                return companyData.data.benefits.includes(benefit.id);
            });
            setBenefits(filteredBenefits);
            setCurrentBenefit(filteredBenefits[0]);
        };
        request();
    }, []);

    const getGraphs = async () => {
        /*const getPlots = await axiosBearer.get(
          `plot/${currentCompany.id}/images/`,
          { params: { year: currentYear } }
        );
        setGraphImages(getPlots.data.plots);*/
        const benefitSteps = await axiosBearer.get("benefitsteps/", {
            params: {
                benefit: currentBenefit.id,
                company: currentCompany.id,
                year: currentYear,
            },
        });
        setBenefitsSteps(benefitSteps.data);
    };

    useEffect(() => {
        const handleResize = () => {
            window.location.reload();
        };

        window.addEventListener('resize', handleResize);
        // Cleanup function to remove the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        getGraphs();
    }, [currentBenefit, currentYear]);

    useEffect(() => {
        if (refreshDashboard) getGraphs();
    }, [refreshDashboard]);

    return (
        <>
            <Card body className="p-4">
                <Row>
                    <Col
                        className="d-flex align-items-center justify-content-end"
                        sm={2}
                        lg={2}
                    >
                        <Card.Text>Ano-Calendário</Card.Text>
                    </Col>
                    <Col sm={4} lg={2} className="mb-4 mb-sm-0">
                        <YearSelector role={mode} onChange={(e) => handleYearChange(e.target.value)} />
                    </Col>
                    <Col
                        className="d-flex align-items-center justify-content-end"
                        sm={2}
                        lg={{ span: 2, offset: 2 }}
                    >
                        <Card.Text>Tipo de benefício</Card.Text>
                    </Col>
                    <Col sm={4} lg={4}>
                        <Selector
                            options={benefits}
                            label="seleção do tipo de benefício"
                            setCurrentBenefit={(param) => {
                                setCurrentBenefit(param);
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            {benefitsSteps.map((ruler) => {
                return (
                    <StepProgressCard
                        key={ruler.id}
                        hasEdit={mode === "client" ? false : true}
                        stepProgressData={ruler}
                    />
                );
            })}
            {mode === "consultant" ? (
                <div className="d-flex gap-2">
                    <Button
                        onClick={() => {
                            setTriggerModal(true);
                        }}
                    >
                        Enviar excel de dados
                    </Button>
                    <Button variant="outline-secondary" onClick={downloadExcel}>
                        Template Excel
                    </Button>
                </div>
            ) : null}

            <UploadManager
                triggerModal={triggerModal}
                handleModal={handleModal}
                mode={"excel"}
            />
            <Row className="justify-content-center">
                {/* <Col sm={12} md={12} lg={6} xl={6} className="order-lg-2 order-xl-2 custom-card">
                    {data && <ChartFruicao mode={mode} graphData={data} />}
                </Col> */}
                <Col sm={12} md={12} lg={6} xl={6} className="order-lg-3 order-xl-3 mt-4 mt-md-0 custom-card">
                    {data && <ChartRelevanciaDispendio mode={mode} graphData={data} />}
                </Col>
                <Col sm={12} md={12} lg={6} xl={6} className="order-lg-1 order-xl-1 mt-4 mt-md-0">
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={12} className="custom-card">
                            {data && <ChartDispendio mode={mode} graphData={data} />}
                        </Col>
                        <Col sm={12} md={12} lg={12} xl={12} className="custom-card">
                            {data && <ChartEconomiaTributaria mode={mode} graphData={data} />}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};
