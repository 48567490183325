import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import { FormularioAtualizacao } from "./FormularioAtualizacao";
import { currentUserActions } from "../store/currentUser";
import { useDispatch, useSelector } from "react-redux";
import { AppNavbar } from "../Components/AppNavbar";

export const Atualizacao = () => {

  const location = useLocation()
  const { expired } = location.state || false

  return (
    <div
      className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center gap-4"
      style={{ backgroundColor: "#F8F9FA" }}
    >
      <div className="d-none">
        <AppNavbar />

      </div>

      <h1>Atualização cadastro da empresa</h1>
      <Card
        className="overflow-auto bg-white p-3 terms"
        style={{ width: "80%", height: "60%", margin: "0 auto" }}
      >
        <FormularioAtualizacao expired={expired}/>
      </Card>

    </div>
  );
};
