import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";

const MemoriaCalculoTable = ({ calcMemory, year }) => {
  const [expandedCategory, setExpandedCategory] = useState(null);

  const toggleDetails = (category) => {
    if (expandedCategory === category) {
      setExpandedCategory(null);
    } else {
      setExpandedCategory(category);
    }
  };
  const formatMoney = (valor) => {
    return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const hasDetails = (category) => {
    return calcMemory[category]?.Detalhes !== undefined;
  };

  return (
    
    <Table striped bordered hover>
      <thead>
        <tr>
          <th style={{ textAlign: "center" }}>Resumo dos Dispêndios {year}</th>
          <th style={{ textAlign: "center" }}>Total</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(calcMemory).map((category, index) => (
          <React.Fragment key={index}>
            <tr onClick={() => toggleDetails(category)}>
              <td>
                {hasDetails(category) && (
                  <span style={{ marginRight: '5px' }}>
                  {expandedCategory === category ? (
                    <SlArrowUp />
                  ) : (
                    <SlArrowDown />
                  )}
                  </span>
                )}
                {category}
                </td>
                <td>
                {calcMemory[category].hasOwnProperty("Total")
                  ? formatMoney(calcMemory[category].Total)
                  : category === "% benefício"
                  ? `${calcMemory[category]}%`
                  : typeof calcMemory[category] === "number"
                  ? formatMoney(calcMemory[category])
                  : null}
              </td>
            </tr>
            {expandedCategory === category &&
              hasDetails(category) && (
                <tr>
                  <td colSpan="2">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Detalhes</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(calcMemory[category].Detalhes).map(
                          ([detail, value], idx) => (
                            <tr key={idx}>
                              <td>{detail}</td>
                              <td>{formatMoney(value)}</td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default MemoriaCalculoTable;
