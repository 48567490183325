import React from 'react'
import { Outlet } from "react-router-dom";
import { PageHeader } from "../Components/PageHeader";

export const TermsHome = () => {
  return (
    <>
      <PageHeader title="Termos de Uso e Privacidade">
        Gerenciamento de termos de uso e privacidade deste sistema.
      </PageHeader>
      <Outlet />
    </>
  );
}
