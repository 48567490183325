import React from "react";
import { Outlet } from "react-router-dom";
import { PageHeader } from "../Components/PageHeader";

export const Consultores = () => {
  return (
    <>
      <PageHeader title="Consultores">
        Gerenciamento de consultores deste sistema.
      </PageHeader>
      <Outlet />
    </>
  );
};
