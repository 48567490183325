import React from "react";

export const NotFound = () => {
  return (
    <>
      <div className="d-flex flex-column vw-100 vh-100 justify-content-center align-items-center">
        <p className="fw-bold fs-1">404</p>
        <p>Not Found.</p>
      </div>
    </>
  );
};
