import { useDispatch } from "react-redux";
import { docTypesActions } from "../store/doctypes-slice";
import useAxiosBearer from "./useAxiosBearer";

const useDocTypes = () => {
  const dispatch = useDispatch();
  const axiosBearer = useAxiosBearer();

  const request = async () => {
    const response = await axiosBearer.get("documents/");
    //console.log(response.data);
    dispatch(docTypesActions.setDocTypes(response.data));
  };

  return request
};

export default useDocTypes;
