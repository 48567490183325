import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

export const ClientRemoveTable = ({ addMode, clients = [], onRemove }) => {
  const [toDelete, setToDelete] = useState([]);

  const removeClient = (id) => {
    const newClients = clients.filter((client) => {
      if (client.id === id) {
        if (!addMode && client.consulting_id) {
          toDelete.push(client.consulting_id);
          setToDelete(toDelete);
        }
      }
      return client.id !== id;
    });
    onRemove(newClients, toDelete);
  };

  return (
    <Table className="align-middle">
      <thead>
        <tr>
          <th>Nome</th>
          <th className="text-center">CNPJ</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {clients.map((client) => {
          return (
            <tr key={client?.id}>
              <td>{client?.name}</td>
              <td className="text-center">
                {client?.cnpj.replace(
                  /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                  "$1.$2.$3/$4-$5"
                )}
              </td>
              <td className="d-flex justify-content-end">
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    removeClient(client?.id);
                  }}
                >
                  Remover
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
