import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClientTable } from "../Components/ClientTable";
import { HeadedCard } from "../Components/HeadedCard";
import { PageHeader } from "../Components/PageHeader";
import useAxiosBearer from "../Hooks/useAxiosBearer";
import useDocTypes from "../Hooks/useDocTypes";

export const Clientes = ({mode}) => {
  const [companiesData, setCompaniesData] = useState([])
  const userID = useSelector((state)=>state.auth.userID)
  const axiosBearer = useAxiosBearer()
  const setDocTypes = useDocTypes()

  useEffect(()=>{
    const request = async () => {     
      const userData = await axiosBearer.get(`users/${userID}/`)
      const formatedCompanies = userData.data.user_companies.map((comp)=>{
        const isMain = comp.company_group_main_company === comp.company_id;
        
        return {
          id: comp.company_id,
          name: comp.company_name,
          cnpj: comp.company_cnpj,
          uf: comp.company_uf,
          group: comp.group_name,
          principal: isMain,
          dateRevision: comp.company_date_revision,
          nextValidation: comp.company_next_validation
        }
      })
      setCompaniesData(formatedCompanies)
    }
    request()
    setDocTypes()
  }, [])

  return (
    <>
      <PageHeader title="Empresas">
        Selecionando a empresa para operar o sistema.
      </PageHeader>
      <HeadedCard title=" ">
        <ClientTable type="selection" mode={mode} companies={companiesData}/>
      </HeadedCard>
    </>
  );
};
