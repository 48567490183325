import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth-slice";
import clientToEditSlice from "./client-to-edit-slice";
import currentUserSlice from "./currentUser";
import projectOrTermToEditSlice from "./project-to-edit-slice";
import userToEditSlice from "./user-to-edit-slice";
import refreshDashboardSlice from "./refresh-dashboard-slice";
import docTypesSlice from "./doctypes-slice";
import storage from "redux-persist/lib/storage"
import {persistReducer} from "redux-persist"
import {combineReducers} from "@reduxjs/toolkit"

const persistConfig = {
  key: "root",
  version: 1,
  storage
}

const reducer = combineReducers({
  auth: authSlice.reducer,
  clientToEdit: clientToEditSlice.reducer,
  projectOrTermToEdit: projectOrTermToEditSlice.reducer,
  userToEdit: userToEditSlice.reducer,
  currentUser: currentUserSlice.reducer,
  refreshDashboard: refreshDashboardSlice.reducer,
  docTypes: docTypesSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
  devTools: false
});

export default store;
